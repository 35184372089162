/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { DraftService } from '@app/services/draft.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { logoutRequested } from '../screen.actions';
import {
  draftDialogOpened,
  draftInfoDeleteFailed,
  draftInfoDeleteRequested,
  draftInfoDeleteSuccessful,
  draftInfoGetFailed,
  draftInfoGetSuccessful,
  draftInfoRenameFailed,
  draftInfoRenameRequested,
  draftInfoRenameSuccessful
} from './draft-info.actions';
import { of } from 'rxjs';

@Injectable()
export class DraftInfoEffects {
  draftInfoGetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(draftDialogOpened),
      mergeMap(() =>
        this.draftService.loadDraftInfo().pipe(
          map(draftInfo => draftInfoGetSuccessful({ draftInfo })),
          catchError(err => of(draftInfoGetFailed({ err })))
        )
      )
    );
  });

  draftInfoDeleteEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(draftInfoDeleteRequested),
      mergeMap(props =>
        this.draftService.deleteDraft(props.draftId).pipe(
          map(draftId => draftInfoDeleteSuccessful({ deletedDraftId: draftId })),
          catchError(err => of(draftInfoDeleteFailed({ err })))
        )
      )
    );
  });

  draftInfoRenameEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(draftInfoRenameRequested),
      mergeMap(props =>
        this.draftService.renameDraft(props.draftId, props.newName).pipe(
          map(updatedDraft => draftInfoRenameSuccessful({ updatedDraft })),
          catchError(err => of(draftInfoRenameFailed({ err })))
        )
      )
    );
  });

  clearingCacheEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested, draftInfoRenameSuccessful, draftInfoDeleteSuccessful),
        tap(() => this.draftService.clearCache())
      );
    },
    { dispatch: false }
  );

  public constructor(private store: Store, private actions$: Actions, private draftService: DraftService) {}
}
