import { createReducer, on } from '@ngrx/store';
import { monthSelected } from './selected-month.actions';
import { SelectedMonthState } from './selected-month.state';

const currentDateIso = new Date().toISOString();

export const initialState: SelectedMonthState = {
  yearMonth: currentDateIso.substring(0, 7)
};

export const selectedMonthReducer = createReducer(
  initialState,
  on(monthSelected, (state, props): SelectedMonthState => ({ yearMonth: props.yearMonth }))
);
