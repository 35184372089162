import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { UserDataSelectors } from '../user-data/user-data.selectors';

export const selectSelectedMonthState = (state: AppState) => state.selectedMonth;

export const selectSelectedMonth = createSelector(selectSelectedMonthState, state => state.yearMonth);
export const selectSelectedMonthDate = createSelector(selectSelectedMonthState, state => new Date(state.yearMonth + '-02'));
export const selectIsInCurrentMonth = createSelector(selectSelectedMonth, state => state === new Date().toISOString().substring(0, 7));
export const selectIsInOriginalPlanMonth = createSelector(
  selectSelectedMonth,
  UserDataSelectors.selectUserDataProp('originalPlanYearMonth'),
  (selectedMonth, originalMonth) => selectedMonth === originalMonth
);
