import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isInteractive = false;
  @Input() public isLoading: boolean | undefined | null;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoaderInteractive$.subscribe(interactive => {
      this.isInteractive = interactive;
    });
  }
}
