import { Injectable } from '@angular/core';
import { Account, AccountWithoutId, Institution } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ApiService } from './api.service';
import { PlaidService } from './plaid.service';
import { UtilService } from './util.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountMinimumRequiredFields } from '@app/state/account/account.actions';
import { EditAccountDialogComponent } from '@app/modules/accounts/edit-account-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly path = 'account';
  private readonly batchPath = '/batch';
  static readonly manualInstitutionName = 'Manual Accounts';

  constructor(private apiService: ApiService, private utilService: UtilService, private plaidService: PlaidService, private dialog: MatDialog) {}

  openAccountDialog(account?: Account) {
    return this.dialog.open(EditAccountDialogComponent, { data: account }).afterClosed();
    // return this.dialog
    //   .open<AccountTypeSelectorComponent, IAccountTypeSelectorData, boolean | undefined>(AccountTypeSelectorComponent, {
    //     data: { account }
    //   })
    //   .afterClosed();
  }

  getAccounts(yearMonth: string, autoCreateAccounts?: boolean, draftId?: string): Observable<Account[]> {
    return this.apiService.get(this.path, { params: { yearMonth, draftId, autoCreate: autoCreateAccounts ? 'true' : 'false' } });
  }

  createAccount(partialValues: AccountMinimumRequiredFields, yearMonth: string, draftId?: string): Observable<Account> {
    const newAccount: AccountWithoutId = {
      accountLimit: 0,
      balance: 0,
      coverageAmount: 0,
      employerContribution: 0,
      interestRate: 0,
      monthlyAmount: 0,
      monthlyIncome: 0,
      monthlyMinimum: 0,
      totalOwed: 0,
      isActive: true,
      isBankAccount: false,
      ...(partialValues ?? {})
    };
    return this.apiService.post(this.path, newAccount, { params: { yearMonth, draftId } });
  }

  updateAccount(account: Account, yearMonth: string, draftId?: string): Observable<Account[]> {
    return this.apiService.put(this.path, account, { params: { yearMonth, draftId } });
  }

  deleteAccount(yearMonth: string, account: Account, draftId?: string): Observable<Account> {
    return this.apiService.delete(this.path, account, { params: { draftId, yearMonth } }).pipe(mapTo(account));
  }

  batchDeleteAccounts(accounts: Account[], yearMonth: string, draftId?: string): Observable<void> {
    return this.apiService.delete(this.path + this.batchPath, accounts, { params: { draftId, yearMonth } });
  }

  batchUpdateAccounts(accounts: Account[], yearMonth: string, draftId?: string): Observable<Account[]> {
    return this.apiService.put(this.path + this.batchPath, accounts, { params: { yearMonth, draftId } });
  }

  updateBalances(): Observable<{ accounts: Account[]; institutions: Institution[] }> {
    return this.plaidService.updateBalances();
  }

  public generateManualAccount(manualInstitution: Institution): Account {
    const createdInstitution = manualInstitution ? undefined : this.generateManualInstitution();
    const institution = manualInstitution ?? createdInstitution;

    const account: Account = {
      id: undefined as any,
      accountName: 'Manual Account',
      subtype: undefined,
      mask: '0000',
      balance: 0,
      itemId: institution.itemId,
      isActive: true,
      shouldSyncTransactions: true,
      isManual: true,
      accountLimit: 0,
      category: undefined,
      coverageAmount: 0,
      employerContribution: 0,
      interestRate: 0,
      isBankAccount: true,
      monthlyAmount: 0,
      monthlyIncome: 0,
      monthlyMinimum: 0,
      totalOwed: 0,
      targetAmount: 0,
      type: undefined
    };

    return account;
  }

  public generateManualInstitution(): Institution {
    return {
      itemId: this.utilService.generateIDcode(5),
      institutionId: this.utilService.generateIDcode(4),
      institutionName: AccountService.manualInstitutionName,
      isManual: true,
      isActive: true
    };
  }
}
