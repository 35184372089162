/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from '@wizefi/entities';
import {
  START_HERE_PATH,
  LOGIN_PATH,
  SETUP_ACCOUNTS_PATH,
  SETUP_GOALS_PATH,
  SETUP_INCOME_PATH,
  SETUP_PATH,
  SETUP_PROJECTIONS_PATH
} from '@app/modules/route-paths';
import { LoginService } from '@app/services/login.service';
import { MessageService } from '@app/services/message/message.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { monthSelected } from '../selected-month/selected-month.actions';
import { AccountActions } from '../account/account.actions';
import { LoginActions } from './login.actions';
import { CachedProjectionsService } from '@app/services/cached-projections.service';
import { HttpErrorResponse } from '@angular/common/http';
import { stopLoadingTransactions, syncTransactionsRequested } from '../transaction/transaction.actions';

@Injectable()
export class LoginEffects {
  loginRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.loginRequested),
      switchMap(({ email, password }) =>
        this.loginService.login(email, password, new Date().toISOString().substring(0, 7)).pipe(
          map(result => {
            if (result?.status === 'SubscriptionExpired') {
              this.messageService.info(
                `
                Please contact WizeFisupport@bluprintworldwide.com regarding your account access.
                `,
                500000
              );
              this.router.navigate(['/login']);
              return LoginActions.loginFailed({ err: 'Your enterprise subscription has expired.' });
            }

            if (result?.status === 'DeactivatedEnterpriseUser') {
              this.messageService.info(
                `
                Your subscription is no longer active.
                Please contact BluPrint (WizeFisupport@bluprintworldwide.com) or WizeFi
                (support@wizefi.com) to reactivate your subscription.
                `,
                500000
              );
              this.router.navigate(['/login']);
              return LoginActions.loginFailed({ err: 'Enterprise user deactivated.' });
            }

            return LoginActions.loginSuccessful(result);
          }),
          tap(() => window.localStorage.setItem('email', email)),
          catchError(err => {
            if (err.status === 401) {
              this.messageService.error('Invalid user or password.');
            } else {
              this.messageService.error('An error occurred. Please try again.');
            }
            return of(LoginActions.loginFailed({ err: err.message }));
          })
        )
      )
    );
  });

  loginSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.loginSuccessful),
      switchMap(async ({ id, token, userData, braintreeData }) => {
        window.sessionStorage.setItem('wizeFiID', id);
        window.sessionStorage.setItem('token', token);
        this.cachedProjectionsService.recalculateCache();
        this.redirect(userData);
        this.store.dispatch(syncTransactionsRequested({}));
        this.store.dispatch(stopLoadingTransactions());
        return { userData, braintreeData };
      }),
      map(({ braintreeData }) => {
        let shouldSkip = false;
        if (braintreeData.paidThroughDate && braintreeData.status) {
          shouldSkip = this.shouldSkipBalanceUpdate(braintreeData.paidThroughDate, braintreeData.status);
        }
        return shouldSkip ? { type: 'NO_ACTION' } : AccountActions.balancesUpdateRequested();
      })
    );
  });

  private shouldSkipBalanceUpdate(paidThroughDate: string, status: string): boolean {
    const paidDate = new Date(paidThroughDate);
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 30);
    return paidDate < sevenDaysAgo && status === 'Canceled';
  }

  reloginSuccessfulEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.reloginSuccessful),
        tap(() => this.cachedProjectionsService.recalculateCache())
      );
    },
    { dispatch: false }
  );

  verificationCodeRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.verificationCodeRequested),
      switchMap(props =>
        this.loginService.requestVerificationCode(props.email).pipe(
          map(() => LoginActions.verificationCodeSuccessful({ email: props.email })),
          catchError(err => of(LoginActions.verificationCodeFailed({ err })))
        )
      )
    );
  });

  resendVerificationCodeRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.resendVerificationCodeRequested),
      switchMap(props =>
        this.loginService.requestVerificationCode(props.email).pipe(
          map(() => LoginActions.resendVerificationCodeSuccessful({ email: props.email })),
          catchError(err => of(LoginActions.resendVerificationCodeFailed({ err })))
        )
      )
    );
  });

  resendVerificationCodeSuccessfulEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.resendVerificationCodeSuccessful),
        tap(props => this.messageService.success('New code sent, please check your email: ' + props.email, 3000))
      );
    },
    { dispatch: false }
  );

  confirmRegistrationRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.confirmRegistrationRequested),
        switchMap(props =>
          this.loginService.confirmRegistration(props.email, props.verificationToken).pipe(
            tap(result => {
              this.messageService.success(result, 3000);
              this.router.navigate([LOGIN_PATH], { state: { email: props.email } });
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.error.message === 'Invalid verification code provided, please try again.') {
                this.messageService.error('Invalid verification code, please try again.');
              }
              return of();
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  signupRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.signupRequested),
      map(props => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.localStorage.setItem('email', props.email);
        return {
          nameFirst: props.nameFirst || '',
          nameLast: props.nameLast || '',
          email: props.email,
          password: props.password,
          parentAffiliateId: props.parentAffiliateId,
          isEnterpriseMember: props.isEnterpriseMember || false
        };
      }),
      switchMap(props =>
        this.loginService
          .signup(props.email, props.password, props.parentAffiliateId, props?.nameFirst, props?.nameLast, props?.isEnterpriseMember)
          .pipe(
            tap(
              () => this.router.navigateByUrl('/validate-email?u=' + encodeURIComponent(props.email)),
              (err: HttpErrorResponse) => (err.status === 400 ? this.messageService.error('A user with this email already exists.') : {})
            ),
            mapTo(LoginActions.signupSuccessful()),
            catchError(err => of(LoginActions.signupFailed()))
          )
      )
    );
  });

  changePasswordRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.changePasswordRequested),
        switchMap(props =>
          this.loginService.changePassword(props.oldPassword, props.newPassword).pipe(
            tap(() => this.messageService.success('Your password has changed successfully.', 5000)),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 401) {
                this.messageService.error('Invalid password. Please try again.');
              }
              return of();
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  updateEmailRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.updateEmailRequested),
      switchMap(props =>
        this.loginService.updateEmail(props.password, props.newEmail).pipe(
          tap(() => this.messageService.success('Your email has changed successfully.', 5000)),
          catchError(() => of<string>())
        )
      ),
      map(newEmail => LoginActions.updateEmailSuccessful({ newEmail }))
    );
  });

  resetPasswordCodeRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.resetPasswordCodeRequested),
      switchMap(props =>
        this.loginService.requestPasswordResetCode(props.email).pipe(
          tap(() => this.messageService.success('A new verification code has been sent in your email.', 5000)),
          tap(() => this.router.navigateByUrl('/reset-password?u=' + encodeURIComponent(props.email))),
          map(() => LoginActions.verificationCodeSuccessful({ email: props.email })),
          catchError(err => of(LoginActions.verificationCodeFailed({ err })))
        )
      )
    );
  });

  resetPasswordRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.resetPasswordRequested),
        switchMap(props =>
          this.loginService.resetPassword(props.email, props.verificationCode, props.newPassword).pipe(
            tap(() => {
              this.messageService.success('Password reset successfully!', 3000);
              this.router.navigate([LOGIN_PATH]);
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.error?.message === 'Invalid verification code provided, please try again.') {
                this.messageService.error('Invalid verification code provided, please try again.');
              }
              return of();
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  private redirect(userData: UserData): Promise<boolean> {
    if (!userData.setupCompletedDate) {
      this.store.dispatch(monthSelected({ yearMonth: userData.originalPlanYearMonth, autoCreate: true }));

      const lastStepCompleted = userData.setupStepCompleted;
      const lastStepCompletedUrl = this.getSetupStepUrls().find(i => i.step === lastStepCompleted)?.url;

      if (!lastStepCompletedUrl) {
        return this.router.navigateByUrl(this.getSetupStepUrls()[0].url);
      }

      return this.router.navigateByUrl(lastStepCompletedUrl);
    } else {
      return this.router.navigate([START_HERE_PATH]);
    }
  }

  private getSetupStepUrls() {
    return [
      { step: 1, url: SETUP_PATH + '/' + SETUP_GOALS_PATH },
      { step: 2, url: SETUP_PATH + '/' + SETUP_INCOME_PATH },
      { step: 3, url: SETUP_PATH + '/' + SETUP_ACCOUNTS_PATH },
      { step: 4, url: SETUP_PATH + '/' + SETUP_PROJECTIONS_PATH }
    ];
  }

  public constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private router: Router,
    private messageService: MessageService,
    private store: Store,
    private cachedProjectionsService: CachedProjectionsService
  ) {}
}
