import { Injectable } from '@angular/core';
import { Productivity, AccountType, AccountSubtypeInfo, allSubtypeInfo, productivityByCategory } from '@wizefi/entities';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {
  public getAllSubtypes(): SubtypeWithProductivity[] {
    return allSubtypeInfo
      .map(i => ({ ...i, defaultProductivity: productivityByCategory[i.category] ?? 'Productive' }))
      .sort((a, b) => a.subtype.localeCompare(b.subtype));
  }

  public getSubtypes(type: AccountType | undefined): SubtypeWithProductivity[] {
    return allSubtypeInfo
      .filter(i => i.type === type)
      .map(i => ({ ...i, defaultProductivity: productivityByCategory[i.category] ?? 'Productive' }))
      .sort((a, b) => a.subtype.localeCompare(b.subtype));
  }

  getProductiveSubtypes(type: AccountType): SubtypeWithProductivity[] {
    return this.getSubtypes(type).filter(i => i.defaultProductivity === 'Productive');
  }

  getLimitedSubtypes(type: AccountType): SubtypeWithProductivity[] {
    return this.getSubtypes(type).filter(i => i.defaultProductivity === 'Limited');
  }

  getNonProductiveSubtypes(type: AccountType): SubtypeWithProductivity[] {
    return this.getSubtypes(type).filter(i => i.defaultProductivity === 'Non-productive');
  }
}

export interface SubtypeWithProductivity extends AccountSubtypeInfo {
  defaultProductivity: Productivity;
}
