import { createReducer, on } from '@ngrx/store';
import { TrackingToggleActions } from './tracking-toggle.actions';
import { TrackingToggleState } from './tracking-toggle.state';

const initialState: TrackingToggleState = {
  planning: false
};

export const trackingToggleReducer = createReducer(
  initialState,

  on(TrackingToggleActions.onToggle, (state, props): TrackingToggleState => ({ ...state, planning: !state.planning }))
);
