import { createReducer, on } from '@ngrx/store';
import { logoutRequested } from '../screen.actions';
import { LoginActions } from './login.actions';
import { LoginState } from './login.state';

export const initialState: LoginState = {
  loginStatus: 'pending',
  signupStatus: 'pending',
  token: undefined,
  id: undefined
};

export const loginReducer = createReducer(
  initialState,
  on(LoginActions.loginRequested, (state, props): LoginState => ({ ...state, loginStatus: 'loading' })),
  on(LoginActions.loginSuccessful, (state, props): LoginState => ({ ...state, loginStatus: 'success', token: props.token, id: props.id })),
  on(LoginActions.loginFailed, (state, props): LoginState => ({ ...state, loginStatus: 'failure' })),
  on(LoginActions.signupRequested, (state): LoginState => ({ ...state, signupStatus: 'loading' })),
  on(LoginActions.signupSuccessful, (state): LoginState => ({ ...state, signupStatus: 'success' })),
  on(LoginActions.signupFailed, (state): LoginState => ({ ...state, signupStatus: 'failure' })),
  on(LoginActions.reloginRequested, (state, props): LoginState => ({ ...state, loginStatus: 'success', token: props.token, id: props.id })),
  on(logoutRequested, (): LoginState => initialState)
);
