"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allCategories = exports.accountCategorySchema = void 0;
const zod_1 = require("zod");
exports.accountCategorySchema = zod_1.z.enum([
    'income',
    'emergencySavings',
    'cash',
    'cashReserves',
    'checking',
    'otherSavings',
    'investments',
    'residenceProperty',
    'investmentProperty',
    'personalProperty',
    'limitedPersonalProperty',
    'termInsurance',
    'permInsurance',
    'autoInsurance',
    'homeInsurance',
    'otherInsurance',
    'mortgageLimited',
    'creditCard',
    'autoLoan',
    'boatLoan',
    'educationalLoan',
    'other',
    'otherLimited',
    'otherNonProductive',
    'giving',
    'housing',
    'transportation',
    'food',
    'health',
    'clothing',
    'entertainment',
    'otherBudget'
]);
exports.allCategories = [
    { type: 'income', key: 'income', label: 'Income' },
    { type: 'assets', key: 'emergencySavings', label: 'Emergency Savings' },
    { type: 'assets', key: 'cash', label: 'Cash' },
    { type: 'assets', key: 'cashReserves', label: 'General Savings' },
    { type: 'assets', key: 'checking', label: 'Checking' },
    { type: 'assets', key: 'otherSavings', label: 'Other Savings' },
    { type: 'assets', key: 'investments', label: 'Investments' },
    { type: 'assets', key: 'residenceProperty', label: 'Residence Property' },
    { type: 'assets', key: 'investmentProperty', label: 'Investment Property' },
    { type: 'assets', key: 'personalProperty', label: 'Personal Property' },
    { type: 'assetProtection', key: 'termInsurance', label: 'Term Insurance' },
    { type: 'assetProtection', key: 'permInsurance', label: 'Permanent Insurance' },
    { type: 'assetProtection', key: 'autoInsurance', label: 'Automobile Insurance' },
    { type: 'assetProtection', key: 'homeInsurance', label: 'Housing Insurance' },
    { type: 'assetProtection', key: 'otherInsurance', label: 'Other Insurance' },
    { type: 'liabilities', key: 'mortgageLimited', label: 'Mortgage' },
    { type: 'liabilities', key: 'creditCard', label: 'Credit Card' },
    { type: 'liabilities', key: 'autoLoan', label: 'Auto Loan' },
    { type: 'liabilities', key: 'boatLoan', label: 'Boat Loan' },
    { type: 'liabilities', key: 'educationalLoan', label: 'Educational Loan' },
    { type: 'liabilities', key: 'other', label: 'Other' },
    { type: 'liabilities', key: 'otherLimited', label: 'Other' },
    { type: 'liabilities', key: 'otherNonProductive', label: 'Other' },
    { type: 'budget', key: 'giving', label: 'Giving' },
    { type: 'budget', key: 'housing', label: 'Housing' },
    { type: 'budget', key: 'transportation', label: 'Transportation' },
    { type: 'budget', key: 'food', label: 'Food' },
    { type: 'budget', key: 'health', label: 'Health/Insurance' },
    { type: 'budget', key: 'clothing', label: 'Clothing' },
    { type: 'budget', key: 'entertainment', label: 'Entertainment' },
    { type: 'budget', key: 'otherBudget', label: 'Other' }
];
