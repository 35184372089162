import { Injectable } from '@angular/core';
import { Transaction, TransactionWithoutIds } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TransactionService {
  private readonly path = 'transactions';

  private cachedTransactions: { [key: string]: Observable<Transaction[]> } = {};

  constructor(private apiService: ApiService) {}

  getTransactions(yearMonth: string): Observable<Transaction[]> {
    if (this.cachedTransactions[yearMonth]) {
      return this.cachedTransactions[yearMonth];
    }
    this.cachedTransactions[yearMonth] = this.apiService.get(this.path, { params: { date: yearMonth } });
    return this.cachedTransactions[yearMonth];
  }

  getFilteredSyncedTransactions(yearMonth: string): Observable<Transaction[]> {
    if (this.cachedTransactions[yearMonth]) {
      return this.cachedTransactions[yearMonth];
    }
    this.cachedTransactions[yearMonth] = this.apiService.post(this.path + '/getFilteredSyncedTransactions', { yearMonth });
    return this.cachedTransactions[yearMonth];
  }

  createBatchTransactions(transactions: TransactionWithoutIds[]): Observable<Transaction[]> {
    return this.apiService.post(`${this.path}/batch`, transactions).pipe(tap(() => this.clearCache()));
  }

  editBatchTransactions(transactions: Transaction[]): Observable<Transaction[]> {
    return this.apiService.put(`${this.path}/batch`, transactions).pipe(tap(() => this.clearCache()));
  }

  deleteBatchTransactions(transactions: Transaction[]): Observable<Transaction[]> {
    return this.apiService.delete(`${this.path}/batch`, transactions).pipe(tap(() => this.clearCache()));
  }

  editTransaction(transaction: Transaction): Observable<Transaction> {
    return this.apiService.put(this.path, transaction).pipe(tap(() => this.clearCache()));
  }

  deleteTransaction(transaction: Transaction): Observable<Transaction> {
    return this.apiService.delete(this.path, transaction).pipe(tap(() => this.clearCache(transaction.date.substring(0, 7))));
  }

  createTransaction(transaction: TransactionWithoutIds): Observable<Transaction> {
    return this.apiService.post(this.path, transaction).pipe(tap(() => this.clearCache(transaction.date.substring(0, 7))));
  }

  public clearCache(yearMonth?: string) {
    if (!yearMonth) {
      this.cachedTransactions = {};
      return;
    }
    delete this.cachedTransactions[yearMonth];
  }
}
