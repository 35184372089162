import { Injectable } from '@angular/core';
import { Account, Transaction, BraintreeData, UserData, Institution } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private readonly unauthenticatedPath = 'open';
  private readonly userPath = 'user';
  private readonly loginPath = '/login';
  private readonly requestVerificationCodePath = '/requestVerificationCode';
  private readonly requestPasswordResetCodePath = '/requestPasswordResetCode';
  private readonly confirmRegistrationPath = '/confirmRegistration';
  private readonly signupPath = '/signup';
  private readonly changePasswordPath = '/changePassword';
  private readonly updateEmailPath = '/updateEmail';
  private readonly resetPasswordPath = '/changePasswordWithVerificationCode';
  private readonly getUserInfoFromAliasPath = '/getUserInfoFromAlias';
  private readonly getUserInfoFromIdPath = '/getUserInfoFromId';

  constructor(private apiService: ApiService) {}

  login(
    email: string,
    password: string,
    yearMonth: string
  ): Observable<{
    status?: string;
    id: string;
    token: string;
    institutions: Institution[];
    userData: UserData;
    originalAccounts: Account[];
    accounts: Account[];
    braintreeData: BraintreeData;
    transactions: Transaction[];
  }> {
    return this.apiService.post(
      this.unauthenticatedPath + this.loginPath,
      { email, password, yearMonth },
      { keepLoggedInAfterUnauthorizedResponse: true, redirectToErrorPage: error => error.status !== 401 }
    );
  }

  requestVerificationCode(email: string): Observable<{ email: string }> {
    return this.apiService.post(this.unauthenticatedPath + this.requestVerificationCodePath, { email }).pipe(map(() => ({ email })));
  }

  requestPasswordResetCode(email: string): Observable<any> {
    return this.apiService.post(this.unauthenticatedPath + this.requestPasswordResetCodePath, { email });
  }

  confirmRegistration(email: string, verificationCode: string): Observable<string> {
    return this.apiService
      .post(
        this.unauthenticatedPath + this.confirmRegistrationPath,
        { email, verificationCode },
        {
          keepLoggedInAfterUnauthorizedResponse: true,
          redirectToErrorPage: err => (err.error.message === 'Invalid verification code provided, please try again.' ? false : true)
        }
      )
      .pipe(map(result => result.message));
  }

  signup(
    email: string,
    password: string,
    parentAffiliateId: string,
    nameFirst?: string,
    nameLast?: string,
    isEnterpriseMember?: boolean
  ): Observable<{ id: string }> {
    return this.apiService.post(
      this.unauthenticatedPath + this.signupPath,
      { email, password, parentAffiliateId, nameFirst, nameLast, isEnterpriseMember },
      { redirectToErrorPage: err => (err.status === 400 ? false : true) }
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.apiService.post(
      this.userPath + this.changePasswordPath,
      { oldPassword, newPassword },
      { keepLoggedInAfterUnauthorizedResponse: true, redirectToErrorPage: err => err.status !== 401 }
    );
  }

  updateEmail(password: string, newEmail: string): Observable<string> {
    return this.apiService
      .post(this.userPath + this.updateEmailPath, { password, newEmail }, { keepLoggedInAfterUnauthorizedResponse: true })
      .pipe(map(() => newEmail));
  }

  resetPassword(email: string, verificationCode: string, newPassword: string): Observable<any> {
    return this.apiService.post(
      this.unauthenticatedPath + this.resetPasswordPath,
      { email, verificationCode, newPassword },
      { redirectToErrorPage: err => err.error?.message !== 'Invalid verification code provided, please try again.' }
    );
  }

  getUserInfoFromAlias(alias: string): Observable<{
    isActiveEnterpriseContract?: boolean;
    isEnterpriseMemberActive?: boolean;
    isEnterpriseParent?: boolean;
    isEnterpriseMember?: boolean;
    firstName: string;
    lastName: string;
    wizefiId: string;
  }> {
    return this.apiService.post(this.unauthenticatedPath + this.getUserInfoFromAliasPath, { alias });
  }

  getUserInfoFromId(id: string): Observable<{
    isActiveEnterpriseContract?: boolean;
    isEnterpriseMemberActive?: boolean;
    isEnterpriseParent?: boolean;
    isEnterpriseMember?: boolean;
    firstName: string;
    lastName: string;
    wizefiId: string;
  }> {
    return this.apiService.post(this.unauthenticatedPath + this.getUserInfoFromIdPath, { id });
  }
}
