import { DraftType } from '@wizefi/entities';
import { DraftGetResponse } from '@app/interfaces/draft-get-response';
import { createAction, props } from '@ngrx/store';

export const loadDraftRequested = createAction('[App] load draft requested', props<{ draftId: string }>());
export const loadDraftSuccessful = createAction('[App] load draft successful', props<{ draftResponse: DraftGetResponse }>());
export const loadDraftFailed = createAction('[App] load draft failed');
export const saveDraftRequested = createAction('[App] save draft requested');
export const saveDraftSuccessful = createAction('[App] save draft successful');
export const saveDraftFailed = createAction('[App] save draft failed', props<{ err: any }>());
export const draftUnloaded = createAction('[App] draft was unloaded', props<{ currentFragment?: string | null }>());
export const createNewDraftRequested = createAction(
  '[App] create new draft requested',
  props<{
    draftType: DraftType;
    draftName: string;
    navigateToFragment?: 'expenses' | 'income' | 'four-step-plan';
  }>()
);
export const createNewDraftSuccessful = createAction('[App] create new draft successful', props<{ draftResponse: DraftGetResponse }>());
export const createNewDraftFailed = createAction('[App] create new draft failed', props<{ err: any }>());
export const promoteDraftRequested = createAction('[App] promote draft requested', props<{ currentFragment?: string | null }>());
export const promoteDraftSuccessful = createAction('[App] promote draft successful', props<{ currentFragment?: string | null }>());
export const promoteDraftFailed = createAction('[App] promote draft failed', props<{ err: any }>());
export const saveDraftAndExit = createAction('[App] save draft and exit', props<{ currentFragment?: string | null }>());
export const saveDraftAndExitFailed = createAction('[App] save draft and exit failed', props<{ err: any }>());
