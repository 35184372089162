import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { captureException } from '@sentry/angular-ivy';
import { ClarityService } from './clarity.service';
import { selectWizefiId } from '@app/state/login/login.selectors';
import { UserDataSelectors } from '@app/state/user-data/user-data.selectors';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor(private store: Store, private clarityService: ClarityService) {}

  async captureException(error: any, message?: string) {
    const wizefiId = await this.store.select(selectWizefiId).pipe(first()).toPromise();
    const userData = await this.store.select(UserDataSelectors.selectUserData).pipe(first()).toPromise();

    captureException(error, {
      user: {
        id: wizefiId,
        email: userData?.email
      },
      extra: {
        claritySessionId: this.clarityService.sessionId,
        userMessage: message
      }
    });
  }
}
