import { createReducer, on } from '@ngrx/store';
import { Institution } from '@wizefi/entities';
import { AccountActions } from '../account/account.actions';
import { LoginActions } from '../login/login.actions';
import { logoutRequested, newPlaidInstitutionLinkSuccessful } from '../screen.actions';
import {
  institutionCreationFailed,
  institutionCreationRequest,
  institutionCreationSuccessful,
  institutionDeleteFailed,
  institutionDeleteRequest,
  institutionDeleteSuccessful,
  institutionGetFailed,
  institutionGetSuccessful,
  institutionsGetRequested,
  institutionUpdateFailed,
  institutionUpdateRequest,
  institutionUpdateSuccessful
} from './institution.actions';
import { InstitutionState } from './institution.state';

export const initialState: InstitutionState = {
  status: 'pending',
  institutions: []
};

type StateSwitcher = (state: InstitutionState, props: any) => InstitutionState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });
const failureStateSwitcher: StateSwitcher = state => ({ ...state, status: 'failure' });

const switchToInstitutions = (state: InstitutionState, props: { institutions: Institution[] }): InstitutionState => ({
  ...state,
  status: 'success',
  institutions: props.institutions
});

export const institutionReducer = createReducer(
  initialState,
  on(LoginActions.loginSuccessful, switchToInstitutions),
  on(institutionsGetRequested, loadingStateSwitcher),
  on(institutionGetSuccessful, switchToInstitutions),
  on(institutionGetFailed, failureStateSwitcher),
  on(institutionCreationRequest, loadingStateSwitcher),
  on(
    institutionCreationSuccessful,
    (state, props): InstitutionState => ({
      ...state,
      status: 'success',
      institutions: [...state.institutions, props.newInstitution]
    })
  ),
  on(institutionCreationFailed, failureStateSwitcher),
  on(institutionUpdateRequest, loadingStateSwitcher),
  on(institutionUpdateSuccessful, (state, props) => ({
    ...state,
    status: 'success',
    institutions: state.institutions.map(i => (i.itemId === props.updatedInstitution.itemId ? props.updatedInstitution : i))
  })),
  on(institutionUpdateFailed, failureStateSwitcher),
  on(institutionDeleteRequest, loadingStateSwitcher),
  on(institutionDeleteSuccessful, (state, props) => ({
    ...state,
    status: 'success',
    institutions: state.institutions.filter(i => i.itemId !== props.deletedInstitution.itemId)
  })),
  on(institutionDeleteFailed, failureStateSwitcher),
  on(newPlaidInstitutionLinkSuccessful, (state, props): InstitutionState => ({ ...state, institutions: [...state.institutions, props.institution] })),
  on(
    AccountActions.balancesUpdateSuccessful,
    (state, props): InstitutionState => ({ ...state, status: 'success', institutions: props.institutions })
  ),
  on(logoutRequested, (): InstitutionState => initialState)
);
