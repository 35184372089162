import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor() {}

  public generateIDcode(length: number): string {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    let idCode = '';

    for (let i = 1; i <= length; i++) {
      idCode += letters[Math.floor(Math.random() * 26)];
    }

    return idCode;
  }
}
