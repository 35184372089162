import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MessageService } from '@app/services/message/message.service';
import { UserService } from '@app/services/user.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdminScreenGuard implements CanActivate {
  constructor(private userService: UserService, private messageService: MessageService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isAdmin().pipe(
      tap(result => {
        if (!result) {
          this.messageService.error('Not authorized to view screen');
        }
      })
    );
  }
}
