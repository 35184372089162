"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.affiliateTreeNodeSchema = void 0;
const zod_1 = require("zod");
exports.affiliateTreeNodeSchema = zod_1.z.object({
    affiliateID: zod_1.z.string(),
    affiliateAlias: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    node: zod_1.z.object({
        parent: zod_1.z.string(),
        child: zod_1.z.array(zod_1.z.string()),
    }),
    version: zod_1.z.number(),
});
