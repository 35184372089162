/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { UserDataService } from '@app/services/user-data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { draftUnloaded } from '../draft/loaded-draft.actions';
import { selectLoadedDraft } from '../draft/loaded-draft.selectors';
import { LoginActions } from '../login/login.actions';
import { monthSelected } from '../selected-month/selected-month.actions';
import { UserDataActions } from './user-data.actions';
import { UserDataSelectors } from './user-data.selectors';

@Injectable()
export class UserDataEffects {
  reloginSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.reloginRequested),
      switchMap(() =>
        this.userDataService.getUserData().pipe(
          switchMap(userData =>
            of(
              UserDataActions.getSuccessful({ userData }),
              LoginActions.reloginSuccessful(),
              monthSelected({
                yearMonth: userData.setupCompletedDate ? new Date().toISOString().substring(0, 7) : userData.originalPlanYearMonth,
                autoCreate: false
              })
            )
          ),
          catchError(err => of(UserDataActions.getFailed({ err })))
        )
      )
    );
  });

  getRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDataActions.getRequested),
      concatLatestFrom(() => this.store.select(selectLoadedDraft)),
      switchMap(([_, draftId]) =>
        this.userDataService.getUserData(draftId).pipe(
          map(userData => UserDataActions.getSuccessful({ userData })),
          catchError(err => of(UserDataActions.getFailed({ err })))
        )
      )
    );
  });

  savePartialRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDataActions.savePartialRequested),
      concatLatestFrom(() => [this.store.select(UserDataSelectors.selectUserData), this.store.select(selectLoadedDraft)]),
      switchMap(([props, userData, draftId]) =>
        this.userDataService.saveUserData({ ...userData, ...props.partialValues }, draftId).pipe(
          map(updatedUserData => UserDataActions.saveSuccessful({ userData: updatedUserData })),
          catchError(err => of(UserDataActions.saveFailed({ err })))
        )
      )
    );
  });

  draftUnloadedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(draftUnloaded),
      switchMap(() =>
        this.userDataService.getUserData().pipe(
          map(userData => UserDataActions.getSuccessful({ userData })),
          catchError(err => of(UserDataActions.getFailed({ err })))
        )
      )
    );
  });

  public constructor(private actions$: Actions, private store: Store, private userDataService: UserDataService) {}
}
