import { UserData } from '@wizefi/entities';
import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppState } from '../app.state';

export class UserDataSelectors {
  static selectState = (state: AppState) => state.userData;
  static loadingStatus = createSelector(this.selectState, state => state.status);
  static selectUserData = createSelector(this.selectState, state => state.userData);
  static selectLoadedUserData = pipe(
    select(this.selectState),
    filter(userData => userData.status === 'success'),
    map(state => state.userData)
  );

  static selectHasCompletedSetup = pipe(
    this.selectLoadedUserData,
    map(userData => !!userData.setupCompletedDate)
  );

  static selectUserDataProp = <T extends keyof UserData>(prop: T) => createSelector(this.selectUserData, userData => userData[prop]);
  static selectHasCreatedFirstPlan = () =>
    createSelector(this.selectUserDataProp('hasCreatedFirstPlan'), hasCreatedFirstPlan => !!hasCreatedFirstPlan);

  static selectFinancialFreedomNumber = createSelector(
    this.selectUserData,
    userData => (userData.monthlyRetirementIncome * 12 * 100) / userData.withdrawRate
  );

  static selectFullName = createSelector(this.selectUserData, userData => userData.nameFirst + ' ' + userData.nameLast);
  static selectBirthDate = createSelector(this.selectUserData, userData => new Date(userData.birthDate));

  static hasSelectedCoachMode = createSelector(this.selectUserData, userData => userData.isCoach !== undefined);

  static selectisEnterpriseMember = createSelector(this.selectUserData, userData => (userData.isEnterpriseMember ? true : false));
}
