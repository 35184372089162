"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.affiliateCommissionWithoutIdSchema = exports.affiliateCommissionSchema = void 0;
const zod_1 = require("zod");
exports.affiliateCommissionSchema = zod_1.z.object({
    /** UUID of this commission */
    id: zod_1.z.string(),
    /** UUID of the braintree webhook that originated this commission. Useful for backtracking what originated this commission */
    webhookId: zod_1.z.string(),
    /** The wizefiId of the user who is receiving this commission */
    receivingUser: zod_1.z.string(),
    /** What level in the tree the receivingUser is from the originatingUser. (An immediate parent would be level 1, second parent would be level 2, etc) */
    treeLevel: zod_1.z.number(),
    /** How much percentage of commission on top of the transaction amount the receivingUser is getting paid for. This percentage is in decimal, i.e 1 = 100% */
    commissionPercentage: zod_1.z.number(),
    /** Total amount of commission for this transaction. Equal to commissionPercentage * (transaction amount). This amount can be negative in case of won/accepted disputes. */
    amount: zod_1.z.number(),
    /** The wizefiId of the user who has made the transaction. */
    originatingUser: zod_1.z.string(),
    /** Timestamp of when this transaction occurred. */
    timestamp: zod_1.z.string(),
});
exports.affiliateCommissionWithoutIdSchema = exports.affiliateCommissionSchema.omit({
    id: true,
});
