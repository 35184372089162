<ng-container *ngrxLet="userData$ as userData">
  <h2>Setup</h2>
  <div class="menu-item-row">
    <ng-container *ngFor="let step of setupSteps$ | async; let i = index">
      <app-setup-step-menu-item
        [state]="step.state"
        [stepNumber]="i"
        [stepName]="step.label"
        (stepClicked)="goToStep(userData, $event)"
      ></app-setup-step-menu-item>
    </ng-container>
  </div>
  <button mat-stroked-button (click)="onSaveAndContinue(userData)" class="continue-button" data-test="setup-menu-save-and-continue-button">
    Save & Continue
  </button>
</ng-container>
