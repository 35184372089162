import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly path = 'user';

  constructor(private apiService: ApiService) {}

  isAdmin(): Observable<boolean> {
    return this.apiService.post(this.path + '/isAdmin').pipe(map(result => result.isAdmin));
  }

  getAllAdmins(): Observable<string[]> {
    return this.apiService.post(this.path + '/getAllAdmins');
  }

  revokeAdminAccess(wizefiIdToRevokeAccess: string): Observable<string[]> {
    return this.apiService.post(this.path + '/revokeAdminAccess', { wizefiIdToRevokeAccess });
  }

  grantAdminAccess(wizefiIdToGrantAccess: string): Observable<string[]> {
    return this.apiService.post(this.path + '/grantAdminAccess', { wizefiIdToGrantAccess });
  }

  wipeUserData() {
    return this.apiService.post(this.path + '/refresh');
  }
}
