import { BraintreeData } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export class SubscriptionAction {
  static getDataRequested = createAction('[App] subscription data requested');
  static getDataSuccessful = createAction('[App] subscription data successful', props<{ data: BraintreeData }>());
  static getDataFailed = createAction('[App] subscription data failed', props<{ err: any }>());
  static createSubscriptionRequested = createAction(
    '[App] create subscription requested',
    props<{ firstName: string; lastName: string; paymentMethodNonce: string }>()
  );
  static createSubscriptionSuccessful = createAction('[App] create subscription successful', props<{ data: BraintreeData }>());
  static createSubscriptionFailed = createAction('[App] create subscription failed', props<{ err: any }>());

  static paymentMethodUpdateRequested = createAction('[SubscriptionDialog] payment method update requested', props<{ paymentMethodNonce: string }>());
  static paymentMethodUpdateSuccessful = createAction('[SubscriptionDialog] payment method update successful', props<{ data: BraintreeData }>());
  static paymentMethodUpdateFailed = createAction('[SubscriptionDialog] payment method update failed', props<{ err: any }>());

  static paymentMethodCreateRequested = createAction('[SubscriptionDialog] payment method create requested', props<{ paymentMethodNonce: string }>());
  static paymentMethodCreateSuccessful = createAction('[SubscriptionDialog] payment method create successful', props<{ data: BraintreeData }>());
  static paymentMethodCreateFailed = createAction('[SubscriptionDialog] payment method create error', props<{ err: any }>());

  static cancelSubscriptionRequested = createAction(
    '[CancelSubscriptionDialog] cancel subscription requested',
    props<{ cancelationReason: string }>()
  );
  static cancelSubscriptionFailed = createAction('[CancelSubscriptionDialog] cancel subscription failed', props<{ err: any }>());
}
