import { Injectable } from '@angular/core';
import { NotificationPreferences } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class NotificationPreferencesService {
  private readonly path = 'notification-preferences';

  constructor(private api: ApiService) {}

  get(): Observable<NotificationPreferences> {
    return this.api.get(this.path);
  }

  put(preferences: NotificationPreferences) {
    return this.api.put(this.path, preferences);
  }
}
