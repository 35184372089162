import { Component, HostBinding, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <h2 class="mb-6 text-2xl font-bold">{{ data.title }}</h2>
    <div class="description" [innerHTML]="data.description"></div>
    <div class="mt-4 flex justify-end">
      <button mat-button class="!mr-2" (click)="this.matDialogRef.close(false)">Cancel</button>
      <button
        mat-flat-button
        [color]="data.confirmButtonColor ?? 'primary'"
        class="cancel"
        (click)="this.matDialogRef.close(true)"
        data-test="confirmation-dialog-confirm-button"
      >
        {{ data.confirmButtonText ?? 'Confirm' }}
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        max-width: 650px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
      }

      div.description {
        max-width: 480px;
      }
    `
  ],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule]
})
export class ConfirmationDialogComponent {
  @HostBinding('attr.data-test') dataTestAttr = 'confirmation-dialog';
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; description: string; confirmButtonColor?: ThemePalette; confirmButtonText?: string },
    public matDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}
}
