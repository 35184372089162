/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SETUP_GOALS_PATH, SETUP_PATH } from '@app/modules/route-paths';
import { BraintreeService } from '@app/services/braintree.service';
import { MessageService } from '@app/services/message/message.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { LoginActions } from '../login/login.actions';
import { logoutRequested } from '../screen.actions';
import { SubscriptionAction } from './subscription.actions';
import { selectSubscription } from './subscription.selectors';

@Injectable()
export class SubscriptionEffects {
  reloginRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(ofType(LoginActions.reloginRequested), mapTo(SubscriptionAction.getDataRequested()));
  });

  subscriptionRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionAction.getDataRequested),
      switchMap(() =>
        this.braintreeService.getBraintreeData().pipe(
          map(braintreeData => SubscriptionAction.getDataSuccessful({ data: braintreeData })),
          catchError(err => of(SubscriptionAction.getDataFailed({ err })))
        )
      )
    );
  });

  createSubscriptionRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionAction.createSubscriptionRequested),
      switchMap(props =>
        this.braintreeService.createFirstSubscription(props.firstName, props.lastName, props.paymentMethodNonce).pipe(
          map(braintreeData => SubscriptionAction.createSubscriptionSuccessful({ data: braintreeData })),
          catchError(err => of(SubscriptionAction.createSubscriptionFailed({ err })))
        )
      )
    );
  });

  createSubscriptionSuccessfulEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionAction.createSubscriptionSuccessful),
        tap(() => {
          this.messageService.success('Subscription has been registered');
          return this.router.navigate([SETUP_PATH, SETUP_GOALS_PATH]);
        })
      );
    },
    { dispatch: false }
  );

  paymentMethodUpdateRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionAction.paymentMethodUpdateRequested),
      concatLatestFrom(() => this.store.select(selectSubscription)),
      filter(([props, subscription]) => !!subscription.subscriptionId),
      switchMap(([props, subscription]) =>
        this.braintreeService
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .updatePaymentMethod(subscription.paymentMethodToken!, props.paymentMethodNonce)
          .pipe(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            map(r => ({ paymentMethodToken: r.token, subscriptionId: subscription.subscriptionId! })),
            switchMap(({ paymentMethodToken, subscriptionId }) => this.braintreeService.updateSubscription(subscriptionId, paymentMethodToken)),
            switchMap(() => this.braintreeService.getBraintreeData()),
            map(braintreeData => SubscriptionAction.paymentMethodUpdateSuccessful({ data: braintreeData })),
            catchError(err => of(SubscriptionAction.paymentMethodUpdateFailed({ err })))
          )
      )
    );
  });

  paymentMethodCreateRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionAction.paymentMethodCreateRequested),
      concatLatestFrom(() => this.store.select(selectSubscription)),
      filter(([props, subscription]) => !!subscription.subscriptionId),
      switchMap(([props, subscription]) =>
        this.braintreeService.createPaymentMethod(props.paymentMethodNonce).pipe(
          switchMap(r => {
            if (subscription.status === 'Canceled' || subscription.status === 'Expired') {
              return this.braintreeService.createSubscription(r.token);
            } else {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return this.braintreeService.updateSubscription(subscription.subscriptionId!, r.token);
            }
          }),
          switchMap(() => this.braintreeService.getBraintreeData()),
          tap(braintreeData => {
            if (braintreeData.status === 'Past Due') {
              this.messageService.error(`This card could not be charged. Please check the card and try again or use another payment method.`, 10000);
            }
          }),
          map(braintreeData => SubscriptionAction.paymentMethodCreateSuccessful({ data: braintreeData })),
          catchError(err => of(SubscriptionAction.paymentMethodCreateFailed({ err })))
        )
      )
    );
  });

  cancelSubscriptionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionAction.cancelSubscriptionRequested),
      concatLatestFrom(() => this.store.select(selectSubscription)),
      filter(([props, subscription]) => !!subscription.subscriptionId),
      switchMap(([props, subscription]) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.braintreeService.cancelSubscription(subscription.subscriptionId!, props.cancelationReason).pipe(
          map(() => SubscriptionAction.getDataRequested()),
          catchError(err => of(SubscriptionAction.cancelSubscriptionFailed({ err })))
        )
      )
    );
  });

  logoutEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested),
        tap(() => this.braintreeService.clearCache())
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private braintreeService: BraintreeService,
    private messageService: MessageService,
    private store: Store,
    private router: Router
  ) {}
}
