/* eslint-disable no-console */
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LoginActions } from '@app/state/login/login.actions';
import { selectSignupIsLoading } from '@app/state/login/login.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

interface SignupPayload {
  email: string;
  password: string;
  parentAffiliateId: string;
  nameFirst?: string;
  nameLast?: string;
  isEnterpriseMember?: boolean;
  isEnterpriseParent?: boolean;
}

@Component({
  selector: 'app-create-account-signup',
  templateUrl: './create-account-signup.component.html',
  styleUrls: ['./create-account-signup.component.scss']
})
export class CreateAccountSignupComponent implements OnInit, OnChanges {
  @Input() parentAffiliateId!: string;
  @Input() isEnterpriseMember = false;
  @Input() isEnterpriseParent = false;
  @Input() isActiveEnterpriseContract: boolean;
  @Input() isEnterpriseMemberActive: boolean;
  formGroup!: UntypedFormGroup;
  loading$!: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.initializeForm();
    this.loading$ = this.store.pipe(selectSignupIsLoading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEnterpriseParent) {
      this.updateEnterpriseControls();
    }
  }

  private initializeForm(): void {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), hasNumberValidator, hasUpperAndLowercase])
    });

    this.updateEnterpriseControls();
  }

  updateEnterpriseControls() {
    if (!this.formGroup) {
      return;
    }
    if (this.isEnterpriseParent && this.isActiveEnterpriseContract) {
      if (!this.formGroup.contains('nameFirst')) {
        this.formGroup.addControl('nameFirst', new UntypedFormControl('', Validators.required));
      }
      if (!this.formGroup.contains('nameLast')) {
        this.formGroup.addControl('nameLast', new UntypedFormControl('', Validators.required));
      }
    } else {
      if (this.formGroup.contains('nameFirst')) {
        this.formGroup.removeControl('nameFirst');
      }
      if (this.formGroup.contains('nameLast')) {
        this.formGroup.removeControl('nameLast');
      }
    }
  }
  signup(): void {
    if (!this.formGroup.valid) {
      return;
    }

    const { email, password, nameFirst, nameLast } = this.formGroup.value;

    const signupPayload: SignupPayload = {
      email,
      password,
      parentAffiliateId: this.parentAffiliateId
    };

    if (this.isEnterpriseParent && this.isActiveEnterpriseContract) {
      signupPayload.nameFirst = nameFirst;
      signupPayload.nameLast = nameLast;
      signupPayload.isEnterpriseMember = true;
      signupPayload.isEnterpriseParent = false;
    }

    this.store.dispatch(LoginActions.signupRequested(signupPayload));
  }
}

const hasNumberValidator: ValidatorFn = control => {
  if (!control.value) {
    return { hasNumberError: true };
  }

  if (!/\d/.test(control.value as string)) {
    return { hasNumberError: true };
  }
  return null;
};

const hasUpperAndLowercase: ValidatorFn = control => {
  if (!control.value) {
    return { hasUpperAndLowercaseError: true };
  }

  if (!/[A-Z]/.test(control.value as string) || !/[a-z]/.test(control.value as string)) {
    return { hasUpperAndLowercaseError: true };
  }
  return null;
};
