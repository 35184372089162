import { createReducer, on } from '@ngrx/store';
import { logoutRequested } from '../screen.actions';
import {
  createNewDraftFailed,
  createNewDraftRequested,
  createNewDraftSuccessful,
  draftUnloaded,
  loadDraftFailed,
  loadDraftRequested,
  loadDraftSuccessful,
  promoteDraftFailed,
  promoteDraftRequested,
  promoteDraftSuccessful,
  saveDraftAndExit,
  saveDraftAndExitFailed,
  saveDraftFailed,
  saveDraftRequested,
  saveDraftSuccessful
} from './loaded-draft.actions';
import { LoadedDraftState } from './loaded-draft.state';

export const initialState: LoadedDraftState = {
  loadedDraft: undefined,
  loadedDraftType: undefined,
  status: 'success'
};

type StateSwitcher = (state: LoadedDraftState, props: any) => LoadedDraftState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });
const failureStateSwitcher: StateSwitcher = state => ({ ...state, status: 'failure' });

export const loadedDraftReducer = createReducer(
  initialState,
  on(loadDraftRequested, (state, props): LoadedDraftState => ({ ...state, status: 'loading', loadedDraft: props.draftId })),
  on(
    loadDraftSuccessful,
    (state, props): LoadedDraftState => ({
      ...state,
      status: 'success',
      loadedDraft: props.draftResponse.draft.id,
      loadedDraftType: props.draftResponse.draft.draftType
    })
  ),
  on(loadDraftFailed, failureStateSwitcher),
  on(saveDraftRequested, loadingStateSwitcher),
  on(saveDraftSuccessful, (state, props): LoadedDraftState => ({ ...state, status: 'success' })),
  on(saveDraftFailed, failureStateSwitcher),
  on(createNewDraftRequested, loadingStateSwitcher),
  on(
    createNewDraftSuccessful,
    (state, props): LoadedDraftState => ({
      ...state,
      status: 'success',
      loadedDraft: props.draftResponse.draft.id,
      loadedDraftType: props.draftResponse.draft.draftType
    })
  ),
  on(createNewDraftFailed, failureStateSwitcher),
  on(promoteDraftRequested, loadingStateSwitcher),
  on(promoteDraftSuccessful, (): LoadedDraftState => initialState),
  on(promoteDraftFailed, failureStateSwitcher),
  on(saveDraftAndExit, loadingStateSwitcher),
  on(saveDraftAndExitFailed, failureStateSwitcher),
  on(draftUnloaded, (): LoadedDraftState => initialState),
  on(logoutRequested, (): LoadedDraftState => initialState)
);
