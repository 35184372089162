import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing, Replay, init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

init({
  dsn: 'https://38ef71e00b5c463680fbdea8609de487@o447954.ingest.sentry.io/5428548',
  environment: environment.production ? 'production' : 'dev',
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', 'beta.wizefi.com', 'my.wizefi.com', 'dev.wizefi.com'],
      routingInstrumentation: instrumentAngularRouting
    }),
    new Replay()
  ],
  tracesSampleRate: 1.0
});

if (environment.production) {
  enableProdMode();
}

// Disable double click throughout the application.
document.addEventListener('dblclick', event => {
  event.preventDefault();
  event.stopPropagation();
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
