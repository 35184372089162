<form [formGroup]="formGroup" (submit)="save()" class="w-full md:w-[480px]">
  <div mat-dialog-title class="!flex items-center">
    <h1 class="text-2xl font-bold">Edit institution</h1>
    <div class="flex-1"></div>
    <div class="mr-4">
      <button
        mat-icon-button
        [color]="'primary'"
        (click)="loadUpdatePlaidLink()"
        type="button"
        [matTooltip]="'Refresh login credentials'"
        *ngIf="!data.institution.isManual"
      >
        <mat-icon>sync_problem</mat-icon>
      </button>
    </div>
    <button
      mat-icon-button
      [color]="'warn'"
      (click)="deleteInstitution()"
      type="button"
      [matTooltip]="'Delete institution'"
      data-test="institution-dialog-delete-institution-button"
    >
      <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <mat-form-field [appearance]="'outline'" class="w-full">
      <mat-label>Institution name</mat-label>
      <input matInput cdkFocusInitial data-test="institution-name-input" [formControl]="formGroup.controls.institutionName" #nameInput />
      <mat-error *ngIf="formGroup.controls.institutionName.errors?.required">Please enter a name for this institution.</mat-error>
    </mat-form-field>
    <div>
      <p class="mb-6 text-middleGray">
        It's recommended to leave all toggles on. Exceptions include:<br /><br />A&#41; Toggle off Transactions if you only want to know the balance
        &#40;not recomended&#41;.<br /><br />B&#41; Toggle off &#34;Active&#34; if it's not personal finance related.
      </p>
    </div>
    <div class="flex border-b border-solid border-lightGray text-xs font-bold">
      <div class="pl-10" [ngClass]="{ 'w-9/12': !data.institution.isManual, 'w-11/12': data.institution.isManual }">Account name</div>
      <div class="mr-4 w-2/12">Transactions</div>
      <div class="w-1/12">Active</div>
    </div>
    <ng-container *ngFor="let account of initialAccounts; index as i">
      <div
        *ngIf="!formGroup.controls.accounts.controls[i].controls.deleted.value"
        class="flex h-14 items-center border-b border-solid border-lightGray"
        data-test="institution-dialog-account-row"
      >
        <div class="flex items-center overflow-hidden" [ngClass]="{ 'w-9/12': !data.institution.isManual, 'w-11/12': data.institution.isManual }">
          <button
            mat-icon-button
            [color]="'warn'"
            [matTooltip]="'Delete account'"
            type="button"
            (click)="deleteAccount(i)"
            data-test="institution-dialog-account-delete-button"
          >
            <mat-icon class="!h-5 !w-5 !text-xl !leading-none" fontSet="material-symbols-outlined">delete</mat-icon>
          </button>
          <h4 class="overflow-hidden text-ellipsis whitespace-nowrap" data-test="institution-dialog-account-name">
            {{ account.accountName }}{{ account.mask ? ' ...' + account.mask : '' }}
          </h4>
        </div>
        <mat-slide-toggle
          class="mr-4 w-2/12"
          [color]="'primary'"
          [formControl]="formGroup.controls.accounts.controls[i].controls.shouldSyncTransactions"
        ></mat-slide-toggle>
        <mat-slide-toggle
          class="w-1/12"
          [color]="'primary'"
          [formControl]="formGroup.controls.accounts.controls[i].controls.isActive"
        ></mat-slide-toggle>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions [align]="'end'">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button mat-flat-button [color]="'primary'" type="submit" data-test="save-institution">Save</button>
  </div>
</form>
