import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Institution } from '@wizefi/entities';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InstitutionService {
  private readonly path = 'institutions';

  public constructor(private api: ApiService) {}

  public getInstitutions(yearMonth: string, autoCreateInstitutions?: boolean, draftId?: string | undefined): Observable<Institution[]> {
    return this.api.get(this.path, { params: { draftId, yearMonth, autoCreate: autoCreateInstitutions ? 'true' : 'false' } });
  }

  public createInstitution(institution: Institution, yearMonth: string, draftId: string | undefined): Observable<Institution> {
    return this.api.post(this.path, institution, { params: { draftId, yearMonth } });
  }

  public editInstitution(institution: Institution, yearMonth: string, draftId: string | undefined): Observable<Institution> {
    return this.api.put(this.path, institution, { params: { draftId, yearMonth } });
  }

  public deleteInstitution(institution: Institution, yearMonth: string, draftId: string | undefined): Observable<Institution> {
    return this.api.delete(this.path, institution, { params: { draftId, yearMonth } });
  }
}
