<button mat-icon-button class="sidebar-toggle" (click)="hideSidebar = !hideSidebar" *ngIf="!pageWithoutSidebar">
  <mat-icon [class.collapsed]="!hideSidebar">chevron_right</mat-icon>
</button>
<ng-container *ngrxLet="isLoggedIn$ as isLoggedIn">
  <ng-container *ngrxLet="isInSetup$ as isInSetup">
    <ng-container *ngrxLet="loggedAsClient$ as loggedAsClient">
      <div *ngIf="isLoggedIn && !isInSetup; else setup_sidebar" class="sidebar-column">
        <img class="wizefi-logo" src="../../../assets/logo_WizeFi.svg" alt="WizeFi Logo" />

        <div class="menu-items-container">
          <ul class="desktop-menu" role="menu">
            <ng-container *ngFor="let option of menuOptions">
              <li
                [class.active]="this.router.url.includes(this.option.route)"
                (click)="optionClick(this.option)"
                *ngIf="option.route !== coachPath || !loggedAsClient"
              >
                <img
                  *ngIf="!option.isMaterialIcon"
                  src="../../../assets/menu_icons/{{ option.icon + (this.router.url.includes(this.option.route) ? '-blue' : '') }}.svg"
                  alt=""
                />
                <mat-icon *ngIf="option.isMaterialIcon">{{ option.icon }}</mat-icon>
                {{ option.title }}
                <span *ngIf="option.title === 'Accounts' && badgeContent > 0" [matBadge]="badgeContent" matBadgeSize="small"> &nbsp; </span>
                <span *ngIf="option.title !== 'Accounts'">&nbsp;</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="expand"></div>
        <div
          class="desktop-menu-bottom"
          (click)="navigateToSettings(isInSetup)"
          [class.active]="router.url.includes(settingsPath)"
          *ngIf="!loggedAsClient"
        >
          <div class="photo-circle"></div>
          <h5>{{ name$ | async }}</h5>
        </div>
        <div class="client-log-in-info" *ngIf="loggedAsClient">
          <p>You're logged in as your client {{ loggedAsClient.firstName + ' ' + loggedAsClient.lastName }}</p>
          <button mat-flat-button [color]="'primary'" (click)="unlogFromClient()" data-test="logout-from-client-button">Click Here to Return</button>
        </div>
      </div>

      <ng-template #setup_sidebar>
        <div class="sidebar-column">
          <app-setup-step-menu *ngIf="isLoggedIn"></app-setup-step-menu>

          <div class="expand"></div>
          <div class="desktop-menu-bottom" (click)="navigateToSettings(isInSetup)" [class.active]="router.url.includes(settingsPath)">
            <div class="photo-circle"></div>
            <h5>{{ name$ | async }}</h5>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
