import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveableScreenService {
  saveScreen: () => Promise<boolean> | boolean = () => true;

  constructor() {}

  resetSaveScreenMethod() {
    this.saveScreen = () => true;
  }
}
