import { createSelector } from '@ngrx/store';
import { selectDraftInfoState } from './draft/draft-info.selectors';
import { selectLoadedDraftState } from './draft/loaded-draft.selectors';
import { selectInstitutionsState } from './institution/institution.root.selectors';
import { selectLoginState } from './login/login.selectors';
import { AccountSelectors } from './account/account.selectors';
import { selectSubscription } from './subscription/subscription.selectors';
import { selectTransactionsState } from './transaction/transaction.selectors';
import { UserDataSelectors } from './user-data/user-data.selectors';
import { selectRulesState } from './transaction-rules/transaction-rules.selectors';
import { CoachSelectors } from './coach/coach.selectors';
import { PayoutRequestSelectors } from './payout-request/payout-request.selectors';

export class AppSelectors {
  static anyStateLoading = createSelector(
    UserDataSelectors.selectState,
    selectInstitutionsState,
    AccountSelectors.selectState,
    selectTransactionsState,
    selectLoadedDraftState,
    selectDraftInfoState,
    selectLoginState,
    selectSubscription,
    selectRulesState,
    PayoutRequestSelectors.state,
    (userData, institutions, accounts, transactions, loadedDraft, draftInfo, login, subscription, rules, payoutRequest) =>
      userData.status === 'loading' ||
      institutions.status === 'loading' ||
      accounts.status === 'loading' ||
      transactions.status === 'loading' ||
      loadedDraft.status === 'loading' ||
      draftInfo.status === 'loading' ||
      login.loginStatus === 'loading' ||
      login.signupStatus === 'loading' ||
      subscription.loadingStatus === 'loading' ||
      rules.status === 'loading' ||
      payoutRequest.status === 'loading'
  );

  static allDataForIntercom = createSelector(
    UserDataSelectors.selectState,
    selectLoginState,
    selectSubscription,
    CoachSelectors.state,
    (userData, login, subscription, coach) => ({ userData, login, subscription, coach })
  );
}
