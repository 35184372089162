import { Injectable } from '@angular/core';
import { Account, Institution, InstitutionItem, NotificationPreferences, TransactionsResponse } from '@wizefi/entities';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { MessageService } from './message/message.service';
import { NotificationPreferencesService } from './notification-preferences.service';

export type PlaidCountryCodes = 'US' | 'GB' | 'ES' | 'NL' | 'FR' | 'IE' | 'CA' | 'DE';
export interface LinkPlaidInstitutionResult {
  institution: Institution;
  newAccounts: Account[];
}
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Plaid: any;

@Injectable({ providedIn: 'root' })
export class PlaidService {
  private readonly path = 'plaid';
  notificationPreferences?: NotificationPreferences;

  private readonly selectedCountryCodes: PlaidCountryCodes[] = ['US', 'CA'];
  institutionImgCache: { [institutionId: string]: Observable<string> } = {};

  constructor(
    private readonly api: ApiService,
    private readonly messageService: MessageService,
    private readonly notificationService: NotificationPreferencesService
  ) {}

  updateBalances(): Observable<{ accounts: Account[]; institutions: Institution[] }> {
    return this.api.post(this.path + '/updateBalances', undefined, { redirectToErrorPage: () => false }).pipe(
      catchError(err => (err.status === 504 ? of() : throwError(err))) // Catch and ignore timeouts happening when syncing balances with plaid
    );
  }

  syncTransactions(yearMonth: string): Observable<TransactionsResponse> {
    return this.api.post(this.path + '/syncTransactionsWeb', { yearMonth });
  }

  getInstitutionLogo(institutionId: string): Observable<string> {
    const logo$ = this.api.post(this.path + '/getInstitutionLogo', { institutionId }).pipe(
      shareReplay(1),
      map(result => result.logo)
    );
    this.institutionImgCache[institutionId] = logo$;
    return logo$;
  }

  linkNewPlaidInstitution(
    accounts: Account[],
    institutions: Institution[],
    yearMonth: string,
    draftId?: string
  ): Observable<LinkPlaidInstitutionResult> {
    return this.loadPlaidLink().pipe(
      map(result => ({ ...result, isNewInstitution: this.isNewInstitutionInstance(result.metadata, accounts, institutions) })),
      switchMap(({ publicToken, isNewInstitution }) => {
        if (!isNewInstitution) {
          this.messageService.error('This institution has already been linked to your WizeFi Account', 20000);
          return of(undefined);
        }
        return of(publicToken);
      }),
      catchError(err => of(undefined)),
      filter(publicTokenOrUndefined => !!publicTokenOrUndefined),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      mergeMap(publicToken => this.linkPlaidAccount(publicToken!, yearMonth, draftId)),
      tap(result => this.messageService.success('Link with ' + result.institution.institutionName + ' established.', 5000))
    );
  }

  private loadPlaidLink() {
    return this.createLinkToken().pipe(switchMap(linkToken => this.plaidLinkHandler(linkToken)));
  }

  private linkPlaidAccount(publicToken: string, yearMonth: string, draftId?: string): Observable<LinkPlaidInstitutionResult> {
    return this.api.post(
      this.path + '/linkPlaidAccount',
      { publicToken, countryCodes: this.selectedCountryCodes, yearMonth },
      { params: { draftId } }
    );
  }

  private createLinkToken(): Observable<string> {
    return this.api.post(this.path + '/createLinkToken', { countryCodes: this.selectedCountryCodes }).pipe(map(result => result.message));
  }

  private createInstitutionLinkToken(itemId: string, yearMonth: string, draftId?: string): Observable<string> {
    return this.api
      .post(this.path + '/createInstitutionLinkToken', { countryCodes: this.selectedCountryCodes, itemId }, { params: { draftId, yearMonth } })
      .pipe(map(result => result.message));
  }

  private plaidLinkHandler(linkToken: string): Observable<{ publicToken: string; metadata: any }> {
    return new Observable(subscriber => {
      const configs = {
        token: linkToken,
        onSuccess: async (publicToken: string, metadata: any) => {
          subscriber.next({ publicToken, metadata });
          subscriber.complete();
        },
        onExit: async (err: any) => {
          if (err != null) {
            subscriber.error(err);
          }
        }
      };
      const linkHandler = Plaid.create(configs);
      linkHandler.open();
    });
  }

  private isNewInstitutionInstance(metadata: any, existingAccounts: Account[], existingInstitutions: Institution[]) {
    const makeAccountListString = (accountList: Account[]) => accountList.sort().reduce((prev, cur) => `${prev}:${cur.accountName}(${cur.mask})`, '');

    const institutionsIdByItemId = existingInstitutions.reduce(
      (prev, cur) => ({ ...prev, [cur.itemId]: cur.institutionId }),
      {} as { [key: string]: string }
    );

    const newAccountListString = makeAccountListString(metadata.accounts.map((a: any) => ({ ...a, accountName: a.name })));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const existingAccountsInSameInstitution = existingAccounts.filter(a => institutionsIdByItemId[a.itemId!] === metadata.institution.institution_id);
    const existingAccountsGroupedByItemId = existingAccountsInSameInstitution.reduce(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (prev, cur) => ({ ...prev, [cur.itemId!]: prev[cur.itemId!] ? [...prev[cur.itemId!], cur] : [cur] }),
      {} as { [key: string]: Account[] }
    );
    return Object.values(existingAccountsGroupedByItemId).every(accounts => makeAccountListString(accounts) !== newAccountListString);
  }

  refreshPlaidInstitutionCredentials(itemId: string, yearMonth: string, draftId?: string): Observable<{ publicToken: string; metadata: any }> {
    return this.createInstitutionLinkToken(itemId, yearMonth, draftId).pipe(
      switchMap(linkToken => this.plaidLinkHandler(linkToken)),
      tap(() => {
        this.messageService.success('Your account has been successfully reconnected to WizeFi');
        this.notificationService.get().subscribe({
          next: result => {
            this.notificationPreferences = result;
          }
        });

        if (this.notificationPreferences) {
          this.notificationPreferences.dontShowAgainItemError = true;
          this.notificationService.put(this.notificationPreferences).subscribe(() => {});
        }
      }),
      catchError(err => (err.error_code === 'item-no-error' ? of<{ publicToken: string; metadata: any }>() : throwError(err)))
    );
  }

  getInstitutionsItemsByWizefiId(): Observable<InstitutionItem | InstitutionItem[] | undefined> {
    return this.api.get(this.path + '/institutionsItems').pipe(catchError(err => (err.status === 504 ? of() : throwError(err))));
  }
}
