import { Component, HostBinding, OnInit } from '@angular/core';
import { BraintreeService } from '@app/services/braintree.service';
import { MessageService } from '@app/services/message/message.service';
import { SubscriptionAction } from '@app/state/subscription/subscription.actions';
import { Store } from '@ngrx/store';

declare const braintree: any;

@Component({
  selector: 'app-subscription-signup',
  templateUrl: './subscription-signup.component.html',
  styleUrls: ['./subscription-signup.component.scss']
})
export class SubscriptionSignupComponent implements OnInit {
  isLoading = false;
  private braintreeWidgetInstance: any;
  nameFirst = '';
  nameLast = '';
  @HostBinding('attr.data-test') dataTestAttr = '';

  constructor(private messageService: MessageService, private braintreeService: BraintreeService, private store: Store) {}

  ngOnInit() {
    this.establishPaymentControl();
  }

  register() {
    if (!this.braintreeWidgetInstance || !this.validateProfile()) {
      return;
    }

    this.isLoading = true;

    this.braintreeWidgetInstance.requestPaymentMethod((requestPaymentMethodErr: any, noncePayload: { nonce: string }) => {
      if (requestPaymentMethodErr) {
        console.error('Error in requestPaymentMethod: ', requestPaymentMethodErr);
        this.isLoading = false;
      } else {
        this.store.dispatch(
          SubscriptionAction.createSubscriptionRequested({
            firstName: this.nameFirst,
            lastName: this.nameLast,
            paymentMethodNonce: noncePayload.nonce
          })
        );
      }
    });
  }

  establishPaymentControl() {
    // Sample credit card number: 4111111111111111
    this.braintreeService.generateClientToken().subscribe(
      token => this.establishPaymentMethodWidget(token),
      err => console.error('Error in braintree widget', err)
    );
  }

  private establishPaymentMethodWidget(clientToken: string) {
    braintree.dropin.create({ authorization: clientToken, container: '#braintree-widget-container' }, (createErr: any, instance: any) => {
      if (createErr) {
        console.error('Error in dropin.create: ' + createErr);
      } else {
        this.braintreeWidgetInstance = instance;
        this.dataTestAttr = 'loaded-braintree-instance';
      }
    });
  }

  private validateProfile(): boolean {
    if (!this.nameFirst || !this.nameLast) {
      this.messageService.error(`Please enter a value for First and Last Name`, 5000);
      return false;
    }
    return true;
  }
}
