import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoachSelectors } from '@app/state/coach/coach.selectors';
import { selectToken } from '@app/state/login/login.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  public constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.apiUrl)) {
      return next.handle(req);
    }

    return combineLatest([this.store.select(selectToken), this.store.select(CoachSelectors.loggedAsClient)]).pipe(
      take(1),
      switchMap(([token, loggedAsClient]) => {
        if (!token) {
          return next.handle(req);
        }
        const withAuth = req.clone({ setHeaders: { ['Authorization']: token } });
        const finalReq = loggedAsClient ? withAuth.clone({ setHeaders: { ['loggedasuser']: loggedAsClient.clientId } }) : withAuth;
        return next.handle(finalReq);
      })
    );
  }
}
