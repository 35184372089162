import { Injectable } from '@angular/core';
import { Draft, DraftInfo, DraftType } from '@wizefi/entities';
import { DraftGetResponse } from '@app/interfaces/draft-get-response';
import { Observable } from 'rxjs';
import { mapTo, shareReplay, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class DraftService {
  private readonly path = 'draft';
  private readonly renamePath = '/rename';
  private readonly promotePath = '/promote';

  private cachedDraftList$: Observable<DraftInfo[]> | undefined;

  public constructor(private api: ApiService) {}

  public loadDraftInfo(): Observable<DraftInfo[]> {
    if (this.cachedDraftList$) {
      return this.cachedDraftList$;
    }
    this.cachedDraftList$ = this.api.get(this.path + '/list').pipe(shareReplay());
    return this.cachedDraftList$;
  }

  public loadDraft(draftId: string): Observable<DraftGetResponse> {
    const apiRequest$ = this.api.get(this.path + '/' + draftId).pipe(shareReplay());
    return apiRequest$;
  }

  public createDraft(draftType: DraftType, draftName: string): Observable<DraftGetResponse> {
    return this.api.post(this.path, { draftType, draftName }).pipe(tap(() => this.clearCache()));
  }

  public updateDraft(draft: Draft): Observable<DraftInfo> {
    return this.api.put(this.path, draft).pipe(tap(() => this.clearCache()));
  }

  public deleteDraft(draftId: string): Observable<string> {
    return this.api.delete(this.path, { draftId }).pipe(
      tap(() => this.clearCache()),
      mapTo(draftId)
    );
  }

  renameDraft(draftId: string, newName: string): Observable<DraftInfo> {
    return this.api.put(this.path + this.renamePath, { draftId, newName }).pipe(tap(() => this.clearCache()));
  }

  promoteDraft(draftId: string, versionHistoryName: string): Observable<string> {
    return this.api.post(this.path + this.promotePath, { draftId, versionHistoryName }).pipe(
      tap(() => this.clearCache()),
      mapTo(draftId)
    );
  }

  public clearCache() {
    this.cachedDraftList$ = undefined;
  }
}
