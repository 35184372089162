import { TransactionRule } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export const transactionRulesGetRequested = createAction('[App] Transaction rules were requested');
export const transactionRulesGetSuccessful = createAction('[App] Transaction rules get was successful', props<{ rules: TransactionRule[] }>());
export const transactionRulesGetFailed = createAction('[App] Transaction rules get failed', props<{ err: any }>());

export const ruleCreationRequested = createAction(
  '[TransactionRuleDialog] Transaction rules creation was requested',
  props<{ rule: TransactionRule }>()
);
export const ruleCreationSuccessful = createAction('[App] Transaction rule creation was successful', props<{ rule: TransactionRule }>());
export const ruleCreationFailed = createAction('[App] Transaction rule creation failed', props<{ err: any }>());

export const ruleUpdateRequested = createAction('[TransactionRuleDialog] Transaction rule update was requested', props<{ rule: TransactionRule }>());
export const ruleUpdateSuccessful = createAction('[App] Transaction rule update was successful', props<{ rule: TransactionRule }>());
export const ruleUpdateFailed = createAction('[App] Transaction rule update failed', props<{ err: any }>());

export const ruleDeleteRequested = createAction('[TransactionRuleDialog] Transaction rule delete was requested', props<{ rule: TransactionRule }>());
export const ruleDeleteSuccessful = createAction('[App] Transaction rule delete was successful', props<{ rule: TransactionRule }>());
export const ruleDeleteFailed = createAction('[App] Transaction rule delete failed', props<{ err: any }>());
