import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appCurrencyInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CurrencyInputDirective,
      multi: true
    }
  ]
})
export class CurrencyInputDirective implements ControlValueAccessor {
  @Input() allowNegative: boolean | undefined;
  private onChange!: (val: number) => void;
  private onTouch!: () => void;

  constructor(private element: ElementRef<HTMLInputElement>, private currencyPipe: CurrencyPipe) {}

  writeValue(obj: number): void {
    const formattedValue = this.currencyPipe.transform(obj, undefined, '', '1.2-2');
    this.element.nativeElement.value = formattedValue ?? '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  @HostListener('focus', ['$event'])
  public onHostFocus(event: Event): void {
    this.onTouch?.();
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    const htmlInput = this.element.nativeElement;
    htmlInput.disabled = isDisabled;
  }

  @HostListener('keypress', ['$event'])
  public onKeyPress(event: KeyboardEvent): void {
    const eventIsNumber = event.key >= '0' && event.key <= '9';

    if (eventIsNumber) {
      return;
    }

    const eventIsSign = event.key === '-' || event.key === '+';
    if (this.allowNegative && eventIsSign) {
      return;
    }

    const passthroughKeycodes = new Set(['NumpadEnter', 'Enter']);
    if (passthroughKeycodes.has(event.code)) {
      return;
    }

    event.preventDefault();
  }

  @HostListener('input', ['$event'])
  public onInput(event: InputEvent): void {
    const htmlInput = this.element.nativeElement;
    const start = htmlInput.selectionStart ?? 0;
    // Remove all dots, commas and spaces to get clean value, which is the price * 100 (cents become integers)
    let cleanValue = this.element.nativeElement.value.replace(/\./g, '');
    cleanValue = cleanValue.replace(/,/g, '');
    cleanValue = cleanValue.replace(/ /g, '');

    const containsNegativeSign = cleanValue.includes('-');
    cleanValue = cleanValue.replace(/-/g, '');
    cleanValue = containsNegativeSign ? `-${cleanValue || 0}` : cleanValue;

    const containsPositiveSign = cleanValue.includes('+');
    cleanValue = cleanValue.replace(/\+/g, '');
    cleanValue = containsPositiveSign ? cleanValue.replace(/-/g, '') : cleanValue;

    // We use .toFixed(2) to fix any floating point precision errors
    const cleanValueNumber = Number((Number(cleanValue) / 100).toFixed(2));

    const shouldSetToNegativeZero = containsNegativeSign && !containsPositiveSign && cleanValueNumber === 0;

    const formattedValue = this.currencyPipe.transform(cleanValueNumber, undefined, '', '1.2-2') ?? '';
    const valueBeforeFormatting = htmlInput.value;
    htmlInput.value = shouldSetToNegativeZero ? '-0.00' : formattedValue;
    if (valueBeforeFormatting.length < 4) {
      htmlInput.setSelectionRange(formattedValue.length, formattedValue.length);
    } else {
      const commasAdded = this.commasAddedUntilSelection(valueBeforeFormatting, formattedValue, start);
      htmlInput.setSelectionRange(start + commasAdded, start + commasAdded);
    }
    this.onChange(cleanValueNumber);
  }

  private commasAddedUntilSelection(valueBeforeFormatting: string, valueAfterFormatting: string, selectionStart: number) {
    const commasBeforeFormatting = valueBeforeFormatting.substring(0, selectionStart).split(',').length - 1;
    const commasAfterFormatting = valueAfterFormatting.substring(0, selectionStart).split(',').length - 1;
    return commasAfterFormatting - commasBeforeFormatting;
  }
}
