import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SetupStepState } from '../setup-step-menu.component';

@Component({
  selector: 'app-setup-step-menu-item',
  templateUrl: './setup-step-menu-item.component.html',
  styleUrls: ['./setup-step-menu-item.component.scss']
})
export class SetupStepMenuItemComponent {
  @Output() public stepClicked: EventEmitter<number> = new EventEmitter<number>();
  @Input() public state!: SetupStepState;
  @Input() public stepNumber!: number;
  @Input() public stepName!: string;

  public _onStepClicked() {
    this.stepClicked.emit(this.stepNumber);
  }
}
