import { Account, Transaction, BraintreeData, UserData, Institution } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export class LoginActions {
  static loginRequested = createAction('[LoginComponent] login requested', props<{ email: string; password: string }>());
  static loginSuccessful = createAction(
    '[App] login successful',
    props<{
      token: string;
      id: string;
      institutions: Institution[];
      userData: UserData;
      originalAccounts: Account[];
      accounts: Account[];
      braintreeData: BraintreeData;
      transactions: Transaction[];
    }>()
  );
  static loginFailed = createAction('[App] login failed', props<{ err: any }>());
  static verificationCodeRequested = createAction('[LoginComponent] verification code requested', props<{ email: string }>());
  static verificationCodeSuccessful = createAction('[LoginComponent] verification code successful', props<{ email: string }>());
  static resetPasswordCodeRequested = createAction('[LoginComponent] reset password requested', props<{ email: string }>());
  static resetPasswordCodeSuccessful = createAction('[LoginComponent] reset password successful');
  static verificationCodeFailed = createAction('[LoginComponent] verification code failed', props<{ err: any }>());
  static reloginRequested = createAction('[ReloginComponent] relogin requested', props<{ token: string; id: string; url: string }>());
  static reloginSuccessful = createAction('[ReloginComponent] relogin successful');
  static reloginFailed = createAction('[ReloginComponent] relogin failed');
  static resendVerificationCodeRequested = createAction('[ValidateEmailComponent] resend verification code requested', props<{ email: string }>());
  static resendVerificationCodeSuccessful = createAction('[ValidateEmailComponent] resend verification code successful', props<{ email: string }>());
  static resendVerificationCodeFailed = createAction('[ValidateEmailComponent] resend verification code failed', props<{ err: any }>());
  static confirmRegistrationRequested = createAction(
    '[ValidateEmailComponent] confirm registration requested',
    props<{ email: string; verificationToken: string }>()
  );
  static signupRequested = createAction(
    '[CreateAccountSignupComponent] signup requested',
    props<{ nameFirst?: string; nameLast?: string; email: string; password: string; parentAffiliateId: string; isEnterpriseMember?: boolean }>()
  );
  static signupSuccessful = createAction('[CreateAccountSignupComponent] signup successful');
  static signupFailed = createAction('[CreateAccountSignupComponent] signup failed');
  static changePasswordRequested = createAction(
    '[SettingsDetailsComponent] change password requested',
    props<{ oldPassword: string; newPassword: string }>()
  );
  static updateEmailRequested = createAction('[SettingsDetailsComponent] update email requested', props<{ password: string; newEmail: string }>());
  static updateEmailSuccessful = createAction('[SettingsDetailsComponent] update email successful', props<{ newEmail: string }>());
  static resetPasswordRequested = createAction(
    '[ResetPasswordComponent] reset password requested',
    props<{ email: string; verificationCode: string; newPassword: string }>()
  );
}
