import { createReducer, on } from '@ngrx/store';
import { logoutRequested } from '../screen.actions';
import { CoachActions } from './coach.actions';
import { CoachState } from './coach.state';

export const initialState: CoachState = {
  loggedAsClient: undefined,
  coachUserData: undefined
};

export const coachReducer = createReducer(
  initialState,
  on(CoachActions.logAsClient, (state, props): CoachState => ({ ...state, loggedAsClient: props.client })),
  on(CoachActions.logoutAsClient, (): CoachState => initialState),
  on(logoutRequested, (): CoachState => initialState)
);
