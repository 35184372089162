import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SETTINGS_PATH, SETUP_PATH, SUBSCRIPTION_PATH } from '@app/modules/route-paths';
import { MessageService } from '@app/services/message/message.service';
import { selectLoadedSubscription } from '@app/state/subscription/subscription.selectors';
import { UserDataSelectors } from '@app/state/user-data/user-data.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BraintreeSubscriptionGuard implements CanActivate {
  constructor(private router: Router, private messageService: MessageService, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this.store.pipe(selectLoadedSubscription), this.store.pipe(UserDataSelectors.selectHasCompletedSetup)]).pipe(
      map(([subscriptionData, hasCompletedSetup]) => {
        if (!subscriptionData.haveBraintreeAccount) {
          return this.router.createUrlTree(['/', SUBSCRIPTION_PATH]);
        }
        if (!subscriptionData.isActive) {
          this.messageService.error('Your subscription is no longer active.');
          return hasCompletedSetup
            ? this.router.createUrlTree(['/', SETTINGS_PATH], { fragment: 'account' })
            : this.router.createUrlTree(['/', SETUP_PATH, SETTINGS_PATH], { fragment: 'account' });
        }
        return true;
      })
    );
  }
}
