import { Account, AccountCategory, AccountType } from '@wizefi/entities';
import * as moment from 'moment';

export type ProjectionType = 'original' | 'current' | 'guideline';
const isDebtAccount = (account: Account) => account.type === 'liabilities';

const hasReachedTargetBalance = (account: Account) => typeof account.targetAmount === 'number' && account.balance >= account.targetAmount;
const hasShadowAccount = (account: Account) => !!account.budgetSubcategory;
const getExtraYouPay = (account: Account) => account.monthlyAmount - account.monthlyMinimum;
const relevantAccountForProjections = (account: Account) =>
  (['assets', 'assetProtection', 'liabilities', 'income'] as (AccountType | undefined)[]).includes(account.type) &&
  account.isActive &&
  !account.needsActivation;
const relevantInsuranceTypes = (account: Account) =>
  account.type !== 'assetProtection' || (account.type === 'assetProtection' && account.category === 'permInsurance');
const notPaidInFullAccount = (account: Account) => !account.paidInFullEachMonth;

export const maxYearsOfProjections = 100;
const monthsInAYear = 12;
export const guidelinePercentageTo4StepPlan = 0.2;
export const guidelinePercentageToSpendings = 1 - guidelinePercentageTo4StepPlan;
const fallbackAccountId = 'id';
export const fixedGuidelineBudgetPercentages = {
  giving: 0.1,
  housing: 0.325,
  transportation: 0.125,
  food: 0.2,
  health: 0.15,
  clothing: 0.05,
  entertainment: 0.05,
  otherBudget: 0
};

export interface ProjectionCalculationOptions {
  accounts: Account[];
  startingDate: Date | moment.Moment;
  endingDate: Date | moment.Moment;
  ffGoal: number;
  projectionType: ProjectionType;
  averageInvestmentInterestRate: number;
  assumedLoanInterestRate: number;
}

export const calculateProjections = (params: ProjectionCalculationOptions): Projection[] => {
  const ffdAfterSpendingsAnd4Step = getPlannedFfdAfterSpendingsAnd4Step(params.accounts);

  let accounts: AccountWithExtraFields[] = params.accounts
    .filter(relevantAccountForProjections)
    .filter(relevantInsuranceTypes)
    .filter(notPaidInFullAccount);
  accounts = createFallbackAccounts(accounts, params.projectionType, params.averageInvestmentInterestRate, params.assumedLoanInterestRate);
  accounts = convertDebtAccountsBalance(accounts);
  accounts = convertInterestRateForDepreciation(accounts);
  let currentIterationDate = moment(params.startingDate);
  const guidelineValue = getGuidelineTo4StepPlan(accounts);
  const projections: Projection[] = [];

  const { netWorth: initialNetWorth, totalDebt: initialDebt } = getNetWorthAndTotalDebt(accounts);
  projections.push({
    date: currentIterationDate.toDate(),
    netWorth: initialNetWorth,
    totalDebt: initialDebt
  });

  for (let iteration = 0; ; iteration++) {
    accounts = addMonthlyAmounts(accounts, params.projectionType);
    const limitedAccountsAndRolloverFfd = limitToTargetBalanceAndGetRolloverFfd(accounts, params.projectionType, ffdAfterSpendingsAnd4Step);
    accounts = limitedAccountsAndRolloverFfd.accounts;
    let remainingFfd = calculateRemainingFfd(accounts, params.projectionType, guidelineValue, limitedAccountsAndRolloverFfd.rolloverFfd);

    ({ remainingFfd, accounts } = applyOverspentAmountToAssumedDebt(accounts, remainingFfd));
    accounts = applyRemainingFfdTo4StepPlan(accounts, remainingFfd);
    accounts = addMonthlyInterestInAccounts(accounts);

    const { netWorth, totalDebt } = getNetWorthAndTotalDebt(accounts);
    currentIterationDate = getNextIterationDate(currentIterationDate);

    projections.push({
      date: currentIterationDate.toDate(),
      netWorth,
      totalDebt
    });

    if (!shouldContinueProjections(netWorth, totalDebt, currentIterationDate, params.endingDate, params.ffGoal)) {
      break;
    }
  }

  return projections;
};

/**
 * Gets net worth and total debt, assuming debt accounts already have a negative balance
 */
const getNetWorthAndTotalDebt = (accounts: Account[]): NetWorthAndTotalDebt => {
  const netWorth = accounts.reduce<number>((prev, cur) => prev + cur.balance, 0);
  const totalDebt = accounts.filter(isDebtAccount).reduce<number>((prev, cur) => prev - cur.balance, 0);
  return {
    netWorth,
    totalDebt
  };
};

const shouldContinueProjections = (
  netWorth: number,
  totalDebt: number,
  currentIterationDate: moment.Moment,
  endingDate: Date | moment.Moment,
  ffGoal: number
): boolean => {
  const hasReachedEndingDate = moment(currentIterationDate).isSameOrAfter(endingDate);
  if (hasReachedEndingDate) {
    return false;
  }

  if (netWorth >= ffGoal && totalDebt <= 0) {
    return false;
  }
  return true;
};

const getNextIterationDate = (currentIterationDate: moment.Moment): moment.Moment => currentIterationDate.add(1, 'month');

const getGuidelineTo4StepPlan = (accounts: Account[]): number => {
  const minimumsForNonShadowAccounts = accounts.filter(a => !hasShadowAccount(a)).reduce<number>((prev, cur) => prev + cur.monthlyMinimum, 0);
  const minimumsForShadowAccounts = accounts.filter(hasShadowAccount).reduce((prev, cur) => prev + cur.monthlyMinimum, 0);
  const totalIncome = getTotalIncome(accounts);
  if (minimumsForShadowAccounts > guidelinePercentageToSpendings * totalIncome) {
    return totalIncome - minimumsForShadowAccounts;
  }
  return Math.max(getTotalIncome(accounts) * guidelinePercentageTo4StepPlan, minimumsForNonShadowAccounts);
};

const getTotalIncome = (accounts: Account[]): number => accounts.reduce<number>((prev, cur) => prev + cur.monthlyIncome, 0);

const addMonthlyInterestInAccounts = (accounts: AccountWithExtraFields[]): AccountWithExtraFields[] =>
  accounts.map(a => {
    const interest = (a.interestRate ?? 0) / (100 * monthsInAYear);
    return { ...a, balance: a.balance * (1 + interest), accumulatedInterest: (a.accumulatedInterest ?? 0) + interest };
  });

/**
 * For guideline, we assume all monthly payments are 0 (besides employer contribution), and the rest is processed by the 4-step plan
 */
const addMonthlyAmounts = (accounts: Account[], projectionType: ProjectionType): Account[] => {
  const appliedMinimumAccounts = applyMonthlyMinimums(accounts);
  if (projectionType === 'guideline') {
    return appliedMinimumAccounts.map(a => ({ ...a, balance: a.balance + a.employerContribution }));
  } else {
    return appliedMinimumAccounts.map(a => ({ ...a, balance: a.balance + getExtraYouPay(a) + a.employerContribution }));
  }
};

const applyMonthlyMinimums = (accounts: Account[]): Account[] => {
  const shadowAccounts = accounts.filter(hasShadowAccount);
  const nonShadowAccounts = accounts.filter(a => !hasShadowAccount(a));

  const shadowAccountsAfterMinimums = shadowAccounts.map<Account>(a => ({
    ...a,
    balance: a.balance + a.monthlyMinimum > (a.targetAmount ?? 0) ? a.targetAmount ?? 0 : a.balance + a.monthlyMinimum
  }));
  const shadowAccountsRemovingMinimumsAfterTargetBalance = shadowAccountsAfterMinimums.map(a => ({
    ...a,
    monthlyMinimum: hasReachedTargetBalance(a) ? 0 : a.monthlyMinimum, // Remove monthly minimums (which should not be redistributed in shadow accounts)
    monthlyAmount: hasReachedTargetBalance(a) ? getExtraYouPay(a) : a.monthlyAmount
  }));

  const nonShadowAccountsAfterMinimums = nonShadowAccounts.map(a => ({ ...a, balance: a.balance + a.monthlyMinimum }));
  return [...nonShadowAccountsAfterMinimums, ...shadowAccountsRemovingMinimumsAfterTargetBalance];
};

const limitToTargetBalanceAndGetRolloverFfd = (
  accounts: Account[],
  projectionType: ProjectionType,
  ffdAfterSpendingsAnd4Step: number
): { rolloverFfd: number; accounts: Account[] } => {
  const isOverTargetBalance = (account: Account) => typeof account.targetAmount === 'number' && account.balance > account.targetAmount;
  const accountsOverTargetBalance = accounts.filter(isOverTargetBalance);
  const rolloverFfd = accountsOverTargetBalance.reduce<number>((prev, cur) => prev + (cur.balance - (cur.targetAmount ?? 0)), 0);
  const equalizedBalanceAccounts = accounts.map(a => ({ ...a, balance: isOverTargetBalance(a) ? a.targetAmount ?? 0 : a.balance }));

  const overspentFfd = ffdAfterSpendingsAnd4Step > 0 ? 0 : ffdAfterSpendingsAnd4Step;
  let overspentMonthlyMinimums = getTotalIncome(accounts) - accounts.reduce((prev, cur) => prev + cur.monthlyMinimum, 0);
  overspentMonthlyMinimums = overspentMonthlyMinimums > 0 ? 0 : overspentMonthlyMinimums;

  const rolloverFfdAfterOverexpenses = projectionType === 'guideline' ? rolloverFfd + overspentMonthlyMinimums : rolloverFfd + overspentFfd;

  return {
    rolloverFfd: projectionType === 'original' && rolloverFfdAfterOverexpenses > 0 ? 0 : rolloverFfdAfterOverexpenses,
    accounts:
      projectionType === 'original'
        ? accounts.map(a => (isDebtAccount(a) && isOverTargetBalance(a) ? { ...a, balance: 0 } : a))
        : equalizedBalanceAccounts
  };
};

const applyOverspentAmountToAssumedDebt = (accounts: Account[], remainingFfd: number) => {
  if (remainingFfd >= 0) {
    return { accounts, remainingFfd };
  }

  return {
    accounts: accounts.map(a => (a.id === fallbackAccountId && a.type === 'liabilities' ? { ...a, balance: a.balance + remainingFfd } : a)),
    remainingFfd: 0
  };
};

const calculateRemainingFfd = (accounts: Account[], projectionType: ProjectionType, guidelineValue: number, rolloverFfd: number) => {
  if (projectionType === 'guideline') {
    const totalMonthlyMinimums = accounts.filter(a => !hasShadowAccount(a)).reduce((prev, cur) => prev + cur.monthlyMinimum, 0);
    return guidelineValue - totalMonthlyMinimums + rolloverFfd;
  }
  return rolloverFfd;
};

const createFallbackAccounts = (
  accounts: Account[],
  projectionType: ProjectionType,
  averageInvestmentInterestRate: number,
  assumedLoanInterestRate: number
): Account[] => {
  const fallbackDebt = createFallbackAccount('liabilities', 'other', 0, assumedLoanInterestRate);

  if (projectionType === 'original') {
    return [...accounts, fallbackDebt];
  }

  const fallbackInvestments = createFallbackAccount('assets', 'investments', undefined, averageInvestmentInterestRate);
  return [...accounts, fallbackDebt, fallbackInvestments];
};

const applyRemainingFfdTo4StepPlan = (accounts: Account[], remainingFfd: number): Account[] => {
  const accounts4StepPlanSorted = sortAccountsTo4StepPlanOrder(accounts.filter(a => !hasReachedTargetBalance(a) && a.category !== 'mortgageLimited'));
  let accountIdx = 0;
  while (remainingFfd > 0) {
    const accountToApply = accounts4StepPlanSorted[accountIdx];
    const amountNeededFill =
      accountToApply.targetAmount === undefined ? Number.MAX_SAFE_INTEGER : accountToApply.targetAmount - accountToApply.balance;
    const amountApplied = Math.min(amountNeededFill, remainingFfd);
    accountToApply.balance += amountApplied;
    remainingFfd -= amountApplied;
    if (hasReachedTargetBalance(accountToApply)) {
      accountIdx += 1;
    }
  }

  return accounts;
};

const convertDebtAccountsBalance = (accounts: Account[]): Account[] =>
  accounts.map(a => (isDebtAccount(a) ? { ...a, balance: -Math.abs(a.balance), targetAmount: 0 } : a));

const convertInterestRateForDepreciation = (accounts: Account[]): Account[] =>
  accounts.map(a =>
    (a.category === 'personalProperty' || a.category === 'limitedPersonalProperty') && a.productivity === 'Non-productive'
      ? { ...a, interestRate: -a.interestRate }
      : { ...a }
  );

/**
 * First emergencySavings accounts (sorted by lowest targetAmount first),
 * then debt accounts (sorted by lowest balance first),
 * then generalSavings accounts, then fallback investments account
 */
export const sortAccountsTo4StepPlanOrder = (accounts: Account[]): Account[] => {
  const fourStepPlanSort = (a: Account, b: Account): number => {
    const categorySortNumber = (account: Account): number => {
      if (account.category === 'emergencySavings') {
        return 0;
      }
      if (account.type === 'liabilities' && account.category !== 'mortgageLimited') {
        return 1000;
      }
      if (account.category === 'cashReserves') {
        return 2000;
      }
      return 3000;
    };
    const targetSortNumber = (accountA: Account, accountB: Account): number => {
      if (
        (isDebtAccount(accountA) && isDebtAccount(accountB)) ||
        (accountA.category === 'mortgageLimited' && accountB.category === 'mortgageLimited')
      ) {
        return sortDebtAccounts(accountA, accountB);
      }
      if (accountA.targetAmount && accountB.targetAmount) {
        return sortNonDebtAccounts(accountA, accountB);
      }
      if (accountA.category === 'mortgageLimited' && accountB.category !== 'mortgageLimited') {
        return -1;
      }
      if (accountA.category !== 'mortgageLimited' && accountB.category === 'mortgageLimited') {
        return 1;
      }
      if (accountA.id === fallbackAccountId && accountB.id !== fallbackAccountId) {
        return -1;
      }
      if (accountA.id !== fallbackAccountId && accountB.id === fallbackAccountId) {
        return 1;
      }
      return accountA.accountName.localeCompare(accountB.accountName);
    };
    return categorySortNumber(a) - categorySortNumber(b) + targetSortNumber(a, b);
  };

  return [...accounts].sort(fourStepPlanSort);
};

export const getGuidelineBudgetDistribution = (accounts: Account[], totalGuidelineForSpendings: number) => {
  const shadowAccountsMinimums: { [key: string]: number } = accounts.filter(hasShadowAccount).reduce(
    (prev, cur) => ({
      ...prev,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [cur.budgetSubcategory!]: prev[cur.budgetSubcategory!] ? prev[cur.budgetSubcategory!] + cur.monthlyMinimum : cur.monthlyMinimum
    }),
    {} as { [key: string]: number }
  );

  const isMinimumAboveSuggested = (category: keyof typeof fixedGuidelineBudgetPercentages): boolean =>
    shadowAccountsMinimums[category] > fixedGuidelineBudgetPercentages[category] * totalGuidelineForSpendings;

  const isTotalGuidelineSpentOnMinimums = Object.values(shadowAccountsMinimums).reduce((prev, cur) => prev + cur, 0) >= totalGuidelineForSpendings;

  const onlyShadowMinimumsAboveSuggested = Object.keys(shadowAccountsMinimums)
    .filter(isMinimumAboveSuggested)
    .map(category => shadowAccountsMinimums[category])
    .reduce((prev, cur) => prev + cur, 0);

  const shadowAccountsMinimumsTotal = isTotalGuidelineSpentOnMinimums ? totalGuidelineForSpendings : onlyShadowMinimumsAboveSuggested;

  const totalPercentageToDiscount = (
    ['giving', 'housing', 'transportation', 'food', 'health', 'clothing', 'entertainment'] as (keyof typeof fixedGuidelineBudgetPercentages)[]
  )
    .filter(isMinimumAboveSuggested)
    .map(category => fixedGuidelineBudgetPercentages[category])
    .reduce((prev, cur) => prev + cur, 0);

  const maxBetweenFixedGuidelineAndMinimums = (category: keyof typeof fixedGuidelineBudgetPercentages) =>
    Math.max(
      (fixedGuidelineBudgetPercentages[category] * (totalGuidelineForSpendings - shadowAccountsMinimumsTotal)) / (1 - totalPercentageToDiscount),
      shadowAccountsMinimums[category] ?? 0
    );

  return {
    giving: maxBetweenFixedGuidelineAndMinimums('giving'),
    housing: maxBetweenFixedGuidelineAndMinimums('housing'),
    transportation: maxBetweenFixedGuidelineAndMinimums('transportation'),
    food: maxBetweenFixedGuidelineAndMinimums('food'),
    health: maxBetweenFixedGuidelineAndMinimums('health'),
    clothing: maxBetweenFixedGuidelineAndMinimums('clothing'),
    entertainment: maxBetweenFixedGuidelineAndMinimums('entertainment'),
    otherBudget: 0
  };
};

/**
 * Lowest debt accounts should be paid off first
 */
export const sortDebtAccounts = (a: Account, b: Account): number =>
  (Math.abs(a.balance) - Math.abs(b.balance) || a.accountName.localeCompare(b.accountName)) > 0 ? 1 : -1;

/**
 * Accounts with smallest targetAmount should be applied first
 */
export const sortNonDebtAccounts = (a: Account, b: Account): number =>
  ((a.targetAmount ?? 0) - (b.targetAmount ?? 0) || a.accountName.localeCompare(b.accountName)) > 0 ? 1 : -1;

const createFallbackAccount = (type: AccountType, category: AccountCategory, targetAmount: number | undefined, interestRate: number): Account => ({
  accountLimit: 0,
  accountName: 'Fallback account',
  balance: 0,
  category,
  coverageAmount: 0,
  employerContribution: 0,
  id: fallbackAccountId,
  interestRate,
  monthlyAmount: 0,
  monthlyIncome: 0,
  monthlyMinimum: 0,
  totalOwed: 0,
  isActive: true,
  isBankAccount: true,
  type,
  targetAmount
});

export interface Projection {
  date: Date;
  netWorth: number;
  totalDebt: number;
}

export interface NetWorthAndTotalDebt {
  netWorth: number;
  totalDebt: number;
}
export const getFinancialFreedomDate = (projections: Projection[], ffGoal: number, birthDate: Date): Date =>
  projections.find(p => p.netWorth >= ffGoal)?.date ?? getLastDate(birthDate);
export const getDebtPayoffDate = (projections: Projection[], birthDate: Date): Date =>
  projections.find((p, idx) => idx > 0 && p.totalDebt <= 0)?.date ?? getLastDate(birthDate);
const getLastDate = (birthDate: Date) => moment(birthDate).add(maxYearsOfProjections, 'years').toDate();

/**
 * Gets the net worth, assuming the debt accounts do not have a negative balance
 */
export const getNetWorth = (accounts: Account[]): number =>
  getNetWorthAndTotalDebt(convertDebtAccountsBalance(accounts).filter(notPaidInFullAccount)).netWorth;

/**
 * Gets the total debt, assuming the debt accounts do not have a negative balance
 */
export const getDebt = (accounts: Account[]): number =>
  accounts
    .filter(isDebtAccount)
    .filter(notPaidInFullAccount)
    .reduce<number>((prev, cur) => prev + cur.balance, 0);

export const getProductiveAssetsTotal = (accounts: Account[]): number =>
  accounts.filter(a => a.productivity === 'Productive').reduce<number>((prev, cur) => prev + cur.balance, 0);

/**
 * Gets the guideline distribution for the current month for the given snapshot accounts.
 */
export const getGuidelineDistribution = (startingAccounts: Account[]): AccountApplication[] => {
  const ffdAfterSpendingsAnd4Step = getPlannedFfdAfterSpendingsAnd4Step(startingAccounts);

  let accounts: AccountWithExtraFields[] = startingAccounts
    .filter(relevantAccountForProjections)
    .filter(relevantInsuranceTypes)
    .filter(notPaidInFullAccount);
  accounts = createFallbackAccounts(accounts, 'guideline', 0, 0);
  accounts = convertDebtAccountsBalance(accounts);
  accounts = convertInterestRateForDepreciation(accounts);
  const guidelineValue = getGuidelineTo4StepPlan(accounts);
  const valuesBeforeApplication = accounts.map(a => ({ id: a.id, balance: a.balance, monthlyMinimum: a.monthlyMinimum }));
  accounts = addMonthlyAmounts(accounts, 'guideline');
  const limitedAccountsAndRolloverFfd = limitToTargetBalanceAndGetRolloverFfd(accounts, 'guideline', ffdAfterSpendingsAnd4Step);
  accounts = limitedAccountsAndRolloverFfd.accounts;
  const remainingFfd = calculateRemainingFfd(accounts, 'guideline', guidelineValue, limitedAccountsAndRolloverFfd.rolloverFfd);

  const accountsAfterApplication: AccountWithExtraFields[] = applyRemainingFfdTo4StepPlan(accounts, remainingFfd);

  const calculateApplication = (account: AccountWithExtraFields): number => {
    const beforeApplication = valuesBeforeApplication.find(a => a.id === account.id);
    const deductedMinimumFromShadowAccount = account.budgetSubcategory ? beforeApplication?.monthlyMinimum ?? 0 : 0;
    return Math.max(0, account.balance - (beforeApplication?.balance ?? 0) - deductedMinimumFromShadowAccount);
  };

  return accountsAfterApplication.map(account => ({ ...account, application: calculateApplication(account) }));
};

export const getFfdInfo = (accounts: Account[]): FfdInfo => {
  const totalIncome = getTotalIncome(accounts);
  const plannedSpendings = getPlannedSpendings(accounts);
  const planned4Step = getPlanned4Step(accounts);

  const plannedFfdAfterSpendings = totalIncome - plannedSpendings;
  const plannedFfdAfterSpendingsAnd4Step = getPlannedFfdAfterSpendingsAnd4Step(accounts);
  const guideline4StepAccounts = getGuidelineDistribution(accounts);
  const guideline4Step = guideline4StepAccounts.reduce((prev, cur) => prev + cur.application - cur.employerContribution, 0);
  const guideline4StepDistribution: { [account: string]: number } = guideline4StepAccounts.reduce(
    (prev, cur) => ({ ...prev, [cur.id ?? '']: cur.application - cur.employerContribution }),
    {}
  );

  let guidelineSpendings = Math.max(0, totalIncome - guideline4Step);
  const guidelineSpendingsCategoryDistribution = getGuidelineBudgetDistribution(accounts, guidelineSpendings);
  guidelineSpendings = Object.values(guidelineSpendingsCategoryDistribution).reduce((prev, cur) => prev + cur, 0);

  return {
    totalIncome: fixFloatingPointNumberPrecision(totalIncome),
    plannedSpendings: fixFloatingPointNumberPrecision(plannedSpendings),
    planned4Step: fixFloatingPointNumberPrecision(planned4Step),
    plannedFfdAfterSpendings: fixFloatingPointNumberPrecision(plannedFfdAfterSpendings),
    plannedFfdAfterSpendingsAnd4Step: fixFloatingPointNumberPrecision(plannedFfdAfterSpendingsAnd4Step),
    guideline4Step: fixFloatingPointNumberPrecision(guideline4Step),
    guideline4StepAccounts,
    guideline4StepDistribution,
    guidelineSpendings: fixFloatingPointNumberPrecision(guidelineSpendings),
    guidelineSpendingsCategoryDistribution
  };
};

const fixFloatingPointNumberPrecision = (num: number): number => Number(num.toFixed(2));

const getPlannedSpendings = (accounts: Account[]) =>
  accounts.filter(a => a.type === 'budget').reduce<number>((prev, cur) => prev + cur.monthlyAmount, 0) +
  accounts.filter(a => a.budgetSubcategory).reduce<number>((prev, cur) => prev + cur.monthlyMinimum, 0);

const getPlanned4Step = (accounts: Account[]) =>
  accounts
    .filter(a => (a.type === 'assets' || a.type === 'liabilities') && !a.budgetSubcategory)
    .reduce<number>((prev, cur) => prev + cur.monthlyAmount, 0) +
  accounts.filter(a => a.budgetSubcategory).reduce<number>((prev, cur) => prev + cur.monthlyAmount - cur.monthlyMinimum, 0);

const getPlannedFfdAfterSpendingsAnd4Step = (accounts: Account[]) =>
  getTotalIncome(accounts) - getPlannedSpendings(accounts) - getPlanned4Step(accounts);

export interface FfdInfo {
  totalIncome: number;
  plannedSpendings: number;
  planned4Step: number;
  /** Total income - total spendings */
  plannedFfdAfterSpendings: number;
  /** Created ffd - total applied in 4 step plan */
  plannedFfdAfterSpendingsAnd4Step: number;
  guideline4Step: number;
  guideline4StepAccounts: AccountApplication[];
  guideline4StepDistribution: { [account: string]: number };
  guidelineSpendings: number;
  guidelineSpendingsCategoryDistribution: { [account: string]: number };
}

type AccountApplication = Account & { application: number };

type AccountWithExtraFields = Account & { accumulatedInterest?: number };
