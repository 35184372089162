export const environment = {
  production: true,
  mode: 'beta',
  apiUrl: 'https://my.wizefi.com/api',
  intercomId: 'elib46tc',
  googleAnalytics: 'G-SHDFV5ST89',
  gaScript: 'https://www.googletagmanager.com/gtag/js?id=G-SHDFV5ST89',
  googleClarifyTag: 'ec87324f-d5ed-4a81-a723-dd5179c531eb',
  ga: {
    appName: 'WizeFi',
    version: 1
  }
};
