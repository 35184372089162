"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.braintreeWebhookEntryWithoutIdSchema = exports.braintreeWebhookEntrySchema = void 0;
const zod_1 = require("zod");
exports.braintreeWebhookEntrySchema = zod_1.z.object({
    id: zod_1.z.string(),
    transactionId: zod_1.z.string(),
    disputeId: zod_1.z.optional(zod_1.z.string()),
    kind: zod_1.z.string(),
    amount: zod_1.z.number(),
    wizefiId: zod_1.z.string(),
    timestamp: zod_1.z.string(),
});
exports.braintreeWebhookEntryWithoutIdSchema = exports.braintreeWebhookEntrySchema.omit({ id: true });
