/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { IntercomService } from '@app/services/intercom.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { AppSelectors } from './app.selectors';
import { LoginActions } from './login/login.actions';
import { logoutRequested } from './screen.actions';

@Injectable()
export class IntercomEffects {
  intercomLoginSuccessfulEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.loginSuccessful),
        tap(() => this.intercomService.reset())
      );
    },
    { dispatch: false }
  );

  intercomLogoutEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested),
        tap(() => this.intercomService.reset())
      );
    },
    { dispatch: false }
  );

  intercomChangeEffect$ = this.store
    .select(AppSelectors.allDataForIntercom)
    .pipe(
      debounceTime(5000),
      filter(({ coach }) => !coach.loggedAsClient),
      filter(
        ({ userData, login, subscription }) =>
          userData.status === 'success' && login.loginStatus === 'success' && subscription.loadingStatus === 'success'
      )
    )
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .subscribe(({ userData, login, subscription }) => this.intercomService.updateData(login.id!, userData.userData, subscription));

  constructor(private actions$: Actions, private store: Store, private intercomService: IntercomService) {}
}
