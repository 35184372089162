import { Subcategory } from '@app/interfaces/category';
import { Account, AccountCategory, AccountType, Institution, Transaction } from '@wizefi/entities';
import {
  FfdInfo,
  calculateProjections,
  getFfdInfo,
  getDebt,
  getFinancialFreedomDate,
  getNetWorth,
  getProductiveAssetsTotal,
  maxYearsOfProjections,
  ProjectionType,
  getDebtPayoffDate,
  ProjectionCalculationOptions
} from '@app/services/projection.calculation';
import { createSelector, select } from '@ngrx/store';
import * as moment from 'moment';
import { pipe } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { AppState } from '../app.state';
import { UserDataSelectors } from '../user-data/user-data.selectors';
import { selectInstitutions } from '../institution/institution.root.selectors';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';

export const isAccountInStep = (account: Account, step: number) => {
  switch (step) {
    case 1:
      return account.category === 'emergencySavings';
    case 2:
      return account.type === 'liabilities' && !account.paidInFullEachMonth && account.category !== 'mortgageLimited';
    case 3:
      return account.category === 'cashReserves';
    case 4:
      return account.category === 'investments' || account.category === 'mortgageLimited';
    default:
      throw new Error('Invalid step');
  }
};

export class AccountSelectors {
  static activeAccountsFilter = (accounts: Account[]) => accounts.filter(a => a.isActive && !!a.category && !a.needsActivation);

  static selectState = (state: AppState) => state.accounts;
  static accounts = createSelector(this.selectState, state => state.accounts);
  static activeAccounts = createSelector(this.accounts, this.activeAccountsFilter);
  static originalAccounts = createSelector(this.selectState, state => state.originalAccounts);
  static activeOriginalAccounts = createSelector(this.originalAccounts, this.activeAccountsFilter);

  static selectIncomeAccounts = createSelector(this.accounts, accounts => accounts.filter(a => a.monthlyIncome > 0 || a.type === 'income'));

  static bankAccounts = createSelector(this.accounts, accounts => accounts.filter(a => a.isBankAccount));
  static selectedBankAccountsCount = createSelector(this.bankAccounts, accounts => accounts.filter(a => a.isActive && !a.needsActivation).length);
  static selectedBankAccountsWithErrorCount = createSelector(
    this.bankAccounts,
    selectInstitutions,
    (accounts, institutions) =>
      accounts.filter(a => a.isActive && !a.needsActivation && (institutions.find(i => i.itemId === a.itemId)?.error || !a.category)).length
  );
  static selectedBankAccountsWithoutErrorCount = createSelector(
    this.selectedBankAccountsCount,
    this.selectedBankAccountsWithErrorCount,
    (total, error) => total - error
  );
  static hasAnyBankAccount = createSelector(this.bankAccounts, accounts => accounts.length > 0);
  static activeBankAccounts = createSelector(this.bankAccounts, accounts => accounts.filter(a => a.isActive && !!a.category && !a.needsActivation));
  static selectAccountsInInstitution = (institution: Institution) =>
    createSelector(this.accounts, accounts => accounts.filter(a => a.itemId === institution.itemId));
  static selectActiveAccountsInInstitution = (institution: Institution) =>
    createSelector(this.selectAccountsInInstitution(institution), accounts =>
      accounts.filter(a => a.isActive && !a.needsActivation && a.shouldSyncTransactions)
    );

  static selectAccountsByStep = createSelector(this.activeAccounts, accounts =>
    [1, 2, 3, 4]
      .map(step => ({ step, accounts: accounts.filter(a => isAccountInStep(a, step)) }))
      .reduce((prev, cur) => ({ ...prev, [cur.step]: cur.accounts }), {} as { [step: number]: Account[] })
  );

  static selectAccountsInStep = (step: number) => createSelector(this.selectAccountsByStep, accountsByStep => accountsByStep[step]);

  static selectAllSubcategories = (type: AccountType | undefined, category: AccountCategory | undefined) =>
    createSelector(this.activeAccounts, accounts => {
      const mapToSubcategory = (a: Account, isShadowAccount: boolean): Subcategory => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        type: a.type!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        category: a.category!,
        label: a?.accountName ?? '',
        key: a.id,
        hasShadowAccount: !!a.budgetSubcategory && a.type !== 'assetProtection',
        budgetShadowCategory: isShadowAccount ? a.budgetSubcategory : undefined
      });

      const subcategories = accounts.filter(a => type && category && a.type === type && a.category === category).map(a => mapToSubcategory(a, false));

      const shadowAccounts = type !== 'budget' ? [] : accounts.filter(a => a.budgetSubcategory === category).map(a => mapToSubcategory(a, true));

      return [...subcategories, ...shadowAccounts];
    });

  static selectAccountWithId = (id: string | undefined) => createSelector(this.accounts, accounts => accounts.find(a => a.id === id));
  static selectAccountAssignedInTransaction = (transaction: Transaction) => this.selectAccountWithId(transaction.id);

  static selectAccountName = (id: string) => createSelector(this.selectAccountWithId(id), account => account?.accountName ?? '');
  static accountNameForTransaction = (transaction: Transaction) =>
    createSelector(this.bankAccounts, accounts => accounts.find(a => a.id === transaction.sourceAccountId)?.accountName ?? '');

  static selectShadowAccountsOfCategory = (category: AccountCategory) =>
    createSelector(this.activeBankAccounts, accounts => accounts.filter(a => a.budgetSubcategory === category));

  static totalPlannedByCategory = createSelector(this.accounts, accounts =>
    accounts
      .filter(a => !!a.category)
      .filter(a => a.isActive && !a.needsActivation)
      .reduce((prev, cur) => {
        const category = (cur.budgetSubcategory ?? cur.category) as string;
        const prevValue = prev[category] ?? 0;
        return { ...prev, [category]: prevValue + (cur.budgetSubcategory ? cur.monthlyMinimum : cur.monthlyAmount) };
      }, {} as { [category: string]: number })
  );

  static accountsInCategory = (category: AccountCategory) =>
    createSelector(this.activeAccounts, accounts => accounts.filter(a => a.category === category));

  static duplicateAccountNameValidator = (type: AccountType, category: AccountCategory, name: string, excludingId?: string) =>
    createSelector(this.accounts, accounts =>
      accounts
        .filter(a => a.type === type)
        .filter(a => a.category === category)
        .some(a => a.accountName === name && (!excludingId || excludingId !== a.id))
        ? { duplicate: true }
        : null
    );

  static duplicateBudgetAccountNameValidator = (category: AccountCategory, name: string, excludingValue?: string) =>
    this.duplicateAccountNameValidator('budget', category, name, excludingValue);

  static accountsAndShadowAccountsInCategory = (category: AccountCategory) =>
    createSelector(this.selectShadowAccountsOfCategory(category), this.accountsInCategory(category), (shadow, accounts) => [...accounts, ...shadow]);
}

export class ProjectionSelectors {
  static selectProjectionsWithoutDebounce = (projectionType: ProjectionType, useCurrentLoadedAccounts?: boolean) =>
    createSelector(
      AccountSelectors.activeOriginalAccounts,
      AccountSelectors.activeAccounts,
      UserDataSelectors.selectUserData,
      UserDataSelectors.selectFinancialFreedomNumber,
      selectSelectedMonth,
      (originalAccounts, currentAccounts, userData, ffGoal, selectedYearMonth) => {
        const originalStartingDate = moment(userData.originalPlanYearMonth + '-02').startOf('month');
        const nonOriginalStartingDate = moment(selectedYearMonth + '-20').startOf('month');
        const startingDate = projectionType === 'original' ? originalStartingDate : nonOriginalStartingDate;
        const endingDate = moment(userData.birthDate ? new Date(userData.birthDate) : new Date()).add(maxYearsOfProjections, 'years');

        const params: ProjectionCalculationOptions = {
          accounts: projectionType === 'original' && !useCurrentLoadedAccounts ? originalAccounts : currentAccounts,
          startingDate,
          endingDate,
          ffGoal,
          projectionType,
          averageInvestmentInterestRate: userData.investmentReturnRate,
          assumedLoanInterestRate: userData.assumedLoanInterestRate ?? 12
        };

        return calculateProjections(params);
      }
    );

  static selectProjections = (projectionType: ProjectionType, useCurrentLoadedAccounts?: boolean) =>
    pipe(select(this.selectProjectionsWithoutDebounce(projectionType, useCurrentLoadedAccounts)), debounceTime(100));

  static originalProjections = (useCurrentLoadedAccounts?: boolean) => this.selectProjections('original', useCurrentLoadedAccounts);
  static currentProjections = this.selectProjections('current', undefined);
  static guidelineProjections = this.selectProjections('guideline', undefined);

  static netWorth = pipe(
    select(AccountSelectors.activeAccounts),
    map(accounts => getNetWorth(accounts))
  );
  static originalNetWorth = pipe(
    select(AccountSelectors.activeOriginalAccounts),
    map(accounts => getNetWorth(accounts))
  );

  static productiveAssetsTotal = pipe(
    select(AccountSelectors.activeAccounts),
    map(accounts => getProductiveAssetsTotal(accounts))
  );

  static debt = pipe(
    select(AccountSelectors.activeAccounts),
    map(accounts => getDebt(accounts))
  );

  static originalDebt = pipe(
    select(AccountSelectors.activeOriginalAccounts),
    map(accounts => getDebt(accounts))
  );

  static netWorthDifferenceFromOriginal = createSelector(
    AccountSelectors.activeAccounts,
    AccountSelectors.activeOriginalAccounts,
    (currAccounts, origAccounts) => getNetWorth(currAccounts) - getNetWorth(origAccounts)
  );

  static ffDate = (projectionType: ProjectionType) =>
    createSelector(
      this.selectProjectionsWithoutDebounce(projectionType),
      UserDataSelectors.selectUserData,
      UserDataSelectors.selectFinancialFreedomNumber,
      (projection, userData, ffGoal) => getFinancialFreedomDate(projection, ffGoal, new Date(userData.birthDate))
    );

  static ffAge = (projectionType: ProjectionType) =>
    createSelector(this.ffDate(projectionType), UserDataSelectors.selectUserDataProp('birthDate'), (ffDate, birthDate) =>
      moment(ffDate).diff(new Date(birthDate), 'years')
    );

  static debtPayoffDate = (projectionType: ProjectionType) =>
    createSelector(this.selectProjectionsWithoutDebounce(projectionType), UserDataSelectors.selectUserData, (projection, userData) =>
      getDebtPayoffDate(projection, new Date(userData.birthDate))
    );

  static isDebtFree = (projectionType: ProjectionType) =>
    createSelector(this.selectProjectionsWithoutDebounce(projectionType), projection => projection.length > 0 && projection[0].totalDebt === 0);

  static isFinanciallyFree = (projectionType: ProjectionType) =>
    createSelector(
      this.selectProjectionsWithoutDebounce(projectionType),
      UserDataSelectors.selectFinancialFreedomNumber,
      (projection, ffNumber) => projection.length > 0 && projection[0].netWorth >= ffNumber
    );

  static debtFreedomAge = (projectionType: ProjectionType) =>
    createSelector(this.debtPayoffDate(projectionType), UserDataSelectors.selectUserDataProp('birthDate'), (ffDate, birthDate) =>
      moment(ffDate).diff(new Date(birthDate), 'years')
    );

  static ffYearsDifferenceFromOriginal = createSelector(this.ffDate('current'), this.ffDate('original'), (currFfDate, origFfDate) =>
    moment(currFfDate).diff(origFfDate, 'years')
  );

  static debtYearsDifferenceFromOriginal = createSelector(
    this.debtPayoffDate('current'),
    this.debtPayoffDate('original'),
    (currDebtDate, origDebtDate) => moment(currDebtDate).diff(origDebtDate, 'years')
  );

  static ffdInfo = createSelector(AccountSelectors.activeAccounts, accounts => getFfdInfo(accounts));
  static ffdInfoProp = <T extends keyof FfdInfo>(prop: T) => createSelector(this.ffdInfo, ffdIndo => ffdIndo[prop]);

  static guidelineInCategory = (category: AccountCategory) =>
    createSelector(this.ffdInfoProp('guidelineSpendingsCategoryDistribution'), guidelineDistribution => guidelineDistribution[category]);

  private static guidelineAccountsInStep = (step: number) =>
    createSelector(this.ffdInfo, ffd => ffd.guideline4StepAccounts.filter(a => isAccountInStep(a, step)));

  static totalGuidelineInStep = (step: number) =>
    createSelector(this.guidelineAccountsInStep(step), accounts =>
      accounts.reduce((prev, cur) => prev + cur.application - cur.employerContribution, 0)
    );

  static guidelineInAccount = (account: Account) => createSelector(this.ffdInfo, ffdInfo => ffdInfo.guideline4StepDistribution[account.id] ?? 0);
}
