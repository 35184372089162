import { Injectable } from '@angular/core';
import { ProjectionSelectors } from '@app/state/account/account.selectors';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Projection } from './projection.calculation';

@Injectable({
  providedIn: 'root'
})
export class CachedProjectionsService {
  private cachedProjections: Projection[] | undefined;
  private cachedProjections$: ReplaySubject<Projection[]> = new ReplaySubject(1);

  constructor(private store: Store) {}

  getCachedProjectionsObservable(): Observable<Projection[]> {
    this.getCachedProjections();
    return this.cachedProjections$.asObservable();
  }

  async getCachedProjections(): Promise<Projection[]> {
    if (!this.cachedProjections) {
      await this.recalculateCache();
    }
    return this.cachedProjections as Projection[];
  }

  clearCachedEntry() {
    this.cachedProjections = undefined;
  }

  async recalculateCache() {
    this.cachedProjections = await this.store.select(ProjectionSelectors.selectProjectionsWithoutDebounce('current')).pipe(take(1)).toPromise();
    this.cachedProjections$.next(this.cachedProjections);
  }
}
