"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payoutRequestWithoutIdSchema = exports.payoutRequestSchema = exports.payoutRequestStatusSchema = void 0;
const zod_1 = require("zod");
exports.payoutRequestStatusSchema = zod_1.z.enum([
    "open",
    "accepted",
    "rejected",
]);
exports.payoutRequestSchema = zod_1.z.object({
    id: zod_1.z.string(),
    wizefiId: zod_1.z.string(),
    amountRequested: zod_1.z.number(),
    /** Account id of the bank where the payout was paid to. In case of paypal, this is the email */
    payoutAccount: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    updatedBy: zod_1.z.string(),
    rejectionReason: zod_1.z.optional(zod_1.z.string()),
    status: exports.payoutRequestStatusSchema,
});
exports.payoutRequestWithoutIdSchema = exports.payoutRequestSchema.omit({
    id: true,
});
