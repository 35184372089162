"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionDataTableName = exports.subscriptionDataDbSchema = void 0;
const zod_1 = require("zod");
const braintree_subscription_status_1 = require("./braintree-subscription-status");
const braintreeDataSchema = zod_1.z.object({
    wizefiId: zod_1.z.string(),
    haveBraintreeAccount: zod_1.z.boolean(),
    nextBillingDate: zod_1.z.optional(zod_1.z.string()),
    cardType: zod_1.z.optional(zod_1.z.string()),
    last4: zod_1.z.optional(zod_1.z.string()),
    subscriptionId: zod_1.z.optional(zod_1.z.string()),
    status: zod_1.z.optional(braintree_subscription_status_1.braintreeSubscriptionStatusSchema),
    paymentMethodToken: zod_1.z.optional(zod_1.z.string()),
    firstBillingDate: zod_1.z.optional(zod_1.z.string()),
    paidThroughDate: zod_1.z.optional(zod_1.z.string()),
    price: zod_1.z.number(),
    isInTrialPeriod: zod_1.z.boolean(),
    /** Whether the user's subscription is still active. i.e, user has canceled their subscription but paid through this month, so isActive is still true, even though status is going to be 'Canceled' */
    isActive: zod_1.z.boolean(),
    fromInAppPurchase: zod_1.z.optional(zod_1.z.boolean()), /** in-app purchase (mobileApp) or via Braintree */
    isEnterpriseMember: zod_1.z.optional(zod_1.z.boolean())
});
exports.subscriptionDataDbSchema = braintreeDataSchema.omit({ haveBraintreeAccount: true, price: true, isActive: true });
exports.subscriptionDataTableName = 'SubscriptionData';
