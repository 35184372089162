<form class="max-w-md" (submit)="onSubmit()" [formGroup]="formGroup">
  <div matDialogTitle>
    <div class="flex justify-between">
      <h2 class="text-2xl font-bold">{{ account ? 'Edit' : 'Create' }} account</h2>
      <button
        mat-icon-button
        [color]="'warn'"
        [matTooltip]="'Delete account'"
        *ngIf="account"
        (click)="confirmAccountDeletion(account)"
        type="button"
        data-test="account-dialog-delete-button"
      >
        <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <p class="mb-6 text-middleGray">
      Please add your account details as precisely as possible, as this information will be the baseline to calculate your financial projections.
    </p>

    <div class="mb-6 flex gap-2">
      <app-pill
        [color]="this.formGroup.controls.enableTransactions.value === true ? 'primary' : undefined"
        (pillClick)="this.formGroup.controls.enableTransactions.setValue(!this.formGroup.controls.enableTransactions.value)"
        [matTooltip]="
          'Toggle off the transactions for this account if you want to disregard all of them from your budget but still want to consider this account in your projections.'
        "
        ><span class="flex items-center gap-2">
          <span>Transactions {{ this.formGroup.controls.enableTransactions.value === true ? 'enabled' : 'disabled' }}</span>
          <mat-icon class="!h-4 !w-4 !text-base !leading-none">help_outline</mat-icon>
        </span></app-pill
      >
      <app-pill
        [color]="'primary'"
        [matTooltip]="'Deactivate this account if you don\'t want to consider it in your budget and projections.'"
        [color]="this.formGroup.controls.active.value === true ? 'primary' : undefined"
        (pillClick)="this.formGroup.controls.active.setValue(!this.formGroup.controls.active.value)"
        ><span class="flex items-center gap-2">
          <span>Account {{ this.formGroup.controls.active.value === true ? 'active' : 'inactive' }}</span>
          <mat-icon class="!h-4 !w-4 !text-base !leading-none">help_outline</mat-icon>
        </span></app-pill
      >
    </div>

    <div class="mb-4">
      <p class="mb-2 font-bold">General information</p>
      <mat-form-field appearance="outline" class="block w-full">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="formGroup.controls.name" cdkFocusInitial data-test="account-dialog-name-input" />
        <mat-error *ngIf="formGroup.controls.name.errors?.required">Please enter an account name</mat-error>
      </mat-form-field>
      <div class="mb-4 flex flex-wrap items-center gap-2">
        <p class="text-middleGray">Type:</p>
        <app-pill
          [color]="formGroup.controls.type.value === 'assets' ? 'green' : undefined"
          (pillClick)="selectType('assets')"
          data-test="account-dialog-asset-button"
        >
          <span class="flex items-center gap-2">
            <mat-icon class="!h-5 !w-5 !text-xl !leading-none">savings</mat-icon>
            <span>Asset</span>
          </span>
        </app-pill>
        <app-pill
          [color]="formGroup.controls.type.value === 'liabilities' ? 'warn' : undefined"
          (pillClick)="selectType('liabilities')"
          data-test="account-dialog-debt-button"
        >
          <span class="flex items-center gap-2">
            <mat-icon class="!h-5 !w-5 !text-xl !leading-none">trending_down</mat-icon>
            <span>Debt</span>
          </span>
        </app-pill>
        <app-pill
          [color]="formGroup.controls.type.value === 'assetProtection' ? 'primary' : undefined"
          (pillClick)="selectType('assetProtection')"
          data-test="account-dialog-insurance-button"
        >
          <span class="flex items-center gap-2">
            <mat-icon class="!h-5 !w-5 !text-xl !leading-none">health_and_safety</mat-icon>
            <span>Insurance</span>
          </span>
        </app-pill>

        <mat-error *ngIf="formGroup.controls.type.errors?.required && formGroup.controls.type.touched" class="text-xs"
          >Please select an account type</mat-error
        >
      </div>

      <div class="flex gap-4">
        <mat-form-field appearance="outline" class="block w-full">
          <mat-label>Subtype</mat-label>
          <input matInput #subtypeEl [formControl]="formGroup.controls.subtype" [matAutocomplete]="auto" data-test="account-dialog-subtype-input" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredSubtypes$ | async" [value]="option">
              {{ option.subtype }}
            </mat-option>
            <mat-option *ngIf="!formGroup.controls.type.value" disabled>Please select a type first.</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="formGroup.controls.subtype.errors?.required || formGroup.controls.subtype.errors?.subtype"
            >Please select a subtype</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="mb-4" *ngIf="formGroup.controls.type.value !== 'assetProtection'">
      <p class="mb-2 font-bold">Account details</p>
      <div class="mb-4 flex gap-2">
        <mat-form-field appearance="outline" class="w-2/3">
          <mat-label>{{
            getSingleSubtype(formGroup.controls.subtype.value, formGroup.controls.type.value)?.category === 'personalProperty' ? 'Value' : 'Balance'
          }}</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input matInput appCurrencyInput class="!text-right" [formControl]="formGroup.controls.balance" data-test="account-dialog-balance-input" />
          <mat-hint *ngIf="formGroup.controls.balance.disabled">Balance updated automatically</mat-hint>
          <mat-error *ngIf="formGroup.controls.balance.errors?.required">Please enter the account balance</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-1/3">
          <mat-label>{{
            getSingleSubtype(formGroup.controls.subtype.value, formGroup.controls.type.value)?.category === 'personalProperty'
              ? 'Depreciation rate'
              : 'Interest rate'
          }}</mat-label>
          <input
            matInput
            appCurrencyInput
            class="!text-right"
            [formControl]="formGroup.controls.interestRate"
            data-test="account-dialog-interest-rate-input"
          />
          <mat-hint>Annual rate</mat-hint>
          <span matSuffix>%</span>
          <mat-error *ngIf="formGroup.controls.interestRate.errors?.required">Please enter the interest rate</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" class="w-full" *ngIf="isCategory('emergencySavings') || isCategory('cashReserves')">
        <mat-label>Target balance</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input
          matInput
          appCurrencyInput
          [formControl]="formGroup.controls.targetBalance"
          class="!text-right"
          data-test="account-dialog-target-balance-input"
        />
        <span matSuffix [matTooltip]="targetBalanceTooltipText()"><mat-icon class="!ml-2 text-middleGray">help_outline</mat-icon></span>
        <mat-error *ngIf="formGroup.controls.targetBalance.errors?.required">Please enter the target balance</mat-error>
      </mat-form-field>
    </div>

    <div class="mb-4" *ngIf="formGroup.controls.type.value === 'assetProtection'">
      <p class="mb-2 font-bold">Account details</p>
      <div class="flex gap-2">
        <mat-form-field appearance="outline" class="w-full" [ngClass]="{ '!w-1/2': isCategory('termInsurance') || isCategory('otherInsurance') }">
          <mat-label>Coverage</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input
            matInput
            appCurrencyInput
            [formControl]="formGroup.controls.coverage"
            class="!text-right"
            data-test="account-dialog-coverage-input"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-1/2" *ngIf="isCategory('termInsurance') || isCategory('otherInsurance')">
          <mat-label>Expiration date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [formControl]="formGroup.controls.expirationDate"
            data-test="account-dialog-expiration-date-input"
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="formGroup.controls.expirationDate.errors?.valid">Please enter a valid date</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="mb-4" *ngIf="isCategory('emergencySavings') || isCategory('cashReserves') || isCategory('investments')">
      <p class="mb-2 font-bold">Monthly contributions</p>
      <div class="flex gap-2">
        <mat-form-field appearance="outline" class="w-1/2">
          <mat-label>Self</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input
            matInput
            appCurrencyInput
            class="!text-right"
            [formControl]="formGroup.controls.selfContribution"
            data-test="account-dialog-self-input"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-1/2">
          <mat-label>Outside</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input
            matInput
            appCurrencyInput
            class="!text-right"
            [formControl]="formGroup.controls.outsideContribution"
            data-test="account-dialog-outside-input"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="mb-4" *ngIf="isCategory('investmentProperty')">
      <p class="mb-2 font-bold">Monthly income</p>
      <mat-form-field appearance="outline">
        <mat-label>Net income</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput appCurrencyInput class="!text-right" [formControl]="formGroup.controls.income" data-test="account-dialog-net-income-input" />
      </mat-form-field>
    </div>

    <div class="mb-4" *ngIf="formGroup.controls.type.value === 'assetProtection'">
      <p class="mb-2 font-bold">Monthly contributions</p>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Premium</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput appCurrencyInput class="!text-right" [formControl]="formGroup.controls.premium" data-test="account-dialog-premium-input" />
        <mat-error *ngIf="formGroup.controls.premium.errors?.required">Please enter the premium amount</mat-error>
      </mat-form-field>
    </div>

    <div class="mb-4" *ngIf="formGroup.controls.type.value === 'liabilities'">
      <p class="mb-2 font-bold">Monthly payments</p>
      <mat-checkbox
        class="my-4"
        *ngIf="isCategory('creditCard')"
        [formControl]="formGroup.controls.paidInFull"
        [color]="'primary'"
        data-test="account-dialog-paid-in-full-checkbox"
        >Card paid in full each month</mat-checkbox
      >

      <div class="flex gap-2" *ngIf="(!formGroup.controls.paidInFull.value && isCategory('creditCard')) || !isCategory('creditCard')">
        <mat-form-field appearance="outline" class="w-1/2">
          <mat-label>Minimum</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input
            matInput
            appCurrencyInput
            class="!text-right"
            [formControl]="formGroup.controls.minimumPayment"
            data-test="account-dialog-minimum-input"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-1/2">
          <mat-label>Extra</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input
            matInput
            appCurrencyInput
            class="!text-right"
            [formControl]="formGroup.controls.extraPayment"
            data-test="account-dialog-extra-input"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-flat-button mat-dialog-close type="button" data-test="account-dialog-cancel-button">Cancel</button>
    <button mat-flat-button [color]="'primary'" type="submit" data-test="account-dialog-save-button">Save</button>
  </div>
</form>
