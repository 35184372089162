import { Account, Transaction } from '@wizefi/entities';
import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { AppState } from '../app.state';
import { AccountSelectors } from '../account/account.selectors';

export const selectTransactionsState = (state: AppState) => state.transactions;

const filterTransactionsInAccountsWithEnabledSync = (transactions: Transaction[], accounts: Account[]) =>
  transactions.filter(t => {
    const account = accounts.find(a => a.id === t.sourceAccountId);
    return !t.sourceAccountId || account?.shouldSyncTransactions;
  });

const selectTransactionsInAccountsWithEnabledSync = createSelector(selectTransactionsState, AccountSelectors.accounts, (state, accounts) =>
  filterTransactionsInAccountsWithEnabledSync(state.transactions, accounts)
);

export const selectTransactions = createSelector(selectTransactionsInAccountsWithEnabledSync, transactions =>
  [...transactions].sort((a, b) => {
    if (a.date === b.date) {
      // Sort by name if dates are equal
      return a.name.localeCompare(b.name);
    }
    // Sort by date in descending order
    return b.date.localeCompare(a.date);
  })
);
export const selectTransactionStatus = createSelector(selectTransactionsState, state => state.status);
export const selectTransactionsAfterOperation = pipe(
  select(selectTransactionStatus),
  pairwise(),
  filter(([status1, status2]) => status1 === 'loading' && status2 === 'success'),
  map(() => select(selectTransactions))
);
export const selectActiveTransactions = createSelector(selectTransactions, transactions => transactions.filter(t => !t.isDeleted));
export const selectChildrenTransactions = createSelector(selectActiveTransactions, transactions => transactions.filter(t => !t.hasChildren));

export const selectUncategorizedTransactions = createSelector(selectChildrenTransactions, transactions => transactions.filter(t => !t.account));
export const selectUncategorizedTransactionsLength = createSelector(selectUncategorizedTransactions, transactions => transactions.length);

export const selectCategorizedTransactions = createSelector(selectChildrenTransactions, transactions => transactions.filter(t => t.account));
export const selectCategorizedTransactionsLength = createSelector(selectCategorizedTransactions, transactions => transactions.length);

export const selectExcludedTransactions = createSelector(selectTransactions, transactions => transactions.filter(t => t.isDeleted && !t.hasChildren));
export const selectExcludedTransactionsLength = createSelector(selectExcludedTransactions, transactions => transactions.length);

export const selectLastMonthTransactions = createSelector(selectTransactionsState, AccountSelectors.accounts, (state, accounts) =>
  filterTransactionsInAccountsWithEnabledSync(state.lastMonthTransactions, accounts)
);

export const selectLastMonthActiveTransactions = createSelector(selectLastMonthTransactions, transactions => transactions.filter(t => !t.isDeleted));
export const selectLastMonthChildrenTransactions = createSelector(selectLastMonthActiveTransactions, transactions =>
  transactions.filter(t => !t.hasChildren)
);

export const selectParentAndChildrenTransactions = (transaction: Transaction) =>
  pipe(
    select(selectTransactions),
    map(transactions => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parent: transactions.find(t => (t.id === transaction.id && !transaction.parentId) || t.id === transaction.parentId)!,
      children: transactions.filter(
        t => (t.parentId === transaction.id && !transaction.parentId) || (t.parentId === transaction.parentId && transaction.parentId)
      )
    }))
  );

export const selectOriginalTransaction = (transaction: Transaction) =>
  pipe(
    select(selectTransactions),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    map(transactions => transactions.find(t => (transaction.id === t.id && !transaction.parentId) || transaction.parentId === t.id)!)
  );

export const selectTransactionsTotalByAccount = createSelector(selectChildrenTransactions, transactions =>
  getTransactionsTotalBySubcategory(transactions)
);

export const selectLastMonthTransactionsTotalByAccount = createSelector(selectLastMonthChildrenTransactions, transactions =>
  getTransactionsTotalBySubcategory(transactions)
);

export const selectTransactionsTotalIncome = createSelector(
  selectChildrenTransactions,
  AccountSelectors.selectIncomeAccounts,
  (transactions, income) => totalTransactionsInAccounts(transactions, income)
);

export const selectLastMonthIncome = createSelector(
  selectLastMonthChildrenTransactions,
  AccountSelectors.selectIncomeAccounts,
  (transactions, accounts) => totalTransactionsInAccounts(transactions, accounts)
);

export const selectLastMonthTotalBySubcategory = createSelector(selectLastMonthTransactions, transactions =>
  getTransactionsTotalBySubcategory(transactions)
);

export const selectBudgetTransactions = createSelector(selectCategorizedTransactions, transactions =>
  transactions.filter(t => t.type === 'budget' || !!t.budgetShadowCategory)
);

const getTransactionsTotalBySubcategory = (transactions: Transaction[]) =>
  transactions.reduce(
    (prev, cur) => {
      const normalizedAmount = cur.amount * (cur.budgetShadowCategory ? -1 : 1);
      const id = cur.account ?? '';
      return { ...prev, [id]: (prev[id] ?? 0) + normalizedAmount };
    },
    {} as { [key: string]: number }
  );

const totalTransactionsInAccounts = (transactions: Transaction[], accounts: Account[]) => {
  const accountIds = new Set(accounts.map(i => i.id));
  return transactions.filter(t => t.account && accountIds.has(t.account)).reduce((prev, cur) => prev + cur.amount, 0);
};
