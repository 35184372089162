import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { logoutRequested } from '@app/state/screen.actions';
import { Router } from '@angular/router';
import { ERROR_PATH } from '@app/modules/route-paths';
import { MatDialog } from '@angular/material/dialog';

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[] | undefined;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  keepLoggedInAfterUnauthorizedResponse?: boolean;
  redirectToErrorPage?: (err: HttpErrorResponse) => boolean;
}

export interface HttpOptionsWithDefinedParams extends HttpOptions {
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  public constructor(private store: Store, private httpClient: HttpClient, private router: Router, private dialog: MatDialog) {}

  public get<T = any>(path: string, options?: HttpOptions): Observable<T> {
    // Comment out code below to simulate error on api level
    // if (path === 'transaction-rule') {
    //   return throwError(new HttpErrorResponse({ status: 404, error: { message: 'Test error' } })).pipe(this.apiErrorHandle(options));
    // }
    return this.httpClient.get<T>(this.getUrl(path), this.formatQueryParams(options)).pipe(this.apiErrorHandle(options)) as Observable<T>;
  }
  public post<T = any>(path: string, body?: any, options?: HttpOptions): Observable<T> {
    // Comment out code below to simulate error on api level
    // if (path === 'plaid/linkPlaidAccount') {
    //   return throwError(new HttpErrorResponse({ status: 400, error: { message: 'Test error' } })).pipe(this.apiErrorHandle(options));
    // }
    return this.httpClient.post<T>(this.getUrl(path), body, this.formatQueryParams(options)).pipe(this.apiErrorHandle(options)) as Observable<T>;
  }
  public put<T = any>(path: string, body?: any, options?: HttpOptions): Observable<T> {
    // Comment out code below to simulate error on api level
    // if (path === 'transaction-rule') {
    //   return throwError(new HttpErrorResponse({ status: 400, error: { message: 'Test error' } })).pipe(this.apiErrorHandle(options));
    // }
    return this.httpClient.put<T>(this.getUrl(path), body, this.formatQueryParams(options)).pipe(this.apiErrorHandle(options)) as Observable<T>;
  }
  public delete<T = any>(path: string, body?: any, options?: HttpOptions): Observable<T> {
    // Comment out code below to simulate error on api level
    // if (path === 'transaction-rule') {
    //   return throwError(new HttpErrorResponse({ status: 400, error: { message: 'Test error' } })).pipe(this.apiErrorHandle(options));
    // }
    const optionsToSend = options ? { ...this.formatQueryParams(options), body } : { body };
    return this.httpClient.delete<T>(this.getUrl(path), optionsToSend).pipe(this.apiErrorHandle(options)) as Observable<T>;
  }

  private getUrl(path: string): string {
    return environment.apiUrl + '/' + path;
  }

  private apiErrorHandle<T>(options?: HttpOptions): MonoTypeOperatorFunction<T> {
    return input =>
      input.pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          if (error.status === 401 && !options?.keepLoggedInAfterUnauthorizedResponse) {
            this.store.dispatch(logoutRequested());
            return of<T>();
          }
          if (options?.redirectToErrorPage === undefined || options.redirectToErrorPage(error) === true) {
            this.router.navigate([ERROR_PATH], { skipLocationChange: true, state: { error, previousUrl: this.router.url } });
            this.dialog.closeAll();
          }
          return throwError(error);
        })
      );
  }

  private formatQueryParams(options?: HttpOptions): HttpOptionsWithDefinedParams | undefined {
    if (!options) {
      return undefined;
    }
    if (!options.params) {
      return options as HttpOptionsWithDefinedParams;
    }
    const params = options.params as { [param: string]: string | string[] | undefined };
    const newParams = Object.keys(params)
      .filter(key => params?.[key] !== undefined && params?.[key] !== null)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .reduce((prev, cur) => ({ ...prev, [cur]: params![cur] }), {});
    return { ...options, params: new HttpParams({ encoder: new CustomHttpParamEncoder(), fromObject: newParams }) };
  }
}

class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
