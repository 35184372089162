"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canceledSubscriptionReasonWithoutIdSchema = exports.canceledSubscriptionReasonTableSchema = exports.userCanceledSubscriptionReasonSchema = exports.canceledSubscriptionReasonSchema = void 0;
const zod_1 = require("zod");
exports.canceledSubscriptionReasonSchema = zod_1.z.object({
    id: zod_1.z.string(),
    subscriptionId: zod_1.z.optional(zod_1.z.string()),
    wizefiId: zod_1.z.string(),
    cancelationReason: zod_1.z.string(),
    timestamp: zod_1.z.string()
});
exports.userCanceledSubscriptionReasonSchema = exports.canceledSubscriptionReasonSchema.required();
exports.canceledSubscriptionReasonTableSchema = exports.canceledSubscriptionReasonSchema.extend({
    timestampIndexPartitionKey: zod_1.z.string()
});
exports.canceledSubscriptionReasonWithoutIdSchema = exports.canceledSubscriptionReasonSchema.omit({ id: true });
