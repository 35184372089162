import { Injectable } from '@angular/core';
import { TransactionRule } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService, HttpOptions } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionRuleService {
  private readonly path = 'transaction-rule';
  private cachedRules$?: Observable<TransactionRule[]>;

  constructor(private apiService: ApiService) {}

  public getTransactionRules(): Observable<TransactionRule[]> {
    if (this.cachedRules$) {
      return this.cachedRules$;
    }
    this.cachedRules$ = this.apiService.get(this.path);
    return this.cachedRules$;
  }

  public createRule(rule: TransactionRule, autoCategorizeTransactions: boolean = true): Observable<TransactionRule> {
    return this.apiService.post(this.path, rule, this.getAutocategorizeQueryParam(autoCategorizeTransactions)).pipe(tap(() => this.clearCache()));
  }

  public updateRule(rule: TransactionRule, autoCategorizeTransactions: boolean = true): Observable<TransactionRule> {
    return this.apiService.put(this.path, rule, this.getAutocategorizeQueryParam(autoCategorizeTransactions)).pipe(tap(() => this.clearCache()));
  }

  public deleteRule(rule: TransactionRule): Observable<TransactionRule> {
    return this.apiService.delete(this.path, rule).pipe(tap(() => this.clearCache()));
  }

  public clearCache() {
    this.cachedRules$ = undefined;
  }

  private getAutocategorizeQueryParam(autoCategorizeTransactions: boolean): HttpOptions {
    return { params: { autoCategorizeTransactions: autoCategorizeTransactions ? 'true' : 'false' } };
  }
}
