/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { AccountActions } from '../account/account.actions';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { TrackingToggleActions } from './tracking-toggle.actions';

@Injectable()
export class TrackingToggleEffects {
  onToggleEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackingToggleActions.onToggle),
      filter(props => props.wasSelected),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      map(([_, yearMonth]) => AccountActions.getRequested({ yearMonth, autoCreate: false }))
    );
  });

  public constructor(private actions$: Actions, private store: Store) {}
}
