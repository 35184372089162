import { createAction, props } from '@ngrx/store';
import { Institution } from '@wizefi/entities';

export const institutionsGetRequested = createAction('[App] Institutions were requested', props<{ autoCreate: boolean }>());
export const institutionGetSuccessful = createAction('[App] Institutions get was successful', props<{ institutions: Institution[] }>());
export const institutionGetFailed = createAction('[App] Institutions get failed', props<{ err: any }>());

export const institutionCreationRequest = createAction(
  '[App] Institution creation was requested',
  props<{ institution: Institution; draftTitle?: string }>()
);
export const institutionCreationSuccessful = createAction('[App] Institution creation was successful', props<{ newInstitution: Institution }>());
export const institutionCreationFailed = createAction('[App] Institution creation failed', props<{ err: any }>());

export const institutionUpdateRequest = createAction(
  '[App] Institution update was requested',
  props<{ institution: Institution; draftTitle?: string }>()
);
export const institutionUpdateSuccessful = createAction('[App] Institution update was successful', props<{ updatedInstitution: Institution }>());
export const institutionUpdateFailed = createAction('[App] Institution update failed', props<{ err: any }>());

export const institutionDeleteRequest = createAction(
  '[App] Institution delete was requested',
  props<{ institution: Institution; draftTitle?: string }>()
);
export const institutionDeleteSuccessful = createAction(
  '[App] Institution delete was successful',
  props<{ deletedInstitution: Institution; isOriginalPlan: boolean }>()
);
export const institutionDeleteFailed = createAction('[App] Institution delete was failed', props<{ err: any }>());
