import { Account } from '@wizefi/entities';
import { createReducer, on } from '@ngrx/store';
import { createNewDraftSuccessful, loadDraftSuccessful } from '../draft/loaded-draft.actions';
import { institutionDeleteSuccessful } from '../institution/institution.actions';
import { LoginActions } from '../login/login.actions';
import { logoutRequested, newPlaidInstitutionLinkSuccessful } from '../screen.actions';
import { AccountActions } from './account.actions';
import { AccountState } from './account.state';

export const initialState: AccountState = {
  status: 'pending',
  accounts: [],
  originalAccounts: []
};

type StateSwitcher = (state: AccountState, props: any) => AccountState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });
const failedStateSwitcher: StateSwitcher = state => ({ ...state, status: 'failure' });

const switchToUpdatedAccounts = (state: AccountState, props: { updatedAccounts: Account[]; isOriginalPlan: boolean }): AccountState => ({
  ...state,
  status: 'success',
  accounts: props.updatedAccounts,
  originalAccounts: props.isOriginalPlan ? props.updatedAccounts : state.originalAccounts
});

export const accountsReducer = createReducer(
  initialState,
  on(
    LoginActions.loginSuccessful,
    (state, props): AccountState => ({
      status: 'success',
      accounts: props.accounts,
      originalAccounts: props.originalAccounts
    })
  ),
  on(AccountActions.getRequested, loadingStateSwitcher),
  on(
    AccountActions.getSuccessful,
    (state, props): AccountState => ({
      ...state,
      status: 'success',
      accounts: props.accounts
    })
  ),
  on(AccountActions.getFailed, failedStateSwitcher),
  on(AccountActions.saveRequested, loadingStateSwitcher),
  on(AccountActions.saveSuccessful, switchToUpdatedAccounts),
  on(AccountActions.saveFailed, failedStateSwitcher),
  on(AccountActions.getOriginalAccountsSuccessful, (state, props): AccountState => ({ ...state, originalAccounts: props.accounts })),
  on(
    AccountActions.deleteRequested,
    (state, props): AccountState => ({
      ...state,
      status: 'loading',
      accounts: props.removeImmediately ? state.accounts.filter(a => a.id !== props.account.id) : state.accounts
    })
  ),
  on(
    AccountActions.deleteSuccessful,
    (state, props): AccountState => ({
      ...state,
      status: 'success',
      accounts: state.accounts.filter(a => a.id !== props.account.id),
      originalAccounts: props.isOriginalPlan ? state.originalAccounts.filter(a => a.id !== props.account.id) : state.originalAccounts
    })
  ),
  on(AccountActions.deleteFailed, failedStateSwitcher),
  on(
    AccountActions.accountChanged,
    (state, props): AccountState => ({
      ...state,
      accounts: state.accounts.map(a => (a.id === props.account.id ? props.account : a)),
      originalAccounts: props.isOriginalPlan
        ? state.originalAccounts.map(a => (a.id === props.account.id ? props.account : a))
        : state.originalAccounts
    })
  ),
  on(AccountActions.createRequested, loadingStateSwitcher),
  on(
    AccountActions.createSuccessful,
    (state, props): AccountState => ({
      ...state,
      status: 'success',
      accounts: [...state.accounts, props.account],
      originalAccounts: props.isOriginalPlan ? [...state.originalAccounts, props.account] : state.originalAccounts
    })
  ),
  on(AccountActions.createFailed, failedStateSwitcher),
  on(
    institutionDeleteSuccessful,
    (state, props): AccountState => ({
      ...state,
      accounts: state.accounts.filter(a => a.itemId !== props.deletedInstitution.itemId),
      originalAccounts: props.isOriginalPlan
        ? state.originalAccounts.filter(a => a.itemId !== props.deletedInstitution.itemId)
        : state.originalAccounts
    })
  ),
  on(createNewDraftSuccessful, (state, props): AccountState => ({ ...state, accounts: props.draftResponse.draft.accounts })),
  on(loadDraftSuccessful, (state, props): AccountState => ({ ...state, accounts: props.draftResponse.draft.accounts })),
  on(AccountActions.balancesUpdateSuccessful, (state, props): AccountState => ({ ...state, status: 'success', accounts: props.accounts })),
  on(AccountActions.balancesUpdateFailed, failedStateSwitcher),

  on(AccountActions.updateBatchRequested, loadingStateSwitcher),
  on(AccountActions.updateBatchSuccessful, switchToUpdatedAccounts),
  on(AccountActions.updateBatchFailed, failedStateSwitcher),
  on(AccountActions.updateRequested, loadingStateSwitcher),
  on(AccountActions.updateSuccessful, switchToUpdatedAccounts),
  on(AccountActions.updateFailed, failedStateSwitcher),

  on(AccountActions.updateAndDeleteBatchRequested, (state, props): AccountState => {
    const deletedAccountsSet = new Set(props.deletedAccounts.map(a => a.id));
    return {
      ...state,
      status: 'loading',
      accounts: state.accounts.filter(a => !deletedAccountsSet.has(a.id)).map(a => props.updatedAccounts.find(a2 => a2.id === a.id) ?? a)
    };
  }),
  on(AccountActions.updateAndDeleteBatchSuccessful, (state, props): AccountState => ({ ...state, status: 'success' })),
  on(AccountActions.updateAndDeleteBatchFailed, failedStateSwitcher),
  on(newPlaidInstitutionLinkSuccessful, (state, props): AccountState => ({ ...state, accounts: [...state.accounts, ...props.newAccounts] })),

  on(logoutRequested, (): AccountState => initialState)
);
