/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { PayoutService } from '@app/services/payout.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMapTo } from 'rxjs/operators';
import { UserDataActions } from '../user-data/user-data.actions';
import { PayoutRequestActions } from './payout-request.actions';

@Injectable()
export class PayoutRequestsEffects {
  getEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutRequestActions.getRequested),
      switchMapTo(
        this.payoutService.getPayoutRequests().pipe(
          map(payoutRequests => PayoutRequestActions.getSuccessful({ payoutRequests })),
          catchError(() => of(PayoutRequestActions.getFailed()))
        )
      )
    );
  });

  createSavePayoutEmailEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutRequestActions.createRequested),
      map(props => UserDataActions.savePartialRequested({ partialValues: { payoutEmail: props.payoutAccount } }))
    );
  });

  createEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PayoutRequestActions.createRequested),
      mergeMap(props =>
        this.payoutService.createPayoutRequest(props.payoutAmountRequested, props.payoutAccount).pipe(
          map(payoutRequest => PayoutRequestActions.createSuccessful({ payoutRequest })),
          catchError(() => of(PayoutRequestActions.createFailed()))
        )
      )
    );
  });

  constructor(private actions$: Actions, private payoutService: PayoutService) {}
}
