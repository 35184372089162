"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userDataWithWizefiId = exports.userDataSchema = void 0;
const zod_1 = require("zod");
exports.userDataSchema = zod_1.z.object({
    affiliateAlias: zod_1.z.string(),
    isCoach: zod_1.z.optional(zod_1.z.boolean()),
    lastLoggedInIso: zod_1.z.optional(zod_1.z.string()),
    desiredDebtFreedomAge: zod_1.z.number(),
    dateCreated: zod_1.z.string(),
    dateUpdated: zod_1.z.string(),
    setupStepCompleted: zod_1.z.number(),
    originalPlanYearMonth: zod_1.z.string(),
    setupCompletedDate: zod_1.z.optional(zod_1.z.string()),
    parentAffiliateID: zod_1.z.string(),
    payoutEmail: zod_1.z.string(),
    hasCreatedFirstPlan: zod_1.z.optional(zod_1.z.boolean()),
    nameFirst: zod_1.z.string(),
    nameLast: zod_1.z.string(),
    email: zod_1.z.string(),
    gender: zod_1.z.optional(zod_1.z.string()),
    birthDate: zod_1.z.string(),
    zipCode: zod_1.z.optional(zod_1.z.string()),
    country: zod_1.z.optional(zod_1.z.string()),
    occupation: zod_1.z.optional(zod_1.z.string()),
    userPictureObjectKey: zod_1.z.string(),
    retirementAge: zod_1.z.number(),
    socialSecurityDrawAge: zod_1.z.number(),
    pensionDrawAge: zod_1.z.number(),
    monthlyRetirementIncome: zod_1.z.number(),
    /**
     * The yearly withdraw rate from the financial freedom net worth
     */
    withdrawRate: zod_1.z.number(),
    monthlySocialSecurity: zod_1.z.number(),
    monthlyPension: zod_1.z.number(),
    investmentReturnRate: zod_1.z.number(),
    inflationRate: zod_1.z.number(),
    currencyCode: zod_1.z.string(),
    decimalSeparator: zod_1.z.string(),
    thousandsSeparator: zod_1.z.string(),
    decimalDigits: zod_1.z.number(),
    /** Interest rate used in a fake debt account when expenses are larger than income. */
    assumedLoanInterestRate: zod_1.z.optional(zod_1.z.number()),
    autoCategorization: zod_1.z.boolean(),
    isEnterpriseParent: zod_1.z.optional(zod_1.z.boolean()),
    isEnterpriseMember: zod_1.z.optional(zod_1.z.boolean()),
    isEnterpriseMemberActive: zod_1.z.optional(zod_1.z.boolean()),
    isActiveEnterpriseContract: zod_1.z.optional(zod_1.z.boolean()),
});
exports.userDataWithWizefiId = exports.userDataSchema.extend({
    wizefiId: zod_1.z.string()
});
