"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.braintreeSubscriptionStatusSchema = exports.allBraintreeSubscriptionStatuses = void 0;
const zod_1 = require("zod");
exports.allBraintreeSubscriptionStatuses = [
    "Active",
    "Canceled",
    "Expired",
    "Past Due",
    "Pending",
];
exports.braintreeSubscriptionStatusSchema = zod_1.z.enum(exports.allBraintreeSubscriptionStatuses);
