"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportsCardSchema = exports.reportsCardType = void 0;
const zod_1 = require("zod");
exports.reportsCardType = zod_1.z.enum([
    'budget-category-pie-chart',
    'net-worth-chart',
    'assets-chart',
    'debt-chart',
    'top-5-expenses-card',
    'top-5-merchant-card',
    'category-spending-bar-chart'
]);
exports.reportsCardSchema = zod_1.z.object({
    enabled: zod_1.z.boolean(),
    type: exports.reportsCardType
});
