import { Injectable } from '@angular/core';
import { UserData } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private readonly path = 'user-data';

  constructor(private apiService: ApiService) {}

  getUserData(draftId?: string): Observable<UserData> {
    return this.apiService.get(this.path, { params: { draftId } });
  }

  saveUserData(userData: UserData, draftId?: string): Observable<UserData> {
    return this.apiService.put(this.path, userData, { params: { draftId } }).pipe(mapTo(userData));
  }
}
