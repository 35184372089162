/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CachedProjectionsService } from '@app/services/cached-projections.service';
import { DraftService } from '@app/services/draft.service';
import { AccountService } from '@app/services/account.service';
import { UserDataService } from '@app/services/user-data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { institutionGetSuccessful } from '../institution/institution.actions';
import { logoutRequested } from '../screen.actions';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { AccountSelectors } from '../account/account.selectors';
import { syncTransactionsRequested } from '../transaction/transaction.actions';
import { UserDataSelectors } from '../user-data/user-data.selectors';
import {
  createNewDraftFailed,
  createNewDraftRequested,
  createNewDraftSuccessful,
  draftUnloaded,
  loadDraftFailed,
  loadDraftRequested,
  loadDraftSuccessful,
  promoteDraftFailed,
  promoteDraftRequested,
  promoteDraftSuccessful,
  saveDraftAndExit,
  saveDraftAndExitFailed,
  saveDraftFailed,
  saveDraftRequested,
  saveDraftSuccessful
} from './loaded-draft.actions';
import { selectLoadedDraft } from './loaded-draft.selectors';
import { ACCOUNTS_PATH, EDIT_PLAN_PATH, PLAN_EXPENSES_PATH, PLAN_INCOME_PATH, PLAN_PATH, PLAN_PLAN_PATH } from '@app/modules/route-paths';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '@app/services/message/message.service';

@Injectable()
export class LoadedDraftEffects {
  loadDraftEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDraftRequested),
      mergeMap(props =>
        this.draftService.loadDraft(props.draftId).pipe(
          switchMap(draft =>
            of<Action>(loadDraftSuccessful({ draftResponse: draft }), institutionGetSuccessful({ institutions: draft.institutions }))
          ),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              this.messageService.error('Draft not found');
              this.router.navigate([PLAN_PATH]);
            }
            return of(loadDraftFailed());
          })
        )
      )
    );
  });

  draftLoadedInMemoryEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadDraftSuccessful, createNewDraftSuccessful),
        tap(() => this.cachedProjectionsService.recalculateCache())
      );
    },
    { dispatch: false }
  );

  saveDraftEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveDraftRequested),
      concatLatestFrom(() => [
        this.store.select(selectLoadedDraft),
        this.store.select(UserDataSelectors.selectUserData),
        this.store.select(AccountSelectors.accounts),
        this.store.select(selectSelectedMonth)
      ]),
      mergeMap(([props, draftId, userData, accounts, yearMonth]) =>
        this.userDataService.saveUserData(userData, draftId).pipe(
          mergeMap(() => this.accountService.batchUpdateAccounts(accounts, yearMonth, draftId).pipe(map(() => saveDraftSuccessful()))),
          catchError(err => of(saveDraftFailed({ err })))
        )
      )
    );
  });

  createNewDraftEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createNewDraftRequested),
      mergeMap(props =>
        this.draftService.createDraft(props.draftType, props.draftName).pipe(
          map(draft => ({ draft, fragment: props.navigateToFragment })),
          tap(({ draft, fragment }) => this.router.navigate([EDIT_PLAN_PATH, draft.draft.id], { fragment })),
          switchMap(({ draft }) =>
            of<Action>(createNewDraftSuccessful({ draftResponse: draft }), institutionGetSuccessful({ institutions: draft.institutions }))
          ),
          catchError(err => of(createNewDraftFailed({ err })))
        )
      )
    );
  });

  promoteDraftEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(promoteDraftRequested),
      concatLatestFrom(() => [
        this.store.select(selectLoadedDraft),
        this.store.select(UserDataSelectors.selectUserData),
        this.store.select(AccountSelectors.accounts),
        this.store.select(selectSelectedMonth)
      ]),
      mergeMap(([props, draftId, userData, accounts, yearMonth]) =>
        this.userDataService.saveUserData(userData, draftId).pipe(
          mergeMap(() =>
            this.accountService.batchUpdateAccounts(accounts, yearMonth, draftId).pipe(
              mergeMap(() =>
                this.draftService
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .promoteDraft(draftId!, `Previous Plan - ${new Date().toLocaleString()}`)
                  .pipe(
                    switchMap(() => of<Action>(promoteDraftSuccessful({ currentFragment: props.currentFragment }), syncTransactionsRequested({})))
                  )
              )
            )
          ),
          catchError(err => of(promoteDraftFailed({ err })))
        )
      )
    );
  });

  promoteDraftSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(promoteDraftSuccessful),
      map(props => draftUnloaded({ currentFragment: props.currentFragment }))
    );
  });

  saveDraftAndExitEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveDraftAndExit),
      concatLatestFrom(() => [
        this.store.select(selectLoadedDraft),
        this.store.select(UserDataSelectors.selectUserData),
        this.store.select(AccountSelectors.accounts),
        this.store.select(selectSelectedMonth)
      ]),
      mergeMap(([props, draftId, userData, accounts, yearMonth]) => {
        return this.userDataService.saveUserData(userData, draftId).pipe(
          mergeMap(() => this.accountService.batchUpdateAccounts(accounts, yearMonth, draftId)),
          map(() => draftUnloaded({ currentFragment: props.currentFragment })),
          catchError(err => of(saveDraftAndExitFailed({ err })))
        );
      })
    );
  });

  draftUnloadedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(draftUnloaded),
        tap(props => {
          switch (props.currentFragment) {
            case 'expenses':
              this.router.navigate([PLAN_PATH, PLAN_EXPENSES_PATH]);
              break;
            case 'income':
              this.router.navigate([PLAN_PATH, PLAN_INCOME_PATH]);
              break;
            case 'four-step-plan':
              this.router.navigate([PLAN_PATH, PLAN_PLAN_PATH]);
              break;
            case 'accounts':
              this.router.navigate([ACCOUNTS_PATH]);
              break;
            default:
              this.router.navigate([PLAN_PATH]);
              break;
          }
          // Clear cached projection calculations
          this.cachedProjectionsService.clearCachedEntry();
        })
      );
    },
    { dispatch: false }
  );

  clearingCacheEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested, promoteDraftSuccessful, createNewDraftSuccessful, saveDraftSuccessful),
        tap(() => this.draftService.clearCache())
      );
    },
    { dispatch: false }
  );

  public constructor(
    private store: Store,
    private actions$: Actions,
    private draftService: DraftService,
    private router: Router,
    private cachedProjectionsService: CachedProjectionsService,
    private userDataService: UserDataService,
    private accountService: AccountService,
    private messageService: MessageService
  ) {}
}
