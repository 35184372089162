import { Injectable } from '@angular/core';
import { BraintreeData } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class BraintreeService {
  private readonly path = 'braintree';
  private readonly dataPath = '/data';
  private readonly clientToken = '/clientToken';
  private readonly createFirstSubscriptionPath = '/createFirstSubscription';
  private readonly createFirstSubscriptionEnterprisePath = '/createFirstSubscriptionEnterprise';
  private readonly paymentMethodPath = '/paymentMethod';
  private readonly subscriptionPath = '/subscription';

  private cachedBraintreeData$: Observable<BraintreeData> | undefined;

  constructor(private apiService: ApiService) {}

  getBraintreeData(): Observable<BraintreeData> {
    if (this.cachedBraintreeData$) {
      return this.cachedBraintreeData$;
    }
    return this.apiService.get(this.path + this.dataPath).pipe(shareReplay(1));
  }

  generateClientToken(): Observable<string> {
    return this.apiService.post(this.path + this.clientToken).pipe(map(result => result.message));
  }

  createFirstSubscription(firstName: string, lastName: string, paymentMethodNonce: string): Observable<BraintreeData> {
    return this.apiService
      .post(this.path + this.createFirstSubscriptionPath, { firstName, lastName, paymentMethodNonce })
      .pipe(tap(() => this.clearCache()));
  }

  createFirstSubscriptionEnterprise(firstName: string, lastName: string): Observable<BraintreeData> {
    return this.apiService.post(this.path + this.createFirstSubscriptionEnterprisePath, { firstName, lastName }).pipe(tap(() => this.clearCache()));
  }

  createSubscription(paymentMethodToken: string): Observable<any> {
    return this.apiService.post(this.path + this.subscriptionPath, { paymentMethodToken });
  }

  updatePaymentMethod(paymentMethodToken: string, paymentMethodNonce: string): Observable<{ token: string }> {
    return this.apiService.put(this.path + this.paymentMethodPath, { paymentMethodToken, paymentMethodNonce }).pipe(tap(() => this.clearCache()));
  }

  createPaymentMethod(paymentMethodNonce: string): Observable<{ token: string }> {
    return this.apiService.post(this.path + this.paymentMethodPath, { paymentMethodNonce }).pipe(tap(() => this.clearCache()));
  }

  updateSubscription(subscriptionId: string, paymentMethodToken: string): Observable<any> {
    return this.apiService.put(this.path + this.subscriptionPath, { subscriptionId, paymentMethodToken }).pipe(tap(() => this.clearCache()));
  }

  cancelSubscription(subscriptionId: string, cancelationReason: string): Observable<any> {
    return this.apiService.delete(this.path + this.subscriptionPath, { subscriptionId, cancelationReason }).pipe(tap(() => this.clearCache()));
  }

  clearCache() {
    this.cachedBraintreeData$ = undefined;
  }
}
