import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoaderInteractive = new BehaviorSubject<boolean>(false);
  isLoaderInteractive$ = this.isLoaderInteractive.asObservable();

  setLoaderInteractive(interactive: boolean) {
    this.isLoaderInteractive.next(interactive);
  }
}
