/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { TransactionRuleService } from '@app/services/transaction-rule.service';
import { TransactionService } from '@app/services/transaction.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { logoutRequested, transactionsScreenInitialized } from '../screen.actions';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { transactionsGetRequested, transactionsGetSuccessful } from '../transaction/transaction.actions';
import {
  ruleCreationFailed,
  ruleCreationRequested,
  ruleCreationSuccessful,
  ruleDeleteFailed,
  ruleDeleteRequested,
  ruleDeleteSuccessful,
  ruleUpdateFailed,
  ruleUpdateRequested,
  ruleUpdateSuccessful,
  transactionRulesGetFailed,
  transactionRulesGetRequested,
  transactionRulesGetSuccessful
} from './transaction-rules.actions';

@Injectable()
export class TransactionRulesEffects {
  rulesGetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(transactionRulesGetRequested),
      mergeMap(() =>
        this.transactionRuleService.getTransactionRules().pipe(
          map(rules => transactionRulesGetSuccessful({ rules })),
          catchError(err => of(transactionRulesGetFailed({ err })))
        )
      )
    );
  });

  ruleCreationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ruleCreationRequested),
      mergeMap(props =>
        this.transactionRuleService.createRule(props.rule, true).pipe(
          concatLatestFrom(() => this.store.select(selectSelectedMonth)),
          mergeMap(([rule, selectedMonth]) =>
            this.transactionService.getTransactions(selectedMonth).pipe(map(transactions => ({ transactions, rule })))
          ),
          switchMap(({ rule, transactions }) => of(transactionsGetSuccessful({ transactions }), ruleCreationSuccessful({ rule }))),
          catchError(err => of(ruleCreationFailed({ err })))
        )
      )
    );
  });

  ruleUpdateEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ruleUpdateRequested),
      mergeMap(props =>
        this.transactionRuleService.updateRule(props.rule, true).pipe(
          switchMap(rule => of(transactionsGetRequested({}), ruleUpdateSuccessful({ rule }))),
          catchError(err => of(ruleUpdateFailed({ err })))
        )
      )
    );
  });

  ruleDeleteEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ruleDeleteRequested),
      mergeMap(props =>
        this.transactionRuleService.deleteRule(props.rule).pipe(
          map(rule => ruleDeleteSuccessful({ rule })),
          catchError(err => of(ruleDeleteFailed({ err })))
        )
      )
    );
  });

  screensToGetRulesEffect$ = createEffect(() => {
    return this.actions$.pipe(ofType(transactionsScreenInitialized), mapTo(transactionRulesGetRequested()));
  });

  clearingCacheEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested),
        tap(() => this.transactionRuleService.clearCache())
      );
    },
    { dispatch: false }
  );

  public constructor(
    private store: Store,
    private actions$: Actions,
    private transactionRuleService: TransactionRuleService,
    private transactionService: TransactionService
  ) {}
}
