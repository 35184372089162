import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import {
  PLAN_PATH,
  SETTINGS_PATH,
  SETUP_ACCOUNTS_PATH,
  SETUP_GOALS_PATH,
  SETUP_INCOME_PATH,
  SETUP_PATH,
  SETUP_PROJECTIONS_PATH
} from '@app/modules/route-paths';
import { MessageService } from '@app/services/message/message.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/components/confirmation-dialog.component';
import { Store } from '@ngrx/store';
import { setupFinalized } from '@app/state/screen.actions';
import { UserDataSelectors } from '@app/state/user-data/user-data.selectors';
import { UserData } from '@wizefi/entities';
import { UserDataActions } from '@app/state/user-data/user-data.actions';
import { AccountActions } from '@app/state/account/account.actions';
import { SaveableScreenService } from '@app/services/saveable-screen.service';
import { concatLatestFrom } from '@ngrx/effects';
import { IntercomService } from '@app/services/intercom.service';

export type SetupStepState = 'complete' | 'incomplete' | 'inProgress';
export interface SetupStep {
  path: string;
  label: string;
  state: SetupStepState;
}

@Component({
  selector: 'app-setup-step-menu',
  templateUrl: './setup-step-menu.component.html',
  styleUrls: ['./setup-step-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetupStepMenuComponent implements OnInit {
  setupSteps$!: Observable<SetupStep[]>;
  userData$!: Observable<UserData>;
  private setupStepsConfig: SetupStep[] = [
    { path: SETUP_GOALS_PATH, label: 'Goals', state: 'incomplete' },
    { path: SETUP_INCOME_PATH, label: 'Income', state: 'incomplete' },
    { path: SETUP_ACCOUNTS_PATH, label: 'Accounts', state: 'incomplete' },
    { path: SETUP_PROJECTIONS_PATH, label: 'Projections', state: 'incomplete' }
  ];
  private currentStep!: number;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog,
    private store: Store,
    private saveableScreenService: SaveableScreenService,
    private intercomService: IntercomService
  ) {}

  ngOnInit() {
    this.userData$ = this.store.select(UserDataSelectors.selectUserData);

    this.setupSteps$ = combineLatest([
      this.store.pipe(UserDataSelectors.selectLoadedUserData),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(new NavigationEnd(1, '', ''))
      )
    ]).pipe(
      concatLatestFrom(() => this.store.select(UserDataSelectors.selectUserData)),
      map(([_, userData]) => this.getCurrentStep(userData)),
      tap(currentStep => (this.currentStep = currentStep !== -1 ? currentStep : this.currentStep)),
      map(() => this.setupStepsConfig.map((step, index) => ({ ...step, state: this.getStepState(index, this.currentStep) })))
    );
  }

  async goToStep(userData: UserData, step: number) {
    const saved = await this.saveableScreenService.saveScreen();
    if (!saved) {
      return;
    }
    const isInLatestStepCompleted = this.currentStep === userData.setupStepCompleted - 1 && !this.isCurrentlyOnSettingsPage();

    // When in settings page, force user to go back to step they were at previously (or a step before that)
    if (step > userData.setupStepCompleted || (step === userData.setupStepCompleted && !isInLatestStepCompleted)) {
      this.messageService.error('Please complete all setup steps in order');
      return;
    }

    const newLatestStep = step + 1 > userData.setupStepCompleted ? step + 1 : userData.setupStepCompleted;
    this.store.dispatch(UserDataActions.savePartialRequested({ partialValues: { setupStepCompleted: newLatestStep } }));
    this.store.dispatch(AccountActions.saveRequested());
    this.router.navigate(this.getURLPath(step));
  }

  async onSaveAndContinue(userData: UserData) {
    const isInLastSetupStep = this.currentStep === this.setupStepsConfig.length - 1;
    if (isInLastSetupStep) {
      const confirmed = await this.waitForLastStepConfirmation();
      if (confirmed) {
        this.store.dispatch(setupFinalized());
      }
      return;
    }

    await this.goToStep(userData, this.currentStep + 1);
  }

  private getStepState(step: number, currentStep: number): SetupStepState {
    if (step < currentStep) {
      return 'complete';
    } else if (step === currentStep) {
      return 'inProgress';
    }
    return 'incomplete';
  }

  private getURLPath(step: number): string[] {
    if (step > this.setupStepsConfig.length) {
      return [PLAN_PATH];
    } else {
      return [SETUP_PATH, this.setupStepsConfig[step].path];
    }
  }

  private getCurrentStep(userData: UserData): number {
    return this.setupStepsConfig.findIndex(
      (step, idx) =>
        (this.router.url.includes(SETUP_PATH) && this.router.url.includes(step.path)) ||
        (this.currentStep === undefined && this.isCurrentlyOnSettingsPage() && userData.setupStepCompleted - 1 === idx)
    );
  }

  private waitForLastStepConfirmation(): Promise<boolean> {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Congratulations!',
          description: `
      You've just taken a huge step towards reaching financial independence. The next step is to
      <strong>see exactly where your money is going</strong> each month and <strong>create a customized plan to reach financial independence</strong> even
      sooner.<br /><br />
      Watch the Independence Plan video containing more information about achieving financial independence. Then click Continue to start setting up your Independence plan.
    `,
          confirmButtonText: 'Continue'
        }
      })
      .afterClosed()
      .toPromise();
  }

  private isCurrentlyOnSettingsPage() {
    return this.router.url.includes(SETTINGS_PATH);
  }
}
