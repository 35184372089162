import { Component, Inject, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { institutionDeleteRequest, institutionUpdateRequest } from '@app/state/institution/institution.actions';
import { refreshPlaidInstitutionCredentialsRequested } from '@app/state/screen.actions';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Account, Institution } from '@wizefi/entities';
import { AccountSelectors } from '@app/state/account/account.selectors';
import { AccountActions } from '@app/state/account/account.actions';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationDialogComponent } from '@app/components/confirmation-dialog.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogService } from '@app/services/dialog.service';

@Component({
  selector: 'app-plaid-institution',
  templateUrl: './edit-institution.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSlideToggleModule
  ]
})
export class EditInstitutionComponent implements OnInit {
  formGroup = new FormGroup({
    institutionName: new FormControl(this.data.institution.institutionName, { validators: Validators.required, nonNullable: true }),
    accounts: new FormArray<
      FormGroup<{ shouldSyncTransactions: FormControl<boolean>; isActive: FormControl<boolean>; deleted: FormControl<boolean> }>
    >([])
  });
  initialAccounts: Account[];

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<EditInstitutionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { institution: Institution }
  ) {}

  async ngOnInit() {
    this.initialAccounts = await this.store.select(AccountSelectors.selectAccountsInInstitution(this.data.institution)).pipe(first()).toPromise();
    this.initialAccounts.forEach(a =>
      this.formGroup.controls.accounts.push(
        new FormGroup({
          deleted: new FormControl(false, { nonNullable: true }),
          shouldSyncTransactions: new FormControl(!!a.shouldSyncTransactions, { nonNullable: true }),
          isActive: new FormControl(a.isActive, { nonNullable: true })
        })
      )
    );
  }

  async deleteAccount(idx: number) {
    const hasConfirmed = await this.dialogService.openDeleteAccountConfirmationDialog().afterClosed().toPromise();

    if (!hasConfirmed) {
      return;
    }

    const deleteFc = this.formGroup.controls.accounts.controls[idx].controls.deleted;
    deleteFc.setValue(true);
    deleteFc.markAsDirty();
  }

  save() {
    if (this.formGroup.pristine) {
      this.dialogRef.close();
      return;
    }
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (!this.formGroup.controls.institutionName.pristine) {
      const updatedInstitution = { ...this.data.institution, institutionName: this.formGroup.controls.institutionName.value };
      this.store.dispatch(institutionUpdateRequest({ institution: updatedInstitution }));
    }

    const changedForms = this.formGroup.controls.accounts.controls
      .map((fg, idx) => ({ ...fg, account: this.initialAccounts[idx] }))
      .filter(fg => !fg.pristine);
    const updatedAccounts: Account[] = changedForms
      .filter(fg => !fg.controls.deleted.value)
      .map(a => ({ ...a.account, isActive: a.controls.isActive.value, shouldSyncTransactions: a.controls.shouldSyncTransactions.value }));
    const deletedAccounts: Account[] = changedForms.filter(fg => fg.controls.deleted.value).map(a => ({ ...a.account }));

    this.store.dispatch(AccountActions.updateAndDeleteBatchRequested({ updatedAccounts, deletedAccounts }));
    this.dialogRef.close();
  }

  async deleteInstitution() {
    const hasConfirmed = await this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Delete institution?',
          description: `
  Deleting this institution will also delete all its accounts and transactions. Are you sure you want to proceed?
  `,
          confirmButtonColor: 'warn'
        }
      })
      .afterClosed()
      .toPromise();

    if (!hasConfirmed) {
      return;
    }

    this.store.dispatch(institutionDeleteRequest({ institution: this.data.institution }));
    this.dialogRef.close();
  }

  loadUpdatePlaidLink() {
    this.store.dispatch(
      refreshPlaidInstitutionCredentialsRequested({
        itemId: this.data.institution.itemId
      })
    );
  }
}
