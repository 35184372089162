"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.affiliateTreeChangelogSchema = void 0;
const zod_1 = require("zod");
exports.affiliateTreeChangelogSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdBy: zod_1.z.string(),
    affiliateId: zod_1.z.string(),
    operation: zod_1.z.enum(["changeParent", "changeAlias", "removeChildren"]),
    dateIso: zod_1.z.string(),
    oldParent: zod_1.z.optional(zod_1.z.string()),
    newParent: zod_1.z.optional(zod_1.z.string()),
    oldAlias: zod_1.z.optional(zod_1.z.string()),
    newAlias: zod_1.z.optional(zod_1.z.string()),
    childrenRemoved: zod_1.z.optional(zod_1.z.array(zod_1.z.string())),
    reasonForChange: zod_1.z.string(),
});
