import { UserData } from '@wizefi/entities';
import { createReducer, on } from '@ngrx/store';
import { createNewDraftSuccessful, loadDraftSuccessful } from '../draft/loaded-draft.actions';
import { LoginActions } from '../login/login.actions';
import { logoutRequested, movedFromSetupToPlan } from '../screen.actions';
import { SubscriptionAction } from '../subscription/subscription.actions';
import { UserDataActions } from './user-data.actions';
import { UserDataState } from './user-data.state';
import { switchToFailure } from '../reducer-utils';

export const initialState: UserDataState = {
  status: 'pending',
  userData: {
    affiliateAlias: '',
    dateCreated: '2017-05-09T12:14:32.456Z',
    dateUpdated: '2017-05-09T12:15:32.456Z',
    setupStepCompleted: 1, // number of highest setup step that has been completed
    originalPlanYearMonth: '',
    parentAffiliateID: '',
    payoutEmail: '', // PayPal
    hasCreatedFirstPlan: false,

    currencyCode: 'USD',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    decimalDigits: 2,

    nameFirst: '',
    nameLast: '',
    email: '',
    gender: '',
    birthDate: '', // date format: YYYY-MM-DD
    zipCode: '',
    country: '',
    occupation: '',
    userPictureObjectKey: 'pictureNotAvailable.png', // default object key for user picture
    retirementAge: 67,
    desiredDebtFreedomAge: 67,
    socialSecurityDrawAge: 67,
    pensionDrawAge: 67,
    monthlyRetirementIncome: 0,
    withdrawRate: 4,
    monthlySocialSecurity: 0,
    monthlyPension: 0,
    investmentReturnRate: 6,
    inflationRate: 2.07,
    autoCategorization: true
  }
};

type StateSwitcher = (state: UserDataState, props: any) => UserDataState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });
const switchToUserData = (state: UserDataState, props: { userData: UserData }): UserDataState => ({
  ...state,
  status: 'success',
  userData: props.userData
});

export const userDataReducer = createReducer(
  initialState,
  on(LoginActions.loginSuccessful, switchToUserData),

  on(UserDataActions.getRequested, loadingStateSwitcher),
  on(UserDataActions.getSuccessful, switchToUserData),
  on(UserDataActions.getFailed, switchToFailure),

  on(UserDataActions.savePartialRequested, loadingStateSwitcher),
  on(UserDataActions.saveSuccessful, switchToUserData),
  on(UserDataActions.saveFailed, switchToFailure),

  on(createNewDraftSuccessful, (state, props): UserDataState => ({ ...state, userData: props.draftResponse.draft.userData })),
  on(movedFromSetupToPlan, switchToUserData),
  on(UserDataActions.changePartial, (state, props): UserDataState => ({ ...state, userData: { ...state.userData, ...props.values } })),
  on(LoginActions.updateEmailSuccessful, (state, props): UserDataState => ({ ...state, userData: { ...state.userData, email: props.newEmail } })),
  on(
    SubscriptionAction.createSubscriptionRequested,
    (state, props): UserDataState => ({ ...state, userData: { ...state.userData, nameFirst: props.firstName, nameLast: props.lastName } })
  ),
  on(loadDraftSuccessful, (state, props): UserDataState => ({ ...state, userData: props.draftResponse.draft.userData })),
  on(logoutRequested, (): UserDataState => initialState)
);
