import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { TextMaskModule } from 'angular2-text-mask';
import { CloseIconComponent } from '../../structure/close-icon/close-icon.component';
import { SearchIconComponent } from '../../structure/search-icon/search-icon.component';
import { LoaderComponent } from '../../structure/loader/loader.component';
import { SetupStepMenuItemComponent } from '../../structure/setup-step-menu/menu-item/setup-step-menu-item.component';
import { SetupStepMenuComponent } from '../../structure/setup-step-menu/setup-step-menu.component';
import { SidebarComponent } from '../../structure/sidebar/sidebar.component';
import { TextInputComponent } from '../../structure/text-input/text-input.component';

import { SubscriptionCardComponent } from '@app/structure/subscription-card.component';
import { SubscriptionDialogComponent } from '@app/structure/subscription-dialog.component';
import { ContentCardComponent } from '@app/structure/content-card.component';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ReactiveComponentModule } from '@ngrx/component';
import { TruncateEllipsisPipe } from '@app/pipes/truncate-ellipsis.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FieldDisplayComponent } from './field-display.component';
import { TransactionListItemComponent } from '@app/components/cards/transaction-list-item/transaction-list-item.component';
import { AccountTransactionsComponent } from '@app/components/cards/account-transactions/account-transactions.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CurrencyInputDirective } from './currency-input.directive';
import { CardModule } from '../card/card.module';
import { MonthSelectorModule } from '../month-selector/month-selector.module';
import { MonthSelectorDropdownComponent } from '../month-selector/month-selector-dropdown.component';
import { IntegerInputDirective } from './integer-input.directive';
import { CancelSubscriptionDialogComponent } from '@app/structure/cancel-subscription-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OrdinalPipe } from './ordinal.pipe';
import { OutlinedIconComponent } from './outlined-icon.component';
import { InfoCardComponent } from './info-card.component';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [
    SidebarComponent,
    SetupStepMenuItemComponent,
    SetupStepMenuComponent,
    LoaderComponent,
    TextInputComponent,
    SearchIconComponent,
    CloseIconComponent,
    ContentCardComponent,
    SubscriptionDialogComponent,
    SubscriptionCardComponent,
    TruncateEllipsisPipe,
    FieldDisplayComponent,
    TransactionListItemComponent,
    AccountTransactionsComponent,
    CurrencyInputDirective,
    IntegerInputDirective,
    CancelSubscriptionDialogComponent,
    OrdinalPipe,
    OutlinedIconComponent,
    InfoCardComponent
  ],
  imports: [
    FormsModule,
    MatButtonModule,
    TextMaskModule,
    RouterModule,
    CommonModule,
    ReactiveComponentModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    CardModule,
    MonthSelectorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatBadgeModule
  ],
  exports: [
    SidebarComponent,
    SetupStepMenuItemComponent,
    SetupStepMenuComponent,
    LoaderComponent,
    TextInputComponent,
    SearchIconComponent,
    CloseIconComponent,
    ContentCardComponent,
    SubscriptionDialogComponent,
    SubscriptionCardComponent,
    TruncateEllipsisPipe,
    FieldDisplayComponent,
    TransactionListItemComponent,
    AccountTransactionsComponent,
    CurrencyInputDirective,
    IntegerInputDirective,
    MonthSelectorDropdownComponent,
    OrdinalPipe,
    OutlinedIconComponent,
    InfoCardComponent
  ],
  providers: [
    CurrencyPipe,
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    }
  ]
})
export class StructureModule {}
