import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { AppState } from '../app.state';

export const selectSubscription = (state: AppState) => state.subscription;

export const selectSubscriptionPrice = createSelector(selectSubscription, state => state.price);

export const selectLoadedSubscription = pipe(
  select(selectSubscription),
  filter(subscription => subscription.loadingStatus === 'success')
);

export const selectSubscriptionLoadingStatus = createSelector(selectSubscription, state => state.loadingStatus);

export const selectSubscriptionOperationHasFinished = pipe(
  select(selectSubscriptionLoadingStatus),
  pairwise(),
  filter(([status1, status2]) => status1 === 'loading' && status2 === 'success')
);
