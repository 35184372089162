import { DraftInfo } from '@wizefi/entities';
import { DraftGetResponse } from '@app/interfaces/draft-get-response';
import { createReducer, on } from '@ngrx/store';
import { logoutRequested } from '../screen.actions';
import {
  draftDialogOpened,
  draftInfoDeleteFailed,
  draftInfoDeleteRequested,
  draftInfoDeleteSuccessful,
  draftInfoGetFailed,
  draftInfoGetSuccessful,
  draftInfoRenameFailed,
  draftInfoRenameRequested,
  draftInfoRenameSuccessful
} from './draft-info.actions';
import { DraftInfoState } from './draft-info.state';
import { createNewDraftSuccessful } from './loaded-draft.actions';

export const initialState: DraftInfoState = {
  status: 'pending',
  draftList: []
};

type StateSwitcher = (state: DraftInfoState, props: any) => DraftInfoState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });
const failureStateSwitcher: StateSwitcher = state => ({ ...state, status: 'failure' });

export const draftInfoReducer = createReducer(
  initialState,
  on(draftDialogOpened, (state): DraftInfoState => ({ ...state, status: 'loading' })),
  on(draftInfoGetSuccessful, (state, props): DraftInfoState => ({ ...state, status: 'success', draftList: props.draftInfo })),
  on(draftInfoGetFailed, failureStateSwitcher),
  on(draftInfoDeleteRequested, loadingStateSwitcher),
  on(draftInfoDeleteSuccessful, (state, props) => ({
    ...state,
    status: 'success',
    draftList: state.draftList.filter(d => d.id !== props.deletedDraftId)
  })),
  on(draftInfoDeleteFailed, failureStateSwitcher),
  on(draftInfoRenameRequested, loadingStateSwitcher),
  on(
    draftInfoRenameSuccessful,
    (state, props): DraftInfoState => ({
      ...state,
      status: 'success',
      draftList: state.draftList.map(i => (i.id === props.updatedDraft.id ? props.updatedDraft : i))
    })
  ),
  on(draftInfoRenameFailed, failureStateSwitcher),
  on(createNewDraftSuccessful, (state, props) => ({
    ...state,
    draftList: [...removeOldTemporaryDraft(state, props.draftResponse), props.draftResponse.draft]
  })),
  on(logoutRequested, (): DraftInfoState => initialState)
);

const removeOldTemporaryDraft = (state: DraftInfoState, props: DraftGetResponse): DraftInfo[] =>
  state.draftList.filter(i => i.draftType !== 'temporary' || props.draft.draftType !== 'temporary');
