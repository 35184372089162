"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payoutSchema = void 0;
const zod_1 = require("zod");
exports.payoutSchema = zod_1.z.object({
    id: zod_1.z.string(),
    amount: zod_1.z.number(),
    wizefiId: zod_1.z.string(),
    payoutRequestId: zod_1.z.string(),
    /** Account id of the bank where the payout was paid to. In case of paypal, this is the email */
    payoutAccount: zod_1.z.string(),
    /** Transaction id of the payout */
    payoutTransactionId: zod_1.z.string(),
    createdBy: zod_1.z.string(),
    createdAt: zod_1.z.string(),
});
