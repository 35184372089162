import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/components/confirmation-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDeleteAccountConfirmationDialog() {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete account?',
        description: `
  Deleting this account will also delete all its transactions. Are you sure you want to proceed?
  `,
        confirmButtonColor: 'warn'
      }
    });
  }
}
