/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LOGIN_PATH, START_HERE_PATH } from '@app/modules/route-paths';
import { PlaidService } from '@app/services/plaid.service';
import { UserDataService } from '@app/services/user-data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { selectLoadedDraft } from './draft/loaded-draft.selectors';
import { selectInstitutions } from './institution/institution.root.selectors';
import {
  editFfdComponentInitialized,
  newPlaidInstitutionLinkRequested,
  newPlaidInstitutionLinkSuccessful,
  refreshPlaidInstitutionCredentialsRequested,
  logoutRequested,
  setupFinalized,
  movedFromSetupToPlan,
  planPageInitialized
} from './screen.actions';
import { AccountSelectors } from './account/account.selectors';
import { lastMonthTransactionsGetRequested } from './transaction/transaction.actions';
import { UserDataSelectors } from './user-data/user-data.selectors';
import { selectSelectedMonth } from './selected-month/selected-month.selectors';
import { AccountActions } from './account/account.actions';
import { AffiliateService } from '@app/services/affiliate.service';
import * as moment from 'moment';
import { EditInstitutionComponent } from '@app/components/edit-institution/edit-institution.component';

@Injectable()
export class ScreenEffects {
  editFfdComponentInitializedEffect$ = createEffect(() => {
    return this.actions$.pipe(ofType(editFfdComponentInitialized), mapTo(lastMonthTransactionsGetRequested({ yearMonth: moment().toDate() })));
  });

  planPageInitializedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planPageInitialized),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      map(([_, yearMonth]) => lastMonthTransactionsGetRequested({ yearMonth: moment(yearMonth + '-20').toDate() }))
    );
  });

  newPlaidInstitutionLinkEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(newPlaidInstitutionLinkRequested),
      concatLatestFrom(() => [
        this.store.select(selectLoadedDraft),
        this.store.select(AccountSelectors.bankAccounts),
        this.store.select(selectInstitutions),
        this.store.select(selectSelectedMonth)
      ]),
      switchMap(([props, draftId, accounts, institutions, yearMonth]) =>
        this.plaidService.linkNewPlaidInstitution(accounts, institutions, yearMonth, draftId).pipe(
          map(result => newPlaidInstitutionLinkSuccessful(result)),
          tap(() => this.dialog.closeAll()),
          tap(result => this.dialog.open(EditInstitutionComponent, { data: { institution: result.institution } }))
        )
      )
    );
  });

  refreshPlaidInstitutionCredentialsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshPlaidInstitutionCredentialsRequested),
      concatLatestFrom(() => [this.store.select(selectSelectedMonth), this.store.select(selectLoadedDraft)]),
      switchMap(([props, yearMonth, draftId]) => this.plaidService.refreshPlaidInstitutionCredentials(props.itemId, yearMonth, draftId)),
      map(() => AccountActions.balancesUpdateRequested())
    );
  });

  logoutRequestedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested),
        tap(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.dialog.closeAll();
          this.affiliateService.resetAffiliatesCache();
        }),
        switchMap(() => this.router.navigate([LOGIN_PATH]))
      );
    },
    { dispatch: false }
  );

  setupFinalizedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setupFinalized),
      concatLatestFrom(() => this.store.select(UserDataSelectors.selectUserData)),
      switchMap(([_, userData]) => {
        const now = new Date().toISOString();
        return this.userDataService.saveUserData({ ...userData, setupCompletedDate: now });
      }),
      tap(() => this.router.navigate([START_HERE_PATH])),
      map(userData => movedFromSetupToPlan({ userData }))
    );
  });

  public constructor(
    private store: Store,
    private actions$: Actions,
    private plaidService: PlaidService,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private affiliateService: AffiliateService,
    private router: Router
  ) {}
}
