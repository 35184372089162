import { PayoutRequest } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export class PayoutRequestActions {
  static getRequested = createAction(`[PayoutRequestActions] get requested`);
  static getSuccessful = createAction(`[PayoutRequestActions] get successful`, props<{ payoutRequests: PayoutRequest[] }>());
  static getFailed = createAction(`[PayoutRequestActions] get failed`);
  static createRequested = createAction(`[PayoutRequestActions] create requested`, props<{ payoutAmountRequested: number; payoutAccount: string }>());
  static createSuccessful = createAction(`[PayoutRequestActions] create successful`, props<{ payoutRequest: PayoutRequest }>());
  static createFailed = createAction(`[PayoutRequestActions] create failed`);
}
