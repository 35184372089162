import { Injectable } from '@angular/core';
import { PayoutRequest, PayoutRequestWithName, Payout, PayoutWithName } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PayoutService {
  private readonly path = 'payouts';
  private readonly requestsPath = '/requests';
  private readonly rejectPath = '/reject';
  private readonly allPath = '/all';

  constructor(private api: ApiService) {}

  getPayoutRequests(): Observable<PayoutRequest[]> {
    return this.api.get<PayoutRequest[]>(this.path + this.requestsPath);
  }

  getAllPayoutRequests(startDate: string, endDate: string): Observable<PayoutRequestWithName[]> {
    return this.api.post<PayoutRequestWithName[]>(this.path + this.requestsPath + this.allPath, {
      startDate,
      endDate
    });
  }

  createPayoutRequest(payoutAmountRequested: number, payoutAccount: string): Observable<PayoutRequest> {
    return this.api.post<PayoutRequest>(this.path + this.requestsPath, { payoutAmountRequested, payoutAccount });
  }

  rejectPayoutRequest(payoutRequestId: string, rejectionReason: string) {
    return this.api.put<PayoutRequest>(this.path + this.requestsPath + this.rejectPath, { payoutRequestId, rejectionReason });
  }

  createPayout(payoutRequestId: string, payoutTransactionId: string) {
    return this.api.post<Payout>(this.path, { payoutRequestId, payoutTransactionId });
  }

  getUserPayouts() {
    return this.api.get<Payout[]>(this.path);
  }

  getAllPayouts(startDate: string, endDate: string) {
    return this.api.get<PayoutWithName[]>(this.path + this.allPath, { params: { startDate, endDate } });
  }
}
