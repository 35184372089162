import { createReducer, on } from '@ngrx/store';
import { logoutRequested } from '../screen.actions';
import {
  ruleCreationFailed,
  ruleCreationRequested,
  ruleCreationSuccessful,
  ruleDeleteFailed,
  ruleDeleteRequested,
  ruleDeleteSuccessful,
  ruleUpdateFailed,
  ruleUpdateRequested,
  ruleUpdateSuccessful,
  transactionRulesGetFailed,
  transactionRulesGetRequested,
  transactionRulesGetSuccessful
} from './transaction-rules.actions';
import { TransactionRulesState } from './transaction-rules.state';
import { switchToFailure } from '../reducer-utils';

export const initialState: TransactionRulesState = {
  status: 'pending',
  rules: []
};

type StateSwitcher = (state: TransactionRulesState, props: any) => TransactionRulesState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });

export const transactionRulesReducer = createReducer(
  initialState,
  on(transactionRulesGetRequested, loadingStateSwitcher),
  on(transactionRulesGetSuccessful, (state, props): TransactionRulesState => ({ ...state, status: 'success', rules: props.rules })),
  on(transactionRulesGetFailed, switchToFailure),

  on(ruleCreationRequested, loadingStateSwitcher),
  on(ruleCreationSuccessful, (state, props): TransactionRulesState => ({ ...state, status: 'success', rules: [...state.rules, props.rule] })),
  on(ruleCreationFailed, switchToFailure),

  on(ruleUpdateRequested, loadingStateSwitcher),
  on(ruleUpdateSuccessful, (state, { rule }) => ({
    ...state,
    status: 'success',
    rules: state.rules.map(r => (r.id === rule.id ? rule : r))
  })),
  on(ruleUpdateFailed, switchToFailure),

  on(ruleDeleteRequested, loadingStateSwitcher),
  on(ruleDeleteSuccessful, (state, { rule }) => ({ ...state, status: 'success', rules: state.rules.filter(r => r.id !== rule.id) })),
  on(ruleDeleteFailed, switchToFailure),

  on(logoutRequested, (): TransactionRulesState => initialState)
);
