/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BraintreeData, UserData } from '@wizefi/entities';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  constructor() {}

  boot() {
    (window as any).Intercom('boot', this.defaultIntercomProps());
  }

  updateData(wizefiId: string, userData: UserData, braintreeData: BraintreeData) {
    (window as any).Intercom('update', this.getIntercomObject(wizefiId, userData, braintreeData));
  }

  reset() {
    (window as any).Intercom('shutdown');
    this.boot();
  }

  show() {
    (window as any).Intercom('show');
  }

  showWithPrepopulatedMessage(message: string) {
    (window as any).Intercom('showNewMessage', message);
  }

  showArticle(articleId: string) {
    // BUG: looks like Intercom requires a reset before showing an article
    this.reset();
    (window as any).Intercom('showArticle', articleId);
  }

  private getIntercomObject(wizefiId: string, userData: UserData, braintreeData: BraintreeData) {
    return {
      ...this.defaultIntercomProps(),
      email: userData.email,
      user_id: wizefiId,
      name: userData.nameFirst + ' ' + userData.nameLast,
      environment: environment.mode,
      affiliate_alias: userData.affiliateAlias,
      parent_affiliate_id: userData.parentAffiliateID,
      setup_complete_date: userData.setupCompletedDate,
      current_setup_step: userData.setupStepCompleted,
      name_first: userData.nameFirst,
      name_last: userData.nameLast,
      occupation: userData.occupation,
      zip: userData.zipCode,
      birth_date: userData.birthDate,
      subscription_is_active: braintreeData.isActive,
      subscription_next_billing_date: braintreeData.nextBillingDate,
      subscription_status: braintreeData.status,
      subscription_has_braintree_account: braintreeData.haveBraintreeAccount
    };
  }

  private defaultIntercomProps() {
    return {
      app_id: environment.intercomId,
      custom_launcher_selector: '.support-trigger'
    };
  }
}
