import { Injectable } from '@angular/core';
import { initialState } from '@app/state/reports/reports-cards.reducer';
import { ReportsCard } from '@wizefi/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsCardsService {
  private readonly path = 'reports-cards';

  constructor(private api: ApiService) {}

  getCards(): Observable<ReportsCard[]> {
    return this.api.get(this.path).pipe(map(result => (Array.isArray(result) ? result : initialState.cards)));
  }

  updateCards(cards: ReportsCard[]) {
    return this.api.put(this.path, cards);
  }
}
