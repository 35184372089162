"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountTypeSchema = void 0;
const zod_1 = require("zod");
exports.accountTypeSchema = zod_1.z.enum([
    "liabilities",
    "income",
    "assetProtection",
    "budget",
    "assets",
]);
