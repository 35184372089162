import { UserData } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export class UserDataActions {
  static getRequested = createAction('[App] get user data requested');
  static getSuccessful = createAction('[App] get user data successful', props<{ userData: UserData }>());
  static getFailed = createAction('[App] get user data failed', props<{ err: any }>());

  static savePartialRequested = createAction('[App] save partial user data requested', props<{ partialValues: Partial<UserData> }>());
  static saveSuccessful = createAction('[App] save user data successful', props<{ userData: UserData }>());
  static saveFailed = createAction('[App] save user data failed', props<{ err: any }>());

  static changePartial = createAction('[App] change partial user data without saving', props<{ values: Partial<UserData> }>());
}
