<div class="signup-outer-container">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div class="fixed-top" *ngIf="!isLoading">
    <a routerLink="/login">
      <button mat-flat-button [color]="'primary'" style="height: 50px; padding: 0 32px">Log in</button>
    </a>
  </div>
  <div class="signup-container flex" *ngIf="!isLoading">
    <div class="flex-column banner">
      <div class="flex-column congratulations-section justify-center" style="align-self: center">
        <div style="text-align: center; padding: 20px 0 0 0">
          <img src="assets/logo-WizeFi-black.svg" width="150" />
        </div>
        <p *ngIf="!this.inCCEntryMode" class="signup-subhead-txt">
          Proven to <span style="color: #4272ff; font-weight: bold">save</span> hundreds each month<br />and
          <span style="color: #4272ff; font-weight: bold">accelerate</span> financial independence
        </p>
        <p *ngIf="this.inCCEntryMode" style="font-size: 1.3em; font-weight: 400; color: #000000; padding: 10px 0 10px 0; text-align: center">
          Why this customer loves WizeFi:
        </p>
        <div *ngIf="bluprint !== null && bluprint" id="thumbnail-image-container">
          <img src="../../../assets/images/bluprint1.jpg" alt="" />
        </div>
        <div *ngIf="bluprint !== null && !bluprint && !isLoading" id="video-player-container">
          <div *ngIf="!this.inCCEntryMode" class="video-player" style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              *ngIf="!this.inCCEntryMode"
              src="https://player.vimeo.com/video/731405784?badge=0&amp;autopause=0&amp&title=0&byline=0&portrait=0;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              class="video-player"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
              title="Affiliate Landing Page.mp4"
            ></iframe>
          </div>
          <div *ngIf="this.inCCEntryMode" class="video-player" style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              *ngIf="this.inCCEntryMode"
              src="https://player.vimeo.com/video/914058446?badge=0&amp;autopause=0&amp&title=0&byline=0&portrait=0;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              class="video-player"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <!-- <img *ngIf="this.inCCEntryMode" width="300" height="750" class="ccImage" /> -->
        <!-- Avatar Container start-->
        <div id="cc-testimonial-div" *ngIf="this.inCCEntryMode">
          <p style="font-size: 1.3em; font-weight: 400; color: #000000; padding: 0 0 10px 0; text-align: center">Testimonials</p>
          <div class="cc-test-container">
            <div class="cc-test-avatar">
              <!-- <img src="assets/avatars/female-avatar-3.svg" width="75" /> -->
              <p style="font-weight: bold">Adrian M.</p>
            </div>
            <div class="cc-test-quote">
              <p>
                <span style="font-style: italic">
                  “Since starting with WizeFi, I've been able to pay off debt, increase my net worth almost 20%, and decrease my retirement date by 25
                  years”</span
                >
              </p>
            </div>
          </div>
          <div class="cc-test-container">
            <div class="cc-test-avatar">
              <!-- <img src="assets/avatars/male-avatar-1.svg" width="75" /> -->
              <p style="font-weight: bold">Dan B.</p>
            </div>
            <div class="cc-test-quote">
              <p>
                <span style="font-style: italic"
                  >“I like WizeFi better than all of them (Mint, YNAB, EveryDollar) because the forecasting beats everything.”</span
                >
              </p>
            </div>
          </div>
          <div class="cc-test-container">
            <div class="cc-test-avatar">
              <!-- <img src="assets/avatars/female-avatar-2.svg" width="75" /> -->
              <p style="font-weight: bold">Marilyn C.</p>
            </div>
            <div class="cc-test-quote">
              <p><span style="font-style: italic">“I saved over $200 last week by cutting back on expenses.”</span></p>
            </div>
          </div>
        </div>
        <!-- Avatar Container end-->
      </div>
    </div>
    <div class="rule-separator align-self-center"></div>
    <div class="signup-credentials-div">
      <div>
        <h4 *ngIf="!this.inCCEntryMode">Congratulations!</h4>
        <div>
          <div style="width: 20px; display: inline-block; vertical-align: top">
            <p *ngIf="!this.inCCEntryMode"><img src="assets/icon_trophy.svg" /></p>
          </div>
          <div style="display: inline-block">
            <p *ngIf="!this.inCCEntryMode">
              WizeFi is by invite-only. Your<br />
              invitation from {{ affiliateName }}<br />
              grants you access
              <span *ngIf="!parentaffiliateData.isEnterpriseParent && !parentaffiliateData.isActiveEnterpriseContract">
                AND a {{ trialDays }}<br />
              </span>
              <span *ngIf="!parentaffiliateData.isEnterpriseParent && !parentaffiliateData.isActiveEnterpriseContract">Free Trial!</span>
              <span style="font-size: 0.75em" *ngIf="!parentaffiliateData.isEnterpriseParent && !parentaffiliateData.isActiveEnterpriseContract"
                >($8.99 a month thereafter)</span
              >.
            </p>
          </div>
        </div>
      </div>
      <app-create-account-signup
        class="d-flex flex-column align-items-center justify-center"
        *ngIf="this.signupMode === signupComponentType.freeTrialSignupMode"
        [parentAffiliateId]="parentAffiliateId"
        [isEnterpriseMember]="parentaffiliateData.isEnterpriseMember ?? false"
        [isEnterpriseParent]="parentaffiliateData.isEnterpriseParent ?? false"
        [isActiveEnterpriseContract]="parentaffiliateData.isActiveEnterpriseContract ?? false"
        [isEnterpriseMemberActive]="parentaffiliateData.isEnterpriseMemberActive ?? false"
      >
      </app-create-account-signup>
      <app-subscription-signup
        class="d-flex flex-column align-items-center justify-center"
        *ngIf="this.signupMode === signupComponentType.subscriptionSignupMode"
      >
      </app-subscription-signup>
    </div>
    <br /><br /><br />
  </div>
</div>
