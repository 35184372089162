"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationPreferencesTableName = exports.notificationPreferencesSchema = void 0;
const zod_1 = require("zod");
exports.notificationPreferencesSchema = zod_1.z.object({
    wizefiId: zod_1.z.string(),
    newAffiliate: zod_1.z.boolean(),
    budgetOverspend: zod_1.z.boolean(),
    budgetOverspendOn: zod_1.z.enum(['budget', 'category', 'subcategory']),
    budgetOverspendPercentage: zod_1.z.number().lte(1).gte(0.7),
    report: zod_1.z.boolean(),
    reportFrequency: zod_1.z.enum(['weekly', 'monthly']),
    transactionsAlert: zod_1.z.boolean(),
    dontShowAgainItemError: zod_1.z.optional(zod_1.z.boolean())
});
exports.notificationPreferencesTableName = 'NotificationPreferences';
