import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SentryService } from '@app/services/sentry.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  async handleError(error: any) {
    const sentryService = this.injector.get(SentryService);
    const chunkFailedMessage = 'ChunkLoadError: Loading chunk';
    if (
      (typeof error.message === 'string' && error.message?.includes(chunkFailedMessage)) ||
      (typeof error === 'string' && error.includes(chunkFailedMessage))
    ) {
      window.location.reload();
      return;
    }

    if (error.message) {
      console.error(error.message);
    } else {
      console.error(error);
    }
    if (error.stack) {
      console.error(error.stack);
    }

    sentryService.captureException(error);
  }
}
