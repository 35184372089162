<div class="d-flex flex-column align-items-center justify-content-center subscription-signup-container">
  <h2>Claim Your Free Trial</h2>
  <div class="price-info">Then only $8.99/month! You can cancel at any time.</div>
  <form (submit)="this.register()">
    <mat-form-field [appearance]="'fill'">
      <mat-label>First Name</mat-label>
      <input matInput [(ngModel)]="nameFirst" name="first" required autofocus data-test="subscription-first-name-input" />
    </mat-form-field>

    <mat-form-field [appearance]="'fill'">
      <mat-label>Last Name</mat-label>
      <input matInput [(ngModel)]="nameLast" name="last" required data-test="subscription-last-name-input" />
    </mat-form-field>
    <div id="braintree-widget-container"></div>
    <div>
      <div style="display: inline-block; width: 25px; vertical-align: top"><img src="assets/shield-halved-solid-2.svg" width="15" /></div>
      <div style="display: inline-block; width: 275px" class="price-info">
        <p>
          Your card & data are protected with 256-bit encryption (bank level security).
          <a href="https://intercom.help/wizefi/en/articles/1222703-security" target="_blank" class="a-link-blue">Details here</a>
        </p>
      </div>
    </div>
    <button mat-flat-button [color]="'primary'" [disabled]="isLoading" type="submit" data-test="subscription-submit-button">
      <img src="assets/icons8-lock.svg" />Start My Free Trial
    </button>
  </form>
  <div class="terms">
    By clicking Start My Free Trial, you agree to our
    <a href="https://intercom.help/wizefi/en/articles/1222677-terms-of-service">terms of service</a>,
    <a href="https://intercom.help/wizefi/en/articles/1222444-affiliate-terms-and-conditions">affiliate terms and conditions</a>, and our
    <a href="https://intercom.help/wizefi/en/articles/1222673-privacy-policy">privacy policy.</a>
  </div>
</div>
