import { createSelector, select } from '@ngrx/store';
import { Account, Institution, TransactionRule } from '@wizefi/entities';
import { pipe } from 'rxjs';
import { filter, pairwise, take } from 'rxjs/operators';
import { AccountSelectors } from '../account/account.selectors';
import { AppState } from '../app.state';
import { selectInstitutions } from '../institution/institution.root.selectors';

export const selectRulesState = (state: AppState) => state.transactionRules;

export const selectRules = createSelector(selectRulesState, state => state.rules);
export const selectRulesStatus = createSelector(selectRulesState, state => state.status);

export const selectRulesWithExtras = createSelector(selectRules, AccountSelectors.accounts, selectInstitutions, (rules, accounts, institutions) =>
  rules.map(
    rule =>
      ({
        rule,
        account: rule.sourceAccount ? accounts.find(a => a.id === rule.sourceAccount) : undefined,
        institution: rule.sourceInstitution ? institutions.find(i => i.itemId === rule.sourceInstitution) : undefined
      } as RuleExtra)
  )
);

export const selectRulesFinishedOperation = pipe(
  select(selectRulesStatus),
  pairwise(),
  filter(([first, second]) => first === 'loading' && second === 'success'),
  take(1)
);

export interface RuleExtra {
  rule: TransactionRule;
  institution?: Institution;
  account?: Account;
}
