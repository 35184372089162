import { createReducer, on } from '@ngrx/store';
import { SpendingComponentActions } from './spending.actions';
import { SpendingComponentState } from './spending.state';

export const initialState: SpendingComponentState = {
  selectedAccount: undefined
};

export const spendingComponentReducer = createReducer(
  initialState,
  on(SpendingComponentActions.init, (): SpendingComponentState => initialState),
  on(SpendingComponentActions.accountSelected, (state, props): SpendingComponentState => ({ selectedAccount: props.account }))
);
