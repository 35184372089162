import { Transaction } from '@wizefi/entities';
import { createReducer, on } from '@ngrx/store';
import { promoteDraftRequested } from '../draft/loaded-draft.actions';
import { LoginActions } from '../login/login.actions';
import { logoutRequested } from '../screen.actions';
import {
  lastMonthTransactionsGetFailed,
  lastMonthTransactionsGetSuccessful,
  splitTransactionsFailed,
  splitTransactionsRequested,
  syncTransactionsFailed,
  stopLoadingTransactions,
  syncTransactionsRequested,
  syncTransactionsSuccessful,
  getFilteredSyncedTransactionsRequested,
  getFilteredSyncedTransactionsSuccessful,
  getFilteredSyncedTransactionsFailed,
  transactionCreationFailed,
  transactionCreationRequested,
  transactionCreationSuccessful,
  transactionsBatchCreationSuccessful,
  transactionsBatchDeleteSuccessful,
  transactionsBatchUpdateFailed,
  transactionsBatchUpdateRequested,
  transactionsBatchUpdateSuccessful,
  transactionsGetFailed,
  transactionsGetRequested,
  transactionsGetSuccessful,
  transactionUpdateFailed,
  transactionUpdateRequested,
  transactionUpdateSuccessful
} from './transaction.actions';
import { TransactionState } from './transaction.state';

export const initialState: TransactionState = {
  status: 'pending',
  transactions: [],
  lastMonthTransactions: []
};

type StateSwitcher = (state: TransactionState, props: any) => TransactionState;

const loadingStateSwitcher: StateSwitcher = state => ({ ...state, status: 'loading' });

const failureStateSwitcher: StateSwitcher = state => ({ ...state, status: 'failure' });

const switchToTransactions = (state: TransactionState, props: { transactions: Transaction[] }): TransactionState => ({
  ...state,
  status: 'success',
  transactions: props.transactions
});

const stopLoadingStateSwitcher: StateSwitcher = state => ({
  ...state,
  status: 'success'
});

export const transactionReducer = createReducer(
  initialState,
  on(LoginActions.loginSuccessful, switchToTransactions),
  on(transactionsGetRequested, loadingStateSwitcher),
  on(transactionsGetSuccessful, switchToTransactions),
  on(transactionsGetFailed, failureStateSwitcher),
  on(stopLoadingTransactions, stopLoadingStateSwitcher),

  on(syncTransactionsRequested, loadingStateSwitcher),
  on(getFilteredSyncedTransactionsRequested, loadingStateSwitcher),
  on(syncTransactionsSuccessful, switchToTransactions),
  on(getFilteredSyncedTransactionsSuccessful, switchToTransactions),
  on(syncTransactionsFailed, failureStateSwitcher),
  on(getFilteredSyncedTransactionsFailed, failureStateSwitcher),

  on(transactionCreationRequested, loadingStateSwitcher),
  on(
    transactionCreationSuccessful,
    (state, props): TransactionState => ({
      ...state,
      status: 'success',
      transactions: props.selectedMonth === props.transaction.date.substring(0, 7) ? [...state.transactions, props.transaction] : state.transactions
    })
  ),
  on(transactionCreationFailed, failureStateSwitcher),
  on(transactionUpdateRequested, loadingStateSwitcher),
  on(
    transactionUpdateSuccessful,
    (state, props): TransactionState => ({
      ...state,
      status: 'success',
      transactions: state.transactions
        .map(t => (t.id === props.transaction.id ? props.transaction : t))
        .filter(filterTransactionsOutOfSelectedMonth(props))
    })
  ),
  on(transactionUpdateFailed, failureStateSwitcher),
  on(transactionsBatchUpdateRequested, loadingStateSwitcher),
  on(
    transactionsBatchUpdateSuccessful,
    (state, props): TransactionState => ({
      ...state,
      status: 'success',
      transactions: state.transactions.map(t => props.transactions.find(updatedT => updatedT.id === t.id) ?? t)
    })
  ),
  on(transactionsBatchUpdateFailed, failureStateSwitcher),

  on(splitTransactionsRequested, loadingStateSwitcher),
  on(splitTransactionsFailed, failureStateSwitcher),
  on(
    transactionsBatchCreationSuccessful,
    (state, props): TransactionState => ({
      ...state,
      status: 'success',
      transactions: [
        ...state.transactions,
        ...props.transactions.filter(t => filterTransactionsOutOfSelectedMonth({ transaction: t, selectedMonth: props.selectedMonth }))
      ]
    })
  ),
  on(
    transactionsBatchDeleteSuccessful,
    (state, props): TransactionState => ({
      ...state,
      status: 'success',
      transactions: state.transactions.filter(t => !props.transactions.some(deleted => deleted.id === t.id))
    })
  ),
  on(lastMonthTransactionsGetSuccessful, (state, props): TransactionState => ({ ...state, lastMonthTransactions: props.transactions })),
  on(lastMonthTransactionsGetFailed, failureStateSwitcher),

  on(promoteDraftRequested, (): TransactionState => initialState),
  on(logoutRequested, (): TransactionState => initialState)
);

const filterTransactionsOutOfSelectedMonth = (props: { transaction: Transaction; selectedMonth: string }) => (t: Transaction) =>
  t.date.substring(0, 7) === props.selectedMonth;
