import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderService } from '@app/structure/loader/loader.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SnackbarComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatSnackBarModule, MatCheckboxModule, FormsModule],
  providers: [LoaderService]
})
export class SnackbarModule {}
