import { createReducer, on } from '@ngrx/store';
import { switchToFailure } from '../reducer-utils';
import { PayoutRequestActions } from './payout-request.actions';
import { PayoutRequestState } from './payout-request.state';

export const initialState: PayoutRequestState = {
  status: 'pending',
  payoutRequests: []
};

export const payoutRequestReducer = createReducer(
  initialState,
  on(PayoutRequestActions.getRequested, (state, props): PayoutRequestState => ({ ...state, status: 'loading' })),
  on(
    PayoutRequestActions.getSuccessful,
    (state, props): PayoutRequestState => ({ ...state, status: 'success', payoutRequests: props.payoutRequests })
  ),
  on(PayoutRequestActions.getFailed, switchToFailure),

  on(PayoutRequestActions.createRequested, (state, props): PayoutRequestState => ({ ...state, status: 'loading' })),
  on(
    PayoutRequestActions.createSuccessful,
    (state, props): PayoutRequestState => ({ ...state, status: 'success', payoutRequests: [props.payoutRequest, ...state.payoutRequests] })
  ),
  on(PayoutRequestActions.createFailed, switchToFailure)
);
