import { Component, OnInit, ChangeDetectionStrategy, Inject, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { stopLoadingTransactions } from '@app/state/transaction/transaction.actions';
import { LoaderService } from '@app/structure/loader/loader.service';
import { Store } from '@ngrx/store';
import { NotificationPreferences, Transaction } from '@wizefi/entities';
import { IntercomService } from '../intercom.service';
import { NotificationPreferencesService } from '../notification-preferences.service';

@Component({
  selector: 'app-snackbar',
  template: `
    <div class="icon">
      <mat-icon [ngClass]="[data.type]">{{ iconName }}</mat-icon>
    </div>
    <div class="message-container">
      <div class="content" [innerHTML]="data.msg"></div>
      <div class="checkbox-container">
        <mat-checkbox
          *ngIf="data.errorCode === 'ITEM_LOGIN_REQUIRED' || data.errorCode === 'ITEM_NOT_FOUND'"
          color="primary"
          [(ngModel)]="dontShowAgainItemError"
          (change)="onDontShowAgainChange()"
        >
          Don’t show this again
        </mat-checkbox>
      </div>
    </div>
    <div class="buttons">
      <button mat-button [color]="'accent'" (click)="onContinue()" data-test="snackbar-dismiss-button">
        {{ data.showFixButton ? 'Continue' : 'Dismiss' }}
      </button>
      <button *ngIf="data.showFixButton && !this.duringSetup" mat-button [color]="'accent'" (click)="redirectToAccounts()">Fix</button>
      <button *ngIf="data.showContactSupportButton" mat-button [color]="'accent'" (click)="contactSupport()">Contact Support</button>
    </div>
  `,
  styles: [
    `
      @import '../../../styles/variables.scss';

      :host {
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1200 !important;
        position: relative;
        pointer-events: auto !important;
        width: 100%;
      }

      .icon {
        display: flex;
        align-items: center;
        margin-right: 16px;
      }

      .icon mat-icon {
        font-size: 24px;
        &.error {
          color: $red;
        }
        &.info {
          color: var(--yellow);
        }
      }

      .message-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .content {
        margin-bottom: 8px;
      }

      .checkbox-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 16px;
      }

      mat-checkbox {
        color: #ffa500;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
        margin-left: 16px;
      }

      button {
        width: 100%;
      }

      @media (max-width: 600px) {
        :host {
          flex-direction: column;
          align-items: center;
          padding: 12px;
        }

        .buttons {
          width: 100%;
          align-items: center;
          margin-left: 0;
        }

        button {
          width: auto;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent implements OnInit, AfterViewInit, OnDestroy {
  iconName?: string;
  transactions: Transaction[] = [];
  currentPage: string;
  duringSetup: boolean;
  dontShowAgainItemError = false;
  notificationPreferences?: NotificationPreferences;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      msg: string;
      type: 'error' | 'warn' | 'success' | 'info';
      showFixButton?: boolean;
      showContactSupportButton?: boolean;
      errorCode: string;
      institution: string;
      itemId: string;
    },
    public snackbarRef: MatSnackBarRef<SnackbarComponent>,
    private readonly router: Router,
    private readonly elRef: ElementRef,
    private readonly store: Store,
    private readonly loaderService: LoaderService,
    private readonly intercomService: IntercomService,
    private readonly notificationService: NotificationPreferencesService
  ) {}

  ngOnInit(): void {
    this.iconName = this.getIcon();
    if (this.data.showFixButton) {
      this.loaderService.setLoaderInteractive(true);
    }
    this.currentPage = this.router.url;
    this.duringSetup = this.currentPage.includes('setup');
    this.notificationService.get().subscribe({
      next: result => {
        this.notificationPreferences = result;
      }
    });
  }

  ngOnDestroy(): void {
    this.loaderService.setLoaderInteractive(false);
    this.snackbarRef.dismiss();
  }

  ngAfterViewInit(): void {
    this.addLinkListener();
  }

  onDontShowAgainChange() {
    if (this.notificationPreferences) {
      this.notificationPreferences.dontShowAgainItemError = this.dontShowAgainItemError;
      this.notificationService.put(this.notificationPreferences).subscribe(() => {});
    }
  }

  getIcon() {
    switch (this.data.type) {
      case 'error':
        return 'error';
      case 'success':
        return 'done';
      case 'info':
        return 'lightbulb';
      default:
        return '';
    }
  }

  addLinkListener() {
    const link = this.elRef.nativeElement.querySelector('.message-link-transactions-modified');
    const linkAccounts = this.elRef.nativeElement.querySelector('.message-link-accounts-disconnected');
    if (link) {
      link.addEventListener('click', (event: Event) => {
        event.preventDefault();
        this.router.navigate(['/transactions']);
        this.snackbarRef.dismiss();
      });
    }
    if (linkAccounts) {
      linkAccounts.addEventListener('click', (event: Event) => {
        event.preventDefault();
        this.router.navigate(['/accounts']);
        this.snackbarRef.dismiss();
      });
    }
  }

  redirectToAccounts() {
    this.router.navigate(['/accounts']);
    this.store.dispatch(stopLoadingTransactions());
    this.snackbarRef.dismiss();
  }

  onContinue() {
    if (this.data.showFixButton) {
      this.store.dispatch(stopLoadingTransactions());
    }
    this.snackbarRef.dismiss();
  }

  contactSupport() {
    const message =
      `Hi! I'm having an issue to sync my ${this.data.institution} account.\n` +
      `The error reported is ${this.data.errorCode},\n` +
      `and the item id of this institution is ${this.data.itemId}.`;

    this.intercomService.showWithPrepopulatedMessage(message);
    this.snackbarRef.dismiss();
  }
}
