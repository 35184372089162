import { DraftType } from '@wizefi/entities';
import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { AppState } from '../app.state';

export const selectLoadedDraftState = (state: AppState) => state.loadedDraft;
export const selectLoadedDraft = createSelector(selectLoadedDraftState, state => state.loadedDraft);
export const selectLoadedDraftType = createSelector(selectLoadedDraftState, state => state.loadedDraftType);
export const selectDraftLoadingStatus = createSelector(selectLoadedDraftState, state => state.status);
export const selectIsEditingDraft = createSelector(selectLoadedDraftType, draftType => {
  const editableDraftTypes: (DraftType | undefined)[] = ['open', 'versionHistory'];
  return editableDraftTypes.includes(draftType);
});
export const selectDraftOperationHasFinished = pipe(
  select(selectDraftLoadingStatus),
  pairwise(),
  filter(([status1, status2]) => status1 === 'loading' && status2 === 'success')
);
