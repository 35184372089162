/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
export const plaidErrorMessages: { [key: string]: string } = {
  ITEM_LOGIN_REQUIRED: `Warning: One or more of your accounts has the following issue:
                          “Username or password incorrect: If you've recently updated your
                          account with this institution, be sure you're entering your updated credentials”.`,
  ITEM_NO_ERROR: 'There is no error with your account at this time.',
  INTERNAL_SERVER_ERROR: 'We are experiencing some technical difficulties. Please try again later.',
  RATE_LIMIT_EXCEEDED: 'Too many requests were made in a short period of time. Please wait a moment and try again.',
  ACCESS_NOT_GRANTED: `Insufficient Sharing Permissions: There was an error connecting to your account.
                        Try linking your account again by selecting the required information to share with this application.`,
  INSTANT_MATCH_FAILED: `Item cannot be verified through Instant Match. Ensure you are correctly enabling all auth features in Link.`,
  INSUFFICIENT_CREDENTIALS: `Insufficient authorization was provided to complete the request`,
  INVALID_CREDENTIALS: `The credentials you provided were incorrect: Check that your credentials are the same that you use for this institution`,
  INVALID_MFA: `The credentials you provided were incorrect: For security reasons, your account may be locked after several unsuccessful attempts`,
  INVALID_SEND_METHOD: `The provided MFA send_method was invalid`,
  ITEM_LOCKED: `Too many attempts: Your account is locked for security reasons. Reset your bank username and password, and then try again.`,
  ITEM_NOT_SUPPORTED: `Account not currently supported: Your account is not currently supported. Please log in using a different account`,
  NO_ACCOUNTS: `No compatible accounts: Your credentials are correct, but we couldn’t find any accounts with this institution that are compatible with this application. Try another account, financial institution, or check for another connection method.`
};
