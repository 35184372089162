import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

@Component({
  template: `
    <button
      type="button"
      matRipple
      (click)="pillClick.emit()"
      [ngClass]="color"
      class="cursor-pointer rounded-2xl border  bg-inherit px-4 py-2 text-sm"
      [ngClass]="{
        'text-blue': color === 'primary',
        'bg-lightBlue': color === 'primary',
        'border-lightBlue': color === 'primary',
        'text-green': color === 'green',
        'bg-lightGreen': color === 'green',
        'border-lightGreen': color === 'green',
        'text-red': color === 'warn',
        'bg-lightRed': color === 'warn',
        'border-lightRed': color === 'warn',
        'text-middleGray': !color,
        'border-lightGray': !color
      }"
    >
      <ng-content></ng-content>
    </button>
  `,
  selector: 'app-pill',
  standalone: true,
  imports: [CommonModule, MatRippleModule]
})
export class PillComponent {
  @Output() pillClick = new EventEmitter<void>();
  @Input() color: 'primary' | 'green' | 'warn' | undefined;
}
