import { createAction, props } from '@ngrx/store';
import { ReportsCard } from '@wizefi/entities';

export class ReportsCardsActions {
  static getRequested = createAction('[ReportsCards] get requested');
  static getSuccessful = createAction('[ReportsCards] get successful', props<{ cards: ReportsCard[] }>());
  static getFailed = createAction('[ReportsCards] get failed', props<{ err: any }>());

  static updateRequested = createAction('[ReportsCards] update requested', props<{ cards: ReportsCard[] }>());
  static updateSuccessful = createAction('[ReportsCards] update successful');
  static updateFailed = createAction('[ReportsCards] update failed', props<{ err: any }>());
}
