import { Injectable } from '@angular/core';
import { selectWizefiId } from '@app/state/login/login.selectors';
import { Store } from '@ngrx/store';
import { v4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class ClarityService {
  readonly sessionId = v4();

  constructor(private store: Store) {
    (window as any).clarity('identify', '', this.sessionId);

    this.store.select(selectWizefiId).subscribe(wizefiId => {
      (window as any).clarity('identify', wizefiId, this.sessionId);
    });
  }
}
