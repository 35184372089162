import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LOGIN_PATH } from '@app/modules/route-paths';
import { MessageService } from '@app/services/message/message.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BetaGuard implements CanActivate {
  constructor(private messageService: MessageService, private router: Router) {}
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const alias: string = next.queryParams.a;
    if (!alias) {
      this.messageService.error('No Affiliate ID found. please contact support.', 5000);
      this.router.navigate([LOGIN_PATH]);
      return false;
    } else {
      if (!alias.match(/^[a-zA-Z0-9]+$/)) {
        this.messageService.error('Invalid affiliate id', 5000);
        this.router.navigate([LOGIN_PATH]);
        return false;
      }
      // todo traverse up the affiliate tree to find valid affiliate ids
      return true;
    }
  }
}
