/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mapTo, tap } from 'rxjs/operators';
import { setupFinalized } from '../screen.actions';
import { AccountActions } from '../account/account.actions';
import { lastMonthTransactionsGetRequested, getFilteredSyncedTransactionsRequested } from '../transaction/transaction.actions';
import { monthSelected } from './selected-month.actions';
import { CachedProjectionsService } from '@app/services/cached-projections.service';
import { Router } from '@angular/router';
import { PLAN_PATH } from '@app/modules/route-paths';

@Injectable()
export class SelectedMonthEffects {
  monthSelectedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(monthSelected),
      tap(() => this.cachedProjectionsService.clearCachedEntry()),
      map(selectedMonth => getFilteredSyncedTransactionsRequested({ yearMonth: selectedMonth.yearMonth }))
    );
  });

  lastMonthTransactionsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(monthSelected),
      filter(() => this.router.url.startsWith('/' + PLAN_PATH)),
      map(props => lastMonthTransactionsGetRequested({ yearMonth: new Date(props.yearMonth + '-20') }))
    );
  });

  setupFinalizedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setupFinalized),
      mapTo(monthSelected({ yearMonth: new Date().toISOString().substring(0, 7), autoCreate: true }))
    );
  });

  monthSelectedPullAccountsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(monthSelected),
      map(({ yearMonth, autoCreate }) => AccountActions.getRequested({ yearMonth, autoCreate }))
    );
  });

  public constructor(
    private actions$: Actions,
    private cachedProjectionsService: CachedProjectionsService,
    private router: Router
  ) {}
}
