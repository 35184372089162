import { DraftInfo } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export const draftDialogOpened = createAction('[DialogDisplayDrafts] Draft dialog was open');
export const draftInfoGetSuccessful = createAction('[App] Draft info loaded successfully', props<{ draftInfo: DraftInfo[] }>());
export const draftInfoGetFailed = createAction('[App] Draft info load failed', props<{ err: any }>());
export const draftInfoDeleteRequested = createAction('[App] Draft info deletion requested', props<{ draftId: string }>());
export const draftInfoDeleteSuccessful = createAction('[App] Draft info deletion successful', props<{ deletedDraftId: string }>());
export const draftInfoDeleteFailed = createAction('[App] Draft info deletion failed', props<{ err: any }>());
export const draftInfoRenameRequested = createAction('[App] Draft info rename requested', props<{ draftId: string; newName: string }>());
export const draftInfoRenameSuccessful = createAction('[App] Draft info rename successful', props<{ updatedDraft: DraftInfo }>());
export const draftInfoRenameFailed = createAction('[App] Draft info rename failed', props<{ err: any }>());
