/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { ReportsCardsService } from '@app/services/reports-cards.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mapTo, mergeMap } from 'rxjs/operators';
import { ReportsCardsActions } from './reports-cards.actions';
import { of } from 'rxjs';

@Injectable()
export class ReportsCardsEffects {
  reportsCardsGetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsCardsActions.getRequested),
      mergeMap(() =>
        this.reportsCardsService.getCards().pipe(
          map(cards => ReportsCardsActions.getSuccessful({ cards })),
          catchError(err => of(ReportsCardsActions.getFailed({ err })))
        )
      )
    );
  });

  reportsCardsUpdateEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsCardsActions.updateRequested),
      mergeMap(({ cards }) =>
        this.reportsCardsService.updateCards(cards).pipe(
          mapTo(ReportsCardsActions.updateSuccessful()),
          catchError(err => of(ReportsCardsActions.updateFailed({ err })))
        )
      )
    );
  });

  public constructor(private actions$: Actions, private reportsCardsService: ReportsCardsService) {}
}
