export const SETUP_PATH = 'setup';
export const SETUP_ACCOUNTS_PATH = 'accounts';
export const SETUP_GOALS_PATH = 'goals';
export const SETUP_INCOME_PATH = 'income';
export const SETUP_PROJECTIONS_PATH = 'projections';
export const SETUP_SETTINGS_PATH = 'settings';

export const DASHBOARD_PATH = 'dashboard';
export const ACCOUNTS_PATH = 'accounts';

export const START_HERE_PATH = 'start';
export const PLAN_PATH = 'plan';
export type PlanChildPaths = 'income' | 'expenses' | 'four-step-plan' | 'net-worth-projection' | 'debt-projection';
export const PLAN_INCOME_PATH: PlanChildPaths = 'income';
export const PLAN_EXPENSES_PATH: PlanChildPaths = 'expenses';
export const PLAN_PLAN_PATH: PlanChildPaths = 'four-step-plan';
export const PLAN_NET_WORTH_PROJECTION_PATH: PlanChildPaths = 'net-worth-projection';
export const PLAN_DEBT_PROJECTION_PATH: PlanChildPaths = 'debt-projection';

export const COACH_PATH = 'coach';

export const LOGIN_PATH = 'login';

export const GO_PATH = 'go';

export const REGISTER_PATH = 'register';
export const SUBSCRIPTION_PATH = 'subscription';

export const ADMIN_PATH = 'admin';
export const ADMIN_MANAGE_ADMIN_PATH = 'manage-admin';
export const ADMIN_AFFILIATE_TREE_PATH = 'affiliate-tree';

export const EDIT_PLAN_PATH = 'edit-plan';
export const EDIT_PLAN_REVIEW_PROJECTIONS_PATH = 'projections';

export const VALIDATE_EMAIL_PATH = 'validate-email';

export const RESET_PASSWORD_PATH = 'reset-password';

export const SETTINGS_PATH = 'settings';

export const TRANSACTIONS_PATH = 'transactions';

export const REPORTS_PATH = 'reports';

export const ERROR_PATH = 'error';
