/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { AccountService } from '@app/services/account.service';
import { CachedProjectionsService } from '@app/services/cached-projections.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { draftUnloaded } from '../draft/loaded-draft.actions';
import { selectLoadedDraft } from '../draft/loaded-draft.selectors';
import { institutionsGetRequested } from '../institution/institution.actions';
import { LoginActions } from '../login/login.actions';
import { bankAccountSaved, setupFinalized } from '../screen.actions';
import { selectIsInOriginalPlanMonth, selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { UserDataSelectors } from '../user-data/user-data.selectors';
import { AccountActions, AccountMinimumRequiredFields } from './account.actions';
import { AccountSelectors } from './account.selectors';
import { of } from 'rxjs';
import { Account } from '@wizefi/entities';

@Injectable()
export class AccountEffects {
  reloginSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(ofType(LoginActions.reloginSuccessful), mapTo(AccountActions.getOriginalAccountsRequested()));
  });

  setupFinalizedEffect$ = createEffect(() => {
    return this.actions$.pipe(ofType(setupFinalized), mapTo(AccountActions.getOriginalAccountsRequested()));
  });

  accountGetOriginalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getOriginalAccountsRequested),
      concatLatestFrom(() => this.store.select(UserDataSelectors.selectUserData)),
      switchMap(([_, userData]) =>
        this.accountService.getAccounts(userData.originalPlanYearMonth).pipe(
          map(accounts => AccountActions.getOriginalAccountsSuccessful({ accounts })),
          catchError(err => of(AccountActions.getOriginalAccountsFailed({ err })))
        )
      )
    );
  });

  accountGetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getRequested),
      concatLatestFrom(() => this.store.select(selectLoadedDraft)),
      mergeMap(([props, loadedDraft]) =>
        this.accountService.getAccounts(props.yearMonth, props.autoCreate, props.draftId ?? loadedDraft).pipe(
          map(accounts => AccountActions.getSuccessful({ accounts, yearMonth: props.yearMonth })),
          catchError(err => of(AccountActions.getFailed({ err })))
        )
      )
    );
  });

  deleteRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.deleteRequested),
      concatLatestFrom(() => [this.store.select(selectLoadedDraft), this.store.select(selectSelectedMonth)]),
      mergeMap(([props, draft, yearMonth]) =>
        this.accountService.deleteAccount(yearMonth, props.account, draft).pipe(
          concatLatestFrom(() => [this.store.select(selectIsInOriginalPlanMonth)]),
          map(([account, isOriginalPlan]) => AccountActions.deleteSuccessful({ account, isOriginalPlan })),
          catchError(err => of(AccountActions.deleteFailed({ err })))
        )
      )
    );
  });

  deleteSuccessfulEffect$ = createEffect(() => {
    return this.actions$.pipe(ofType(AccountActions.deleteSuccessful), mapTo(institutionsGetRequested({ autoCreate: false })));
  });

  createRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.createRequested),
      concatLatestFrom(() => [this.store.select(selectLoadedDraft), this.store.select(selectSelectedMonth)]),
      switchMap(([props, draft, yearMonth]) =>
        this.accountService.createAccount(props.partialValues, yearMonth, draft).pipe(
          concatLatestFrom(() => [this.store.select(selectIsInOriginalPlanMonth)]),
          map(([account, isOriginalPlan]) => AccountActions.createSuccessful({ account, isOriginalPlan })),
          catchError(err => of(AccountActions.createFailed({ err })))
        )
      )
    );
  });

  updateRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.updateRequested),
      concatLatestFrom(() => [this.store.select(selectLoadedDraft), this.store.select(selectSelectedMonth)]),
      switchMap(([props, draft, yearMonth]) =>
        this.accountService.updateAccount(props.account, yearMonth, draft).pipe(
          concatLatestFrom(() => [this.store.select(selectIsInOriginalPlanMonth)]),
          map(([updatedAccounts, isOriginalPlan]) => AccountActions.updateSuccessful({ updatedAccounts, isOriginalPlan })),
          catchError(err => of(AccountActions.updateFailed({ err })))
        )
      )
    );
  });

  saveRequestedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.saveRequested),
      concatLatestFrom(() => [
        this.store.select(selectLoadedDraft),
        this.store.select(selectSelectedMonth),
        this.store.select(AccountSelectors.accounts)
      ]),
      switchMap(([props, draftId, yearMonth, accounts]) =>
        this.accountService.batchUpdateAccounts(accounts, yearMonth, draftId).pipe(
          concatLatestFrom(() => [this.store.select(selectIsInOriginalPlanMonth)]),
          map(([updatedAccounts, isOriginalPlan]) => AccountActions.saveSuccessful({ updatedAccounts, isOriginalPlan })),
          catchError(err => of(AccountActions.saveFailed({ err })))
        )
      )
    );
  });

  updateBatchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.updateBatchRequested),
      concatLatestFrom(() => [this.store.select(selectLoadedDraft), this.store.select(selectSelectedMonth)]),
      switchMap(([props, draftId, yearMonth]) =>
        this.accountService.batchUpdateAccounts(props.accounts, yearMonth, draftId).pipe(
          concatLatestFrom(() => [this.store.select(selectIsInOriginalPlanMonth)]),
          map(([updatedAccounts, isOriginalPlan]) => AccountActions.updateBatchSuccessful({ updatedAccounts, isOriginalPlan })),
          catchError(err => of(AccountActions.updateBatchFailed({ err })))
        )
      )
    );
  });

  draftUnloadedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(draftUnloaded),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      switchMap(([_, month]) =>
        this.accountService.getAccounts(month).pipe(
          map(accounts => ({ accounts, yearMonth: month })),
          map(result => AccountActions.getSuccessful(result)),
          catchError(err => of(AccountActions.getFailed({ err })))
        )
      )
    );
  });

  updateAccountBalancesRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.balancesUpdateRequested),
      mergeMap(() =>
        this.accountService.updateBalances().pipe(
          map(({ accounts, institutions }) => AccountActions.balancesUpdateSuccessful({ accounts, institutions })),
          catchError(err => of(AccountActions.balancesUpdateFailed({ err })))
        )
      )
    );
  });

  bankAccountSavedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bankAccountSaved),
      map(({ account, isNewAccount }) =>
        isNewAccount
          ? AccountActions.createRequested({ partialValues: account as AccountMinimumRequiredFields })
          : AccountActions.updateRequested({ account })
      )
    );
  });

  accountGetSuccessful$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AccountActions.getSuccessful),
        tap(() => this.cachedProjectionService.recalculateCache())
      );
    },
    { dispatch: false }
  );

  updateAndDeleteBatchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.updateAndDeleteBatchRequested),
      concatLatestFrom(() => [this.store.select(selectLoadedDraft), this.store.select(selectSelectedMonth)]),
      switchMap(([props, draftId, yearMonth]) =>
        of(this.batchUpdateAndDelete(props.deletedAccounts, props.updatedAccounts, yearMonth, draftId)).pipe(
          mapTo(AccountActions.updateAndDeleteBatchSuccessful()),
          catchError(err => of(AccountActions.updateAndDeleteBatchFailed({ err })))
        )
      )
    );
  });

  private async batchUpdateAndDelete(deletedAccounts: Account[], updatedAccounts: Account[], yearMonth: string, draftId?: string) {
    if (deletedAccounts.length > 0) {
      await this.accountService.batchDeleteAccounts(deletedAccounts, yearMonth, draftId).toPromise();
    }
    if (updatedAccounts.length > 0) {
      await this.accountService.batchUpdateAccounts(updatedAccounts, yearMonth, draftId).toPromise();
    }
  }

  public constructor(
    private store: Store,
    private actions$: Actions,
    private accountService: AccountService,
    private cachedProjectionService: CachedProjectionsService
  ) {}
}
