<form class="d-flex flex-column align-items-left justify-content-left form" [formGroup]="formGroup" (submit)="this.signup()">
  <h2>Create your WizeFi account</h2>

  <mat-form-field *ngIf="isEnterpriseParent && formGroup.get('nameFirst')" [appearance]="'fill'">
    <mat-label>First Name</mat-label>
    <input matInput type="text" formControlName="nameFirst" name="nameFirst" data-test="signup-nameFirst-input" />
    <mat-error *ngIf="formGroup.get('nameFirst')?.errors" data-test="signup-nameFirst-error"> Please enter your first name </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="isEnterpriseParent && formGroup.get('nameLast')" [appearance]="'fill'">
    <mat-label>Last Name</mat-label>
    <input matInput type="text" formControlName="nameLast" name="nameLast" data-test="signup-nameLast-input" />
    <mat-error *ngIf="formGroup.get('nameLast')?.errors" data-test="signup-nameLast-error"> Please enter your last name </mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="'fill'">
    <mat-label>Email</mat-label>
    <input matInput type="email" formControlName="email" name="email" data-test="signup-email-input" autofocus />
    <mat-error *ngIf="formGroup.controls.email.errors" data-test="signup-email-error">Please enter a valid email</mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="'fill'">
    <mat-label>Password</mat-label>
    <input matInput type="password" formControlName="password" name="password" data-test="signup-password-input" />
    <mat-error *ngIf="formGroup.controls.password.errors?.required" data-test="signup-password-error">Please enter your password</mat-error>
    <mat-error
      *ngIf="
        !formGroup.controls.password.errors?.required &&
        (formGroup.controls.password.errors?.hasUpperAndLowercaseError ||
          formGroup.controls.password.errors?.minlength ||
          formGroup.controls.password.errors?.hasNumberError)
      "
      data-test="signup-password-error"
      >Password must have at least 8 characters, with upper and lowercase letters and a number</mat-error
    >
  </mat-form-field>

  <button
    mat-flat-button
    [color]="'primary'"
    [disabled]="loading$ | async"
    type="submit"
    class="create-account-button"
    data-test="create-account-button"
  >
    <div class="flex items-center justify-center">
      <img src="assets/icons8-lock.svg" />
      <div>Create Account</div>
    </div>
  </button>

  <div class="terms">
    By clicking Create Account, you agree to our
    <a href="https://intercom.help/wizefi/en/articles/1222677-terms-of-service" target="_blank">terms of service</a>
    <a *ngIf="!isEnterpriseParent" href="https://intercom.help/wizefi/en/articles/1222444-affiliate-terms-and-conditions" target="_blank"
      >, affiliate terms and conditions,</a
    >
    and our
    <a href="https://intercom.help/wizefi/en/articles/1222673-privacy-policy" target="_blank">privacy policy.</a>
  </div>
</form>
