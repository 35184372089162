/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { COACH_PATH, PLAN_PATH } from '@app/modules/route-paths';
import { DraftService } from '@app/services/draft.service';
import { TransactionRuleService } from '@app/services/transaction-rule.service';
import { TransactionService } from '@app/services/transaction.service';
import { UserDataService } from '@app/services/user-data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AccountActions } from '../account/account.actions';
import { institutionsGetRequested } from '../institution/institution.actions';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { transactionRulesGetRequested } from '../transaction-rules/transaction-rules.actions';
import { transactionsGetRequested } from '../transaction/transaction.actions';
import { UserDataActions } from '../user-data/user-data.actions';
import { CoachActions } from './coach.actions';

@Injectable()
export class CoachEffects {
  loggedInAsClientEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoachActions.logAsClient),
      switchMap(() => this.router.navigate([PLAN_PATH])),
      tap(() => this.clearCaches()),
      this.getData()
    );
  });

  loggedOutAsClientEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoachActions.logoutAsClient),
      tap(() => this.clearCaches()),
      this.getData(),
      tap(() => this.router.navigate([COACH_PATH]))
    );
  });

  constructor(
    private actions$: Actions,
    private transactionService: TransactionService,
    private ruleService: TransactionRuleService,
    private draftService: DraftService,
    private userDataService: UserDataService,
    private store: Store,
    private router: Router
  ) {}

  private clearCaches() {
    this.transactionService.clearCache();
    this.ruleService.clearCache();
    this.draftService.clearCache();
  }

  private getData() {
    return pipe(
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      // We fetch directly the userData before dispatching the other actions because the original accounts depend on the original plan date
      switchMap(([_, yearMonth]) => this.userDataService.getUserData().pipe(map(userData => ({ userData, yearMonth })))),
      switchMap(({ userData, yearMonth }) =>
        of(
          UserDataActions.getSuccessful({ userData }),
          AccountActions.getRequested({ yearMonth, autoCreate: true }),
          AccountActions.getOriginalAccountsRequested(),
          institutionsGetRequested({ autoCreate: true }),
          transactionsGetRequested({}),
          transactionRulesGetRequested()
        )
      )
    );
  }
}
