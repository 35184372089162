import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AffiliateTreeChangelog, AffiliateTreeError, AffiliateTreeNodeInfo, AffiliateTreeReport } from '@wizefi/entities';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  private readonly path = 'affiliate-tree';
  private getAffiliates$ = this.apiService.get<AffiliateInfoResponse>(`${this.path}/info`).pipe(shareReplay(1));

  constructor(private apiService: ApiService) {}

  getAffiliateSubtree(subtreeRoot: string): Observable<AffiliateTreeNodeInfo> {
    return this.apiService.get(`${this.path}/subtree`, { params: { subtreeRoot } });
  }

  getAffiliateReport(): Observable<AffiliateTreeReport[]> {
    return this.apiService.get(`${this.path}/report`);
  }

  getParents(user: string): Observable<ParentInformation[]> {
    return this.apiService.get(`${this.path}/parents`, { params: { user } });
  }

  getAffiliates() {
    return this.getAffiliates$;
  }

  checkAffiliateTreeErrors(): Observable<AffiliateTreeError[]> {
    return this.apiService.get(`${this.path}/check-errors`);
  }

  autoFixTree(): Observable<{ totalErrorsFixed: number }> {
    return this.apiService.post(`${this.path}/fix-errors`);
  }

  changeParentAffiliate(userToChange: string, newParentAffiliate: string, reasonForChange: string) {
    return this.apiService.post(`${this.path}/change-parent-affiliate`, {
      userToChange,
      newParentAffiliate,
      reasonForChange
    });
  }

  changeAffiliateAlias(userToChange: string, newAffiliateAlias: string, reasonForChange: string) {
    return this.apiService.post(`${this.path}/change-affiliate-alias`, {
      userToChange,
      newAffiliateAlias,
      reasonForChange
    });
  }

  getAffiliateTreeChangelog(): Observable<AffiliateTreeChangelog[]> {
    return this.apiService
      .get<AffiliateTreeChangelog[]>(`${this.path}/changelog`)
      .pipe(map(changelog => changelog.sort((a, b) => b.dateIso.localeCompare(a.dateIso))));
  }

  resetAffiliatesCache() {
    this.getAffiliates$ = this.apiService.get<AffiliateInfoResponse>(`${this.path}/info`).pipe(shareReplay(1));
  }
}

export interface AffiliateInfoResponse {
  children: AffiliateInfo[];
  pendingEarnings: number;
}

export interface AffiliateInfo {
  firstName: string;
  id: string;
  lastName: string;
  subscriptionDate: string;
  secondTierAffiliatesSubscriptionDates: string[];
}

export interface ParentInformation {
  level: number;
  wizefiId: string;
  name: string | undefined;
  email: string | undefined;
}

export interface AffiliateCount {
  count: number;
  lastUpdatedAt: string;
}
