import { createReducer, on } from '@ngrx/store';
import { ReportsCard, ReportsCardType } from '@wizefi/entities';
import { ReportsCardsActions } from './reports-cards.actions';
import { ReportsCardsState } from './reports-cards.state';
import { switchToFailure } from '../reducer-utils';

export const allCardTypes: ReportsCardType[] = [
  'assets-chart',
  'budget-category-pie-chart',
  'category-spending-bar-chart',
  'debt-chart',
  'net-worth-chart',
  'top-5-expenses-card',
  'top-5-merchant-card'
];

export const initialState: ReportsCardsState = {
  status: 'pending',
  cards: allCardTypes.map(type => ({ enabled: true, type }))
};

export const reportsCardsReducer = createReducer(
  initialState,
  on(ReportsCardsActions.getRequested, (state): ReportsCardsState => ({ ...state, status: 'loading' })),
  on(ReportsCardsActions.getSuccessful, (state, props): ReportsCardsState => ({ ...state, status: 'success', cards: addMissingCards(props.cards) })),
  on(ReportsCardsActions.getFailed, switchToFailure),
  on(ReportsCardsActions.updateRequested, (state, props): ReportsCardsState => ({ ...state, status: 'success', cards: props.cards })),
  on(ReportsCardsActions.updateFailed, switchToFailure)
);

const addMissingCards = (cards: ReportsCard[]): ReportsCard[] => {
  const cardTypesSet = new Set<ReportsCardType>(allCardTypes);
  cards.forEach(c => cardTypesSet.delete(c.type));

  const newCards: ReportsCard[] = [...cardTypesSet].map(type => ({ type, enabled: true }));
  return [...cards, ...newCards];
};
