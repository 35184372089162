import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';

interface IMessageInfo {
  msg: string;
  showFixButton?: boolean;
  dontShowAgain?: boolean;
  errorCode?: string;
}

interface ISnackbarData {
  msg: string;
  type: string;
  showFixButton?: boolean;
  dontShowAgainItemError?: boolean;
  showContactSupportButton?: boolean;
  errorCode?: string;
  institution?: string;
  itemId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private snackbarRef: MatSnackBarRef<any> | undefined;

  private activeNotifications: any[] = [];

  constructor(private snackbar: MatSnackBar) {}

  success(msg: string, timeout: number = 3000) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'success'));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  error(msg: string, timeout: number = 3000) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'error'));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  info(msg: IMessageInfo | string, timeout: number = 10000) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'info'));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  errorWithContactSupport(msg: string, timeout: number = 10000, errorCode?: string, institution?: string, itemId?: string) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'info', true, errorCode, institution, itemId));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  private options(
    msg: IMessageInfo | string,
    timeout: number,
    type: string,
    showContactSupportButton: boolean = false,
    errorCode?: string,
    institution?: string,
    itemId?: string
  ): MatSnackBarConfig<ISnackbarData> {
    let message: string;
    let showFixButton = false;
    let dontShowAgainItemError = false;

    if (typeof msg === 'object' && 'msg' in msg) {
      message = msg.msg;
      showFixButton = msg.showFixButton ?? false;
      dontShowAgainItemError = msg.showFixButton ?? false;
      errorCode = msg.errorCode;
    } else {
      message = msg;
    }
    return {
      data: {
        msg: message,
        type,
        showFixButton,
        dontShowAgainItemError,
        showContactSupportButton,
        errorCode,
        institution,
        itemId
      },
      duration: timeout,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: type === 'success' ? 'success-snackbar' : undefined
    };
  }

  clear() {
    this.snackbarRef?.dismiss();
    this.snackbarRef?.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }
}
