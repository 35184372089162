"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.draftSchema = exports.draftInfoSchema = void 0;
const zod_1 = require("zod");
const account_1 = require("./account");
const draft_type_1 = require("./draft-type");
const user_data_1 = require("./user-data");
exports.draftInfoSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    draftType: draft_type_1.draftTypeSchema,
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    yearMonth: zod_1.z.string()
});
exports.draftSchema = exports.draftInfoSchema.extend({
    userData: user_data_1.userDataSchema,
    accounts: zod_1.z.array(account_1.accountSchema)
});
