import { Account, UserData } from '@wizefi/entities';
import { LinkPlaidInstitutionResult } from '@app/services/plaid.service';
import { createAction, props } from '@ngrx/store';

export const newPlaidInstitutionLinkRequested = createAction('[NewAccountDialog] requested to link new plaid institution');
export const refreshPlaidInstitutionCredentialsRequested = createAction(
  '[InstitutionDialog] requested to refresh plaid institution credentials',
  props<{ itemId: string }>()
);
export const newPlaidInstitutionLinkSuccessful = createAction(
  '[App] successfully linked to new plaid institution',
  props<LinkPlaidInstitutionResult>()
);
export const logoutRequested = createAction('[SettingsComponent] logout requested');

/** Setup Step 4 Screen - Finalized */
export const setupFinalized = createAction('[SetupStep4] setup finalized');
export const movedFromSetupToPlan = createAction('[SetupStep4] finalized saving final setup step and moved to plan', props<{ userData: UserData }>());

/** Transactions Screen */
export const transactionsScreenInitialized = createAction('[TransactionsScreen] initialized');

/** Edit Plan: Edit FFD Screen */
export const editFfdComponentInitialized = createAction('[EditFfdComponent] initialized');

/** Account Dialog */
export const bankAccountSaved = createAction('[AccountDialog] saved account', props<{ account: Account; isNewAccount: boolean }>());

export const planPageInitialized = createAction('[PlanPage] initialized');
