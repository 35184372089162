/* eslint-disable @typescript-eslint/naming-convention */
import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, RouterState } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LOGIN_PATH, REGISTER_PATH, RESET_PASSWORD_PATH, SUBSCRIPTION_PATH, VALIDATE_EMAIL_PATH } from '@app/modules/route-paths';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { IntercomService } from '@app/services/intercom.service';
import { AppSelectors } from '@app/state/app.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AnalyticsTagsService } from '@app/services/analytics-tags.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  testId: Observable<string | undefined>;
  title: 'Google Analytics Service';
  anyStateLoading$: Observable<boolean>;
  @HostBinding('class.dark-body') darkBody = true;

  constructor(
    private router: Router,
    private titleService: Title,
    private store: Store,
    private intercomService: IntercomService,
    private gaService: GoogleAnalyticsService,
    private analyticsTagsService: AnalyticsTagsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit() {
    this.analyticsTagsService.injectGoogleClarifyTag();
    this.gaService.init();

    this.anyStateLoading$ = this.store.select(AppSelectors.anyStateLoading).pipe(debounceTime(1)); // Put a debouce to avoid ExpressionChangedAfterItHasBeenCheckedError

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const pathSegments = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map(i => i.path);
      const pathsWithDarkBody = [LOGIN_PATH, REGISTER_PATH, SUBSCRIPTION_PATH, VALIDATE_EMAIL_PATH, RESET_PASSWORD_PATH];
      this.darkBody = pathSegments.some(segment => pathsWithDarkBody.includes(segment));
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root);
        this.titleService.setTitle(title[0]);
        (window as any).gtag('event', 'page_view', {
          page_title: title[0],
          page_path: event.urlAfterRedirects, //technically this doesn't exist in GA4 any longer but leaving it in
          page_location: this.document.location.href
        });
      }
    });
    this.intercomService.boot();
  }
  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }
}
