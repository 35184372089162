import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsTagsService {
  constructor() {}

  injectGoogleClarifyTag(): void {
    if (environment.googleClarifyTag && environment.production && environment.mode === 'beta') {
      const metaTag = document.createElement('meta');
      metaTag.name = 'clarity-site-verification';
      metaTag.content = environment.googleClarifyTag;

      const existingTag = document.head.querySelector('meta[name="clarity-site-verification"]');

      if (!existingTag) {
        document.head.appendChild(metaTag);
      }
    }
  }
}
