import { Account, Institution } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type Required<T, K extends keyof T> = T & { [P in K]-?: T[P] };
type WithRequired<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> & Required<T, K>;

export type AccountWithoutId = PartialBy<Account, 'id'>;
export type AccountMinimumRequiredFields = WithRequired<Partial<Account>, 'accountName' | 'type' | 'category'>;

export class AccountActions {
  static getRequested = createAction('[App] get accounts requested', props<{ yearMonth: string; draftId?: string; autoCreate: boolean }>());
  static getSuccessful = createAction('[App] get accounts successful', props<{ yearMonth: string; accounts: Account[] }>());
  static getFailed = createAction('[App] get accounts failed', props<{ err: any }>());

  static getOriginalAccountsRequested = createAction('[App] get original accounts requested');
  static getOriginalAccountsSuccessful = createAction('[App] get original accounts successful', props<{ accounts: Account[] }>());
  static getOriginalAccountsFailed = createAction('[App] get original accounts failed', props<{ err: any }>());

  static deleteRequested = createAction('[App] delete account requested', props<{ account: Account; removeImmediately?: boolean }>());
  static deleteSuccessful = createAction('[App] delete account successful', props<{ account: Account; isOriginalPlan: boolean }>());
  static deleteFailed = createAction('[App] delete account failed', props<{ err: any }>());

  static updateAndDeleteBatchRequested = createAction(
    '[App] update and delete account batch requested',
    props<{ updatedAccounts: Account[]; deletedAccounts: Account[] }>()
  );
  static updateAndDeleteBatchSuccessful = createAction('[App] update and delete account batch successful');
  static updateAndDeleteBatchFailed = createAction('[App] update and delete account batch failed', props<{ err: any }>());

  static createRequested = createAction('[App] create account requested', props<{ partialValues: AccountMinimumRequiredFields }>());
  static createSuccessful = createAction('[App] create account successful', props<{ account: Account; isOriginalPlan: boolean }>());
  static createFailed = createAction('[App] create account failed', props<{ err: any }>());

  static accountChanged = createAction('[App] partial account value changed', props<{ account: Account; isOriginalPlan: boolean }>());

  static saveRequested = createAction('[App] save accounts requested');
  static saveSuccessful = createAction('[App] save accounts successful', props<{ updatedAccounts: Account[]; isOriginalPlan: boolean }>());
  static saveFailed = createAction('[App] save accounts failed', props<{ err: any }>());

  static updateRequested = createAction('[App] update account requested', props<{ account: Account }>());
  static updateSuccessful = createAction('[App] update account successful', props<{ updatedAccounts: Account[]; isOriginalPlan: boolean }>());
  static updateFailed = createAction('[App] update account failed', props<{ err: any }>());

  static updateBatchRequested = createAction('[App] batch update accounts requested', props<{ accounts: Account[] }>());
  static updateBatchSuccessful = createAction(
    '[App] batch update accounts successful',
    props<{ updatedAccounts: Account[]; isOriginalPlan: boolean }>()
  );
  static updateBatchFailed = createAction('[App] batch update accounts failed', props<{ err: any }>());

  static balancesUpdateRequested = createAction('[App] Update account balances was requested');
  static balancesUpdateSuccessful = createAction(
    '[App] Update account balances was successful',
    props<{ accounts: Account[]; institutions: Institution[] }>()
  );
  static balancesUpdateFailed = createAction('[App] Update account balances failed', props<{ err: any }>());
}
